.case-modal-filter-option {
    width: 100%;
    
    .modal-content {
        width: 645px;
        max-height: 90%;
        overflow: auto;
        
        .modal-body {
            padding: 32px 32px 0;

            label {
                font-size: 14px;
                letter-spacing: -0.4px;
                margin-top: 24px;
                font-size: 12px;
                letter-spacing: -0.34px;
            }

            .checkbox-input {
                padding-bottom: 0;

                label {
                    margin-top: 0;
                }

                [type="checkbox"]:checked + label:before {
                    top: 2px;
                }

                [type="checkbox"]:checked + label:after {
                    top: -4px;
                }
            }

            .case-filter-option-wrapper {
                margin-top: 0px;

                .label-value {
                    font-size: 12px;
                    letter-spacing: -0.4px;
                    display: inline-block;
                    margin: 0 4px 4px 0;
                    vertical-align: top;
                    border-radius: 4px;
                    color: #ffffff;
                    padding: 2px 8px;
    
                    svg {
                        position: relative;
                        vertical-align: top;
                        margin-right: 4px;
                        top: 3px;
                        color: #ffffff;
                        cursor: pointer;
                    }
                }

                .case-label-container {
                    position: relative;

                    .input-contact-container {
                        padding: 12px 40px 12px 16px;
                        border: 1px solid rgba(25, 25, 25, 0.1);
                        border-radius: 8px;
                        height: 98px;
                        overflow: auto;
                        position: relative;
                        display: flex;
                        flex-wrap: wrap;
                  
                        &.focus {
                          border: 1px solid #FF7E00;
                        }
                  
                        &.blur {
                          border: 1px solid rgba(25, 25, 25, 0.1);
                        }
                  
                        &::-webkit-scrollbar {
                          display: none;
                        }
                  
                        .label-value {
                            height: fit-content;
                    
                            svg {
                                position: relative;
                                top: 5px;
                                margin-right: 5px;
                                cursor: pointer;
                            }

                            b {
                                font-size: 14px;
                                line-height: 1.5;
                                letter-spacing: -0.4px;
                            }
                        }
                        
                  
                        textarea {
                          resize: none;
                          width: 90%;
                          border: none;
                          padding: 0;
                          position: relative;
                  
                          &::-webkit-scrollbar {
                            display: none;
                          }
                        }
                  
                        .search-icon {
                          position: absolute;
                          right: 12px;
                          top: 12px;
                          svg {
                            font-size: 20px;
                          }
                        }
                      }

                      .add-label-suggestion-wrapper {
                        max-height: 190px;
                        width: 412px;
                        border-radius: 8px;
                        background: #ffffff;
                        overflow: auto;
                        position: absolute;
                        box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
                        display: none;
                        z-index: 3;

                        .loading-wrapper {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 40px;
                        }
            
                        ul {
                            list-style: none;
                            margin: 0;
                            padding: 0;
                            
                            li {
                                width: 100%;
                                padding: 8px 16px;
                                cursor: pointer;
            
                                
                                &:hover {
                                    background: #FFF2E5;
                                }
            
                                &.no-option {
                                    color: rgba(25, 25, 25, 0.6);
                                    text-align: center;
                                    font-size: 16px;
                                    letter-spacing: -0.3px;
            
                                    &:hover {
                                        background: none;
                                    }
                                }
            
                                .label-value {
                                    margin: 0 4px 0 0;
                                }
                            }
                        }
                    }
                }
            }

            .select-value-wrapper {
                border-radius: 8px;
                width: 100%;
                position: relative;
                font-size: 16px;
                letter-spacing: -0.3px;
                padding: 16px;
                border: solid 1px #dcdcdc;

                svg {
                    position: absolute;
                    right: 16px;
                    font-size: 24px;
                    top: 50%;
                    transform: translate(0, -50%);
                }
            }

            .show-case-type {
                display: grid;
                grid-template-columns: 110px 110px 110px;
                .input-wrapper {
                    padding-bottom: 0 !important;
                }
                label {
                    margin-top: unset !important;
                    font-weight: 700;
                    padding-left: 24px !important;
                }
                label:before, label:after {
                    top: 0px !important;
                }
            }

            .filter-by-channel {
                position: relative;
                width: 100%;

                div {
                    display: inline-block;
                    margin: 0 24px 0 0;

                    label {
                        line-height: 20px;
                        margin: 0 0 12px 0;
                    }

                    [type="checkbox"]:checked + label:after {
                        line-height: 16px;
                    }
                }

                img {
                    position: relative;
                    margin-right: 4px;
                    width: 18px;
                    top: -1px;
                }
            }
        }

        .modal-footer {
            border: none;
            padding: 20px 32px 32px;

            button {
                width: calc(50% - 4px);
                height: 48px;
                margin: 0 8px 0 0;

                &:nth-last-child(1) {
                    margin: 0;
                }
            }
        }
    }
}