.add-user-contact-container {
  padding: 32px 30px;
  text-align: left;

  .integration-list-loading-wrapper {
    .lds-ring {
      width: 50px;
      height: 50px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      right: unset;

      div {
        border: 5px solid #fff;
        border-color: #ff7d00 transparent transparent transparent;
        width: 50px;
        height: 50px;
        margin: 0;
      }
    }
  }

  .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold;
  }

  .add-user-contact-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);

    .add-user-contact-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;

      .title-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        svg {
          margin-right: 4px;
        }
      }

      p {
        font-size: 16px;
        margin: 0;
        color: rgba(25, 25, 25, 0.8);
      }
    }

    .add-user-contact-box-form {
      width: 100%;

      label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block;

        b {
          span {
            font-family: PTRootUI, 'sans-serif';
            color: rgba(25, 25, 25, 0.6);
          }
        }
      }

      .warning-error-red {
        display: flex;
        margin-top: 4px;
        color: #ff3f57;

        svg {
          margin-right: 4px;
          font-size: 12.7px;
          position: relative;
          top: 2.5px;
        }

        p {
          font-size: 12px;
        }
      }

      // .warning-error-red {
      //   color: #ff3f57;
      //   font-size: 12px;
      //   letter-spacing: -0.4px;
      //   margin: 4px 0 0;

      //   svg {
      //       font-size: 14px;
      //       position: relative;
      //       top: -2px;
      //       margin-right: 4px;
      //       color: #ff3f57 !important;
      //       cursor: unset !important;
      //   }
      // }

      .form-contact-fullname,
      .form-contact-alias,
      .form-contact-email {
        width: 320px;
        
        input[type='text'] {
          padding: 12px 16px;
          border-radius: 6px;
          border: solid 1px #dcdcdc;
          width: 100%;
          font-size: 16px;
          letter-spacing: -0.3px;
          display: block;
        }

        input[type='text']:focus {
          border: solid 1px #ff7e00;
        }

        input[type='text']:disabled {
          background-color: rgba(25, 25, 25, 0.1) !important;
          color: rgba(25, 25, 25, 0.4) !important;
        }
      }

      .form-contact-phonenumber {
        width: 320px;

        .input-phonenumber-container {
          padding: 0 16px;
          border-radius: 6px;
          border: solid 1px #dcdcdc;
          width: 100%;
          font-size: 16px;
          letter-spacing: -0.3px;
          display: flex;
          align-items: center;

          &.phone-focus {
            border: solid 1px #ff7e00;
          }

          &.phone-disabled {
            background-color: rgba(25, 25, 25, 0.1);
            color: rgba(25, 25, 25, 0.4);
          }

          label {
            padding: 0;
            margin: 0 4px 0 0;
            font-size: 16px;
          }

          .form-phonenumber {
            padding: 0;
            height: 50px;
            border: none;
          }

          .form-phonenumber:disabled {
            color: rgba(25, 25, 25, 0.4) !important;
          }
        }

        .alert-wrapper {
          display: flex;
          margin-top: 4px;

          svg {
            margin-right: 4px;
            font-size: 20px;
            position: relative;
            top: -2px;
          }

          p {
            font-size: 12px;
          }
        }
      }
    }
  }
}
