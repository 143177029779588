.copy-box-wrapper {
    border: 1px solid rgba(25, 25, 25, 0.1) !important;
    display: block;
    position: relative;
    background: #fff;
    border-radius: 8px;

    input {
        border: 0 !important;
        white-space: nowrap;
        background: #fff;
        padding: 8px 48px 8px 16px !important;
        color: #191919;
        border-radius: 8px;
        font-size: 16px;
        letter-spacing: -0.3px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    svg {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 16px;
        color: #191919; 
        cursor: pointer;

        &:hover {
            color: rgba(25, 25, 25, 0.8);
        }
    }
}