.launch-google {
  position: relative;
  width: 100%;
}

.no-launch-data {
  background: #fff;
  text-align: center;
  padding: 48px;
  width: 100%;
  text-align: center;
  
  b {
    font-size: 16px;
    letter-spacing: -0.5px;
  }

  p {
    font-size: 14px;
    letter-spacing: -0.2px;
    margin: 16px 0;
  }

  button {

  }
}

.launch-google {

  .launch-google-top {
    padding: 0px 0 28px;
    border-bottom: solid 1px #dcdcdc;
    font-size: 20px;
    letter-spacing: -0.6px;
    font-weight: 700;
    margin: 0 0 24px 0;

    .launch-google-top-button {
        position: absolute;
        right: 35px;
        top: 35px;
        font-size: 16px;
        letter-spacing: -0.5px;
        padding: 7px 32px;

        img {
            margin-right: 4px;
            position: relative;
            top: -2px;
        }
    }
  }

  .launch-google-middle {
    width: 100%;
    display: block;
    position: relative;
    margin-bottom: 24px;
    height: 48px;

    .unlaunch-button {
      right: 138px !important;
    }

    .launch-google-top-button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.modal-view-message {
  .modal-content {
    padding: 32px;
    width: 477px;
  }

  .modal-body {
    padding: 0;

    .header-modal {
      margin-bottom: 24px;

      b {
        font-size: 20px;
      }

      svg {
        margin-right: 4px;
        position: relative;
        top: -3px;
      }
    }

    .message-container {
      label {
        font-size: 14px;
      }

      input {
        display: flex;
        align-items: center;
        background: white;
        border-radius: 8px;
        border: 1px solid rgba(25, 25, 25, 0.1);
        height: 48px;
        width: 100%;
        color: #191919;
        font-size: 16px;
        letter-spacing: -0.3px;
        max-width: 100%;
        padding: 0 16px;
        margin-bottom: 24px;

        &:disabled {
          background: rgba(25, 25, 25, 0.05);
          cursor: not-allowed;
        }
      }

      textarea {
        background: white;
        border-radius: 8px;
        border: 1px solid rgba(25, 25, 25, 0.1);
        min-height: 48px;
        width: 100%;
        color: #191919;
        font-size: 16px;
        letter-spacing: -0.3px;
        max-width: 100%;
        padding: 12px 16px;
        line-height: 24px;
        resize: none;
        margin-bottom: 20px;

        &:disabled {
          background: rgba(25, 25, 25, 0.05);
          cursor: not-allowed;
        }
      }
    }
  }
}

.table-launch-list {
  table-layout: fixed;

  .custom-checkbox {
    display: inline-block;
  }

  thead {
    tr {
      background: transparent;

      td {
        padding: 8px;
        // width: 20%;
        font-size: 14px;
        word-wrap: break-word;
        // white-space: normal;
        position: relative;

        .question-circle-wrapper {
          margin-left: 5px;
          position: absolute;
          top: 25%;
        }

        &:first-child {
          width: 30px;
        }

        &:nth-child(2) {
          // width: 30%;
        }

        &:nth-child(4) {
          width: 150px;

          img {
            margin-left: 5px;
            vertical-align: -1.5px;
          }
        }

        &:last-child {
          // width: 100%;
          // text-align: right;

          .custom-select-option-value-wrapper {
            width: 55px !important;
            height: 23px;
            padding: 2px 8px 2px 12px !important;
            border-radius: 4px !important;
            // visibility: hidden;

            p {
              font-size: 12px;
              font-weight: bold;
            }

            .custom-select-option-value {
              padding: 0;
            }

            svg {
              right: 3px;
              height: 15px;
              top: 11px;
            }
          }
        }
      }
    }
  }

  tbody {
    tr {
      // cursor: pointer;
      border-bottom: none;

      &:hover {
        background-color: unset;

        td {
          color: unset;
        }
      }

      td:not(.no-data-column) {
        padding: 8px;

        p {
          margin-bottom: 0;
        }
      }

      td {
        background: transparent;
        position: relative;
        word-wrap: break-word;
        vertical-align: top;
        // white-space: nowrap;

        .unavailable {
          color: rgba(25, 25, 25, 0.4);
        }

        .link-user {
          color: inherit;
          text-decoration: inherit;
        }

        &:nth-child(2) {
          p {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }

        &.col-view {
          color: #ff7e00;
          cursor: pointer;
          font-weight: 700;
          text-align: right;

          a {
            color: #ff7e00;
          }

          svg {
            position: relative;
            color: #ff7e00;
            font-size: 16px;
            margin-right: 4px;
            right: 0;
            top: -2px;
          }
        }
      }
    }

    .no-data-row {
      .no-result-found {
        color: rgba(25, 25, 25, 0.8);
        margin-top: 18px;

      }
      
      button {
        svg {
          right: unset !important;
        }
      }
    }
  }

  #pagination-wrapper {
    visibility: hidden;
    ul {
      li {
        font-size: 12px !important;
        position: relative !important;

        &:not(.pagination-button) {
          top: 6px !important;
        }

        span {
          position: absolute;
          top: -42px;
          display: inline-block;
          left: 50%;
          transform: translate(-50%, 0);
        }

        &.active {
          width: 30px !important;
          height: 30px !important;

          span {
            top: -40px !important;
          }
        }

        &.pagination-label {
          top: 5px !important;

          svg {
            height: 12px;
          }
        }
      }
    }
  }
}

.modal-launch {
  .modal-content {
    width: 478px;

    button {
      width: calc(50% - 2px) !important;
      margin-right: 4px;

      &:nth-last-child(1) {
        margin-right: 0 !important;
      }
    }
  }
}

.modal-launch-error {
  .modal-content {
    button {
      width: 100% !important;
      margin-right: 0 !important;
      
      &.no-fill-button {
        display: none !important;
      }
    }
  }
}
