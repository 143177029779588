.view-request-message-template-whatsapp {
    .sample_url_wrapper {
      width: 100%;
      position: relative;
      padding-left: 40px;
      
      b {
        color: rgba(25, 25, 25, 0.6);
        font-size: 14px !important;
        letter-spacing: -0.4px !important;
        position: absolute;
        left: 0;
        top: 11px;
      }

      .box-value {
        height: 45px;
        padding: 12px 16px;
        border-radius: 8px;
        font-size: 14px;
        letter-spacing: -0.2px;
        border: solid 1px rgba(25, 25, 25, 0.05);
        color: rgba(25, 25, 25, 0.6);
        width: calc(100% - 40px);
      }
    }

    .marginTop24 {
      margin-top: 24px !important;
    }

    .wrapper-button-action {
      background: #f3f3f3;
      border-radius: 8px;
      padding: 12px;
      color: rgba(25, 25, 25, 0.6);
      font-size: 16px;
      letter-spacing: -0.3px;
      margin-top: 8px;
      border: solid 1px rgba(25, 25, 25, 0.05);

      label {
        color: rgba(25, 25, 25, 0.8) !important;
        font-size: 14px !important;
        letter-spacing: -0.3px !important;
        margin-bottom: 4px;
      }

      p {
        margin-bottom: 16px !important;

        svg {
          font-size: 16px;
          position: relative;
          // top: -1px;
          margin-right: 2px !important;
        }

        &:nth-last-child(1) {
          margin-bottom: 0 !important;
        }
      }
    }

    .sample-param-input-wrapper {
      padding-top: 8px;

      p {
        width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: inline-block;
        padding-right: 8px;
        padding-top: 11px;
        margin: 0;
        color: rgba(25, 25, 25, 0.6);
      }

      input {
        width: calc(100% - 150px) !important;
        display: inline-block !important;
        border: solid 1px #dcdcdc;
        border-radius: 8px;
        vertical-align: top;
        height: 45px;
        padding: 0 12px;
      }

      &.paddingTop0 {
        padding-top: 0 !important;
      }

      &.paddingBottom24 {
        padding-bottom: 24 !important;
      }
    }

    .view-request-message-template-whatsapp-top {
      padding: 10px 0 15px;
      border-bottom: solid 1px #dcdcdc;
      b {
          font-size: 20px !important;
          letter-spacing: -0.6px;
          font-weight: 700;
      }
    }
  
    div {
      p, b {
        font-size: 14px;
      }
  
      .disabled-form {
          div {
              background-color: rgba(25, 25, 25, 0.05);
              border: 1px solid rgba(25, 25, 25, 0.1);
              border-radius: 8px;
              padding: 12px 16px;
              width: 400px;
              margin: 8.5px 0 0 0;
              word-break: break-word;
              color: rgba(25, 25, 25, 0.6);

              p {
                font-size: 16px;
                margin: 0;
              }
          }
      }
    }
  
    div:first-child {
      margin-bottom: 24px;
      b {
        font-size: 16px;
      }
      svg {
        margin-right: 5px;
        vertical-align: -2.4px;
      }
      img {
          margin-right: 5px;
      }
    }
  
    .view-request-message-template-whatsapp-middle {
      padding: 0 0 24px;
      border-bottom: solid 1px #dcdcdc;
      .view-request-message-template-whatsapp-middle-category {
          p {
              margin-bottom: 0;
          }
      }
    }
  
    .view-request-message-template-whatsapp-bottom {
      padding: 24px 0 0;
      
      .view-message-box {
        position: relative;
        width: 100%;
      }
  
      .view-message-box-left {
        display: inline-block;
        vertical-align: top;
        width: 400px;
      }
  
      .view-message-box-right {
        width: calc(100% - 400px);
        vertical-align: top;
        display: inline-block;
        padding-left: 24px;
      }
    }

    div:last-child {
      .view-request-message-template-whatsapp-bottom-footer {
        p {
          margin-bottom: 0;
        }
      }
    }

    .icon-wrapper {
      display: inline-block;
      margin-right: 6px;
      border-radius: 50%;
      width: 28px;
      height: 28px;
      position: relative;
      vertical-align: top;
      margin-bottom: 0 !important;
      
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .media-type {
      font-size: 14px;
      letter-spacing: -0.2px;
      margin: 0;
    }

    .header-wrap {
      border: solid 1px #dcdcdc;
      display: inline-block;
      border-radius: 8px;
      padding: 10px;
      min-width: 120px;
      margin: 8px 0 0;
    }
    
    .header-image {
      line-height: 26px;
      font-size: 14px;
      letter-spacing: -0.2px;

      .icon-wrapper {
        background: #ECFFE9;
        color: #19C700;
      }
    }

    .header-video {
      line-height: 26px;
      font-size: 14px;
      letter-spacing: -0.2px;

      .icon-wrapper {
        color: #FF3F57;
        background: #ffecce;
      }
    }

    .header-document {
      line-height: 26px;
      font-size: 14px;
      letter-spacing: -0.2px;

      .icon-wrapper {
        background: #EBF7FF;
        color: #3BAEFF;
      }
    }
  }
  