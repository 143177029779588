.chat-room-message-in-tokopedia--wrapper {
    margin-top: 16px;
    padding: 0 24px;
    width: 100%;
    position: relative;

    .tokopedia-image-wrapper {
      background: rgba(25, 25, 25, 0.1);
      position: relative;
      width: 72px;
      height: 72px;
      border-radius: 6px;
      overflow: hidden;
      margin-right: 12px;
      display: inline-block;

      img {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .tokopedia-price-wrapper {
      margin: 0;
    }

    .tokopedia-link {
      cursor: pointer;
      color: var(--main-dark-orange);
      border: solid 1px var(--main-dark-orange);
      font-size: 14px;
      letter-spacing: -0.5px;
      width: 100%;
      text-align: center;
      border-radius: 6px;
      height: 34px;
      line-height: 32px;
      margin: 12px 0 4px 0;
      
      svg {
        font-size: 12px;
        position: relative;
        top: -1px;
        margin-right: 4px;
      }

      &:hover {
        color: var(--main-darker-orange);
        border: solid 1px var(--main-darker-orange);
        svg {
          color: var(--main-darker-orange);
        }
      }
    }

    .tokopedia-product-description-wrapper {
      vertical-align: top;
      width: calc(100% - 84px);
      display: inline-block;
    }
  }
  .chat-room-message-in-tokopedia--wrapper .message-in-bubble {
    border-radius: 2px 16px 16px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    background: #ffffff;
    display: inline-block;
    /* max-width: calc(100% - 180px); */
    // max-width: 80%;
    // max-width: calc(100% - 110px);
    max-width: calc(100% - 148px);
    padding: 10px 45px 10px 10px;
    padding: 10px;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    line-height: 24px;
    position: relative;
    // cursor: pointer;
    min-height: 44px;
  }
  .chat-room-message-in-tokopedia--wrapper .message-in-bubble.deleted-bubble {
    color: #9b9b9b;
    padding: 10px 10px 10px 33px;
  }
  .chat-room-message-in-tokopedia--wrapper .message-in-bubble.deleted-bubble .deleted-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);
  }
  .chat-room-message-in-tokopedia--wrapper .message-in-bubble.deleted-group-in {
    padding: 10px;
  }
  .chat-room-message-in-tokopedia--wrapper .message-in-bubble.deleted-group-in .deleted-icon {
    position: relative;
    left: 0;
    top: 5px;
    margin-right: 3px;
    transform: unset;
  }
  .chat-room-message-in-tokopedia--wrapper .message-in-bubble .forwarded-message {
    color: #191919;
    font-size: 12px;
    letter-spacing: -0.4px;
    line-height: 18px;
    display: block;
    margin-bottom: 5px;
  }
  .chat-room-message-in-tokopedia--wrapper .message-in-bubble .reply-message {
    width: calc(100% - 20px);
    border-radius: 4px;
    border-left: solid 5px #ff7d00;
    background: #f3f3f3;
    color: #191919;
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 5px 7px 5px 7px;
    line-height: 1;
  }
  .chat-room-message-in-tokopedia--wrapper .message-in-bubble .reply-message p {
    margin: 0;
    font-size: 12px;
    letter-spacing: -0.4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
  }
  .chat-room-message-in-tokopedia--wrapper .message-in-bubble .reply-message p:nth-child(2) {
    letter-spacing: -0.2px;
  }
  .chat-room-message-in-tokopedia--wrapper .message-in-bubble .reply-message.reply-file .reply-file-thumbnail {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    position: relative;
  }
  .chat-room-message-in-tokopedia--wrapper .message-in-bubble .reply-message.reply-file .reply-file-thumbnail img {
    width: 40px;
    height: 40px;
  }
  .chat-room-message-in-tokopedia--wrapper .message-in-bubble .reply-message.reply-file .reply-file-thumbnail svg {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 35px;
    transform: translate(-50%, -50%);
  }
  .chat-room-message-in-tokopedia--wrapper .message-in-bubble .reply-message.reply-file .reply-text-wrapper {
    width: calc(100% - 50px);
    display: inline-block;
    vertical-align: top;
  }
  .chat-room-message-in-tokopedia--wrapper .message-in-bubble .message-info {
    /* position: absolute; */
    margin: 0;
    /* bottom: 11px; */
    /* right: 10px; */
    color: rgba(25, 25, 25, 0.4);
    font-size: 10px;
    letter-spacing: -0.4px;
    line-height: 18px;
    /* width: 24px; */
    width: 100%;
    text-align: right;
    /* width: 26px; */

    .centered-dot {
      width: 2px;
      height: 2px;
      display: inline-block;
      vertical-align: top;
      background: rgba(25, 25, 25, 0.4);
      border-radius: 50%;
      position: relative;
      top: 9px;
    }
  }
  .chat-room-message-in-tokopedia--wrapper .message-in-bubble .group-sender-name-wrapper {
    color: #ff7d00;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    font-size: 14px;
  }
  .chat-room-message-in-tokopedia--wrapper .message-in-bubble .group-sender-name-wrapper .agent-span {
    color: #A3A3A3;
  }
  .chat-room-message-in-tokopedia--wrapper:hover .message-action-wrapper {
    display: inline-block;
  }
  .chat-room-message-in-tokopedia--wrapper .group-sender-avatar-wrapper {
    width: 32px;
    height: 32px;
    display: inline-block;
    border-radius: 50%;
    vertical-align: top;
    margin-right: 8px;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
    position: relative;
  }
  .chat-room-message-in-tokopedia--wrapper .group-sender-avatar-wrapper .avatar-icon-badge {
    width: 20px;
    bottom: -4px;
    right: -4px;
    height: 20px;
    position: absolute;
  }
  .chat-room-message-in-tokopedia--wrapper .group-sender-avatar-wrapper img {
    width: 32px;
    height: 32px;
    display: inline-block;
    border-radius: 50%;
  }
  .chat-room-message-in-tokopedia--wrapper .message-action-wrapper {
    /* right: -120px !important; */
    right: -80px !important;

    &.with-forward {
      right: -118px !important;
      
      .forward-button {
       display: inline-block !important;
      } 
    }
  }

  //reply button
  .hide-reply-button .message-in-bubble .message-action-wrapper {
    right: -40px !important;

    &.with-forward {
      right: -78px !important;
    }
  }
  //reply button