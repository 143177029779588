.modal-choose-channel {
  .modal-content {
    text-align: left;
    width: 500px;

    .modal-body {
      padding: 32px;

      svg,
      img {
        margin-right: 5px;
      }

      b {
        font-weight: 700 !important;
      }

      button {
          height: 40px;
          padding: 5px 20px;
      }

      #modal-choose-channel-title {
        font-size: 20px;
      }

      > :nth-child(2) {
        margin-top: 15px;
      }

      .modal-choose-channel-options {
        div {
          border: solid 1px #f5f5f5;
          border-radius: 8px;
          width: fit-content;
          padding: 5px 20px;
          margin: 5px 0;
          cursor: pointer;

          b {
            color: rgba(25, 25, 25, 0.6);
          }

          img {
            width: 16px;
            height: 16px;
            vertical-align: -3px;
          }
        }

        div:hover {
          background-color: #fff2e6;

          b {
            color: #ff7e00;
          }
        }

        .modal-choose-channel-selected {
          border: solid 1px #fff2e6;
          background-color: #fff2e6;
          
          b {
            color: #ff7e00;
          }
        }
      }

      .modal-choose-channel-buttons {
          margin-top: 20px;

          .no-fill-button {
              width: 120px;
              margin-right: 5px;
          }

          .orange-button {
              width: 308px;
          }
      }
    }
  }
}
