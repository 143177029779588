.detail-modal-history {
    background: red;

    .detail-modal-content {
        width: 100%;
        position: relative;
        display: block;
        max-height: 100%;
        overflow: auto;
    
        .detail-modal-content-top {
            padding: 24px 24px 24px 24px;
            border-bottom: solid 1px #dcdcdc;

            #copy-string-id {
                background: 0;
                border: 0;
            }
        }

        .detail-modal-content-bottom {
            padding: 10px 24px 24px 24px;

            .case-title {
                font-size: 24px;
                letter-spacing: -0.6px;
                color: rgba(25, 25, 25, 1);
                margin: 0 0 12px 0;
                display: block;
            }

            .case-action-history-card {
                padding: 0 20px 8px 30px;
                position: relative;
                margin-bottom: 12px;
                
                &::before {
                    position: absolute;
                    border-radius: 50%;
                    width: 16px;
                    height: 16px;
                    left: 0;
                    top: 10px;
                    // transform: translate(0, -50%);
                    content: "";
                    border: solid 2px rgba(25, 25, 25, 0.2);
                    background: #ffffff;
                    z-index: 2;
                }

                &::after {
                    position: absolute;
                    width: 1px;
                    height: 100%;
                    left: 7px;
                    top: 24px;
                    content: "";
                    border-left: dashed 2px rgba(25, 25, 25, 0.2);
                    z-index: 1;
                }

                &:nth-child(1) {
                    &::before {
                        border: solid 2px #ff7d00;
                    }
                }
                
                p {
                    max-width: 100%;
                    // text-overflow: ellipsis;
                    // overflow: hidden;
                    // white-space: nowrap;

                    &.action-time {
                        font-size: 12px;
                        letter-spacing: -0.2px;
                        color: rgba(25, 25, 25, 0.4);
                        margin: 0;

                        svg {
                            position: relative;
                            margin-right: 4px;
                            top: -1px;
                        }
                    }

                    &.action-text {
                        margin: 0;
                        font-size: 14px;
                        letter-spacing: -0.4px;
                        color: rgba(25, 25, 25, 0.4);
                    }
                }

                &:nth-last-child(1) {
                    &::after {
                        display: none;
                    }
                }

                &:nth-child(1) {
                    margin-top: 12px;
                }
            }
        }

        .detail-value-wrapper {
            background: rgba(25, 25, 25, 0.08);
            border-radius: 8px;
            width: 100%;
            padding: 6px 6px 6px 32px;
            position: relative;
            font-size: 14px;
            letter-spacing: -0.2px;

            p {
                margin: 0;
            }

            &.detail-value-with-copy {
                padding: 7px 62px 6px 32px;
            }

            .icon-title {
                position: absolute;
                left: 10px;
                top: 10px;
            }

            .icon-copy {
                color: rgba(25, 25, 25, 0.6);
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translate(0, -50%);
                font-size: 12px;
                letter-spacing: -0.2px;
                cursor: pointer;
                
                svg {
                    margin-right: 4px;
                    font-size: 14px;
                    position: relative;
                    top: -1px;
                }

                &:hover {
                    color: rgba(25, 25, 25, 1);
                }
            }
        }

        label {
            font-size: 14px;
            letter-spacing: -0.4px;
            color: rgba(25, 25, 25, 0.8);
        }
    }
}