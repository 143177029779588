.review-transaction-main-wrapper {
  width: 100%;
  min-height: calc(100vh - 87px);
  margin-top: 87px;
  background: #f3f3f3;

  .review-transaction-content {
    .review-transaction-header {
      padding: 40px 72px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      .comp-back-button {
        margin: 0;
      }
      
      .button {
        padding: 8px 24px;
        // display: flex;
      } 
    }

    .review-transaction-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 96px;

      h1 {
        margin: 32px 0;
      }

      .review-container {
        width: 600px;
        background-color: #ffffff;
        border-radius: 8px;
        border: 1px solid rgba(25, 25, 25, 0.1);
        padding: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .box-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;

          .box-item-container {
            display: flex;
            justify-content: space-between;
            width: 100%;
            // border: 1px solid red;
            box-shadow: 0px 1px 0px rgba(25, 25, 25, 0.1);
            margin-bottom: 32px;

            &:nth-child(3) {
              margin-bottom: 24px;
              box-shadow: none;
            }

            .img-title-wrapper {
              display: flex;

              .image-credit-card {
                width: 46px;
                height: 36px;
              }

              b {
                font-size: 24px;
              }

              .method-wrapper {
                max-width: 297px;
              }
            }

            img {
              width: 48px;
              height: 48px;
              margin-right: 12px;
            }

            button {
              &.tier-button {
                width: 131px;
                height: 40px;
              }

              &.method-button {
                width: 156px;
                height: 40px;

                svg {
                  position: relative;
                  top: -2px;
                  margin-right: 0;
                  font-size: 20px;
                }
              }

              &.card-button {
                width: 136px;
                height: 40px;
              }
            }
          }
        }

        .trial-container {
          width: 100%;
          background: rgba(25, 25, 25, 0.05);
          border-radius: 8px;
          margin: 0 0 16px;
          padding: 24px 0;
          display: flex;
          justify-content: center;
          align-items: center;
          // margin-bottom: 24px;

          b {
            font-size: 24px;
          }
        }

        .amount-container {
          width: 100%;
          background: rgba(25, 25, 25, 0.05);
          border-radius: 8px;
          padding: 24px 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          // margin-bottom: 24px;
          p {
            color: rgba(25, 25, 25, 0.6);
            font-size: 16px;
            margin-bottom: 10px;
          }

          b {
            font-size: 32px;
          }
        }

        .alert-container {
          margin: 24px 0;
        }

        button {
          &.confirm-button {
            width: 100%;
            height: 40px;

            .lds-ring {
              top: 3px;
              right: unset;

              div {
                border-color: #ffffff transparent transparent transparent;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

.confirm-modal {
  .modal-content {
      width: 476px;
  }

  .modal-body {
      padding: 32px;

      .confirm-modal-wrapper {
          position: relative;

          .confirm-title-wrapper {
            display: flex;
            align-items: center;

            .confirm-title {
                font-size: 20px;
                letter-spacing: -0.6px;
                color: #191919;
                line-height: 1;
                margin: 0;
            }

            svg {
              width: 18px;
              height: 18px;
              margin-right: 7px;
            }
          }

  
          .confirm-description {
              font-size: 14px;
              letter-spacing: -0.2px;
              color: rgba(25, 25, 25, 0.6);
              line-height: 21px;
              margin: 6px 0 20px;
          }

          .button-container {
            width: 100%;
            display: flex;
            justify-content: space-between;

            button {
              &.cancel {
                width: 23%;
                height: 48px
              }

              &.discard {
                width: 75%;
                height: 48px;

                svg {
                  margin-right: 9px;
                }
              }
            }
          }
      }
  }
}