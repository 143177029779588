.number-dropdown {
    float: right;
    margin: 6.5px 6px 0;

    &.disabled-number-dropdown {
        button {
            background: rgba(25, 25, 25, 0.1) !important;
            color: rgba(25, 25, 25, 0.4) !important;
        }
    }

    button {
        background: #ffffff !important;
        border: solid 1px #dcdcdc !important;
        outline: none !important;
        box-shadow: none !important;
        color: #191919 !important;
        padding: 2px 8px 2px 12px;
        border-radius: 4px;
        position: relative;
        font-size: 12px;
        font-family: PTRootBold;
        line-height: 18px;
        letter-spacing: -0.4px;

        .icon-caret {
            font-size: 12px;
            margin-left: 4px;
            transition: transform 0.2s;
        }

        .icon-caret-open {
            transform: rotate(180deg);
        }
    }

    .dropdown-menu {
        padding: 0;
        border-radius: 8px;
        box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
        border: none !important;

        button {
            border: none !important;
            // border-bottom: solid 1px #dcdcdc !important;
            border-radius: 0;
            padding: 7px 18px 7px 36px;
            position: relative;
            font-family: PTRootUI !important;
            font-size: 14px !important;
            
            &:nth-child(1) {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }

            &:nth-last-child(1) {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                border-bottom: 0 !important;
            }

            &:hover {
                background: rgba(255, 242, 229, 1) !important;
            }

            svg {
                position: absolute;
                left: 16px;
                top: 50%;
                transform: translate(0, -50%);
                font-size: 16px;
                color: #ff7e00;
            }
        }

        .active {
            color: #FF7E00 !important;
        }
    }
}
