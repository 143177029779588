.view-pending-payment-wrapper {
    #retrieve-data-loading-page {
        padding-top: 0;
    }

    .view-pending-payment-title {
        font-size: 20px;
        letter-spacing: -0.6px;
        color: #191919;
        padding-bottom: 12px;
        border-bottom: solid 1px #dcdcdc;
    }

    table {
        width: 100%;
        margin-top: 24px;

        thead {
            tr {
                border-bottom: solid 1px rgba(25, 25, 25, 0.1);
                
                td {
                    padding: 8px;
                    text-align: left;
                    color: #191919;
                    font-size: 14px;
                    letter-spacing: -0.4px;
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: 16px 8px;
                    text-align: left;
                    color: #191919;
                    font-size: 14px;
                    letter-spacing: -0.2px;

                    .subscription-label-view {
                        color: #ff7e00;
                        cursor: pointer;
                        
                        svg {
                            position: relative;
                            margin-right: 4px;
                            top: -2px;
                        }

                        &:hover {
                            color: #e87200;
                        }
                    }

                    &.col_total {
                        background: #DCEBFF;
                        font-size: 16px;
                        letter-spacing: -0.5px;
                        text-align: right;
                        width: 100%;
                    }
                }

                &:nth-child(even) {
                    background: rgba(25, 25, 25, 0.05);
                }
            }
        }
    }
} 