.chat-room-case-history-modal-header-wrapper {
    position: relative;
    background: #ffffff;
    width: 100%;
    height: 48px;
    border-top-left-radius: 18px;
    transition: ease-in-out .1s;

    .chat-room-identifier {
       width: calc(100% - 205px) !important;
       overflow: hidden;
       text-overflow: ellipsis;
    }
}

.chat-room-case-history-modal-header-wrapper.modal-chat-room-header-show-detail {
    width: calc(100% - 340px);
}

.chat-room-case-history-close {
    background: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-block;
    text-align: center;
    line-height: 45px;
    top: 0;
    left: -64px;
}

.chat-room-case-history-close svg {
    font-size: 24px;
    color: #7D7C7D;
}

.chat-room-case-history-close:hover svg {
    color: #5c5c5c;
}

.chat-room-case-history-modal-header-wrapper .open-detail-button {
    right: 16px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

.chat-room-case-history-modal-header-wrapper .open-detail-button {
    /* height: calc(100% - 8px);
    line-height: 25px; */
    height: 26px;
    line-height: 26px;
}