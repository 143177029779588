.topic {
  position: relative;
}

.form-topic-detail label {
  margin: 0 0 8px;
}

.form-topic-detail .input-wrapper {
  padding: 0;
  margin: 0;
}

.assigned-agent-content {
  margin: 24px auto !important;
}

.action-button svg {
  position: relative;
  top: -1px;
}

.form-topic-detail .orange-button {
  margin-top: 12px;
}

.form-topic-detail .pos-relative {
  width: 350px;
}

.form-topic-detail span {
  font-weight: normal;
}
