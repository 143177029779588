.modal-invitation-sent {
    .modal-content {
        width: 476px;
        border-radius: 8px;

        .modal-body {
            padding: 32px 24px;
            text-align: center;

            img {
                margin: 0 0 8px 0;
            }

            b {
                font-size: 20px;
            }

            p {
                margin: 8px 0 20px;
                font-size: 14px;
                letter-spacing: 21px;
                letter-spacing: -.2px;
            }
            
            button {
                height: 40px;
                width: 100%;
            }
        }
    }
}

.invite-member-wrapper {
    label {
        width: 317px;
    }
    
    .form-member-invite {
        button {
            width: inherit;
        }
    }
}