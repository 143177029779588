.chat-room-header-container {
  height: 48px;
  background: #ffffff;
  width: 100%;
  position: relative;
  box-shadow: 0 1px 0 0 rgba(25, 25, 25, 0.1);
  z-index: 1;
  transition: ease-in-out .1s;
  border-right: solid 1px #dcdcdc;

  &.chat-room-header-show-detail {
    width: calc(100% - 340px);
    vertical-align: top;
  }
}
.chat-room-header-container .chat-room-header-avatar-wrapper {
  height: 40px;
  padding: 6px 0 0 16px;
  display: inline-block;
  position: relative;
  top: 2px;
  vertical-align: top;
}
.chat-room-header-container .chat-room-header-avatar-wrapper .chat-rooom-omnichannel-header {
  position: relative;
  right: 8px;
}
.chat-room-header-container .chat-room-identifier {
  position: relative;
  top: 2px;
  display: inline-block;
  max-width: calc(100% - 354px);

  P {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }
}
.chat-room-header-container .chat-room-identifier .connectivity-status {
  position: relative;
  color: rgba(25, 25, 25, 0.6);
  font-size: 12px;
  letter-spacing: -0.2px;
  line-height: 16px;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.chat-room-header-container .chat-room-identifier .connectivity-status.status-online::before {
  content: "";
  background: #19C700;
  border-radius: 8px;
  height: 8px;
  width: 8px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.chat-room-header-container .user-avatar-name {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 50%;
  line-height: 32px;
  color: #ffffff;
  position: relative;
  text-align: center;
  /* cursor: pointer; */
  background: #f9f9f9;
  font-size: 15px;
  vertical-align: top;
}
.chat-room-header-container img {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  /* right: 24px; */
  /* cursor: pointer; */
}

.chat-room-header-container .button-icon img {
  height: 12px !important;
  width: 12px !important;
  border-radius: unset !important;
}

.open-detail-button {
  cursor: pointer;
  transition: ease-in-out;
  position: relative;
  /* right: 16px;
  top: 50%;
  transform: translate(0, -50%); */
  color: #ff7d00;
  font-size: 14px;
  letter-spacing: -0.5px;
  transition: ease-in-out .3s;
  border-radius: 6px;
  /* padding: 7px 24px 8px 24px; */
  padding: 0 12px 0 10px !important;
  /* line-height: 25px; */
  display: inline-block;
  transition: ease-in-out .1s;
  vertical-align: top;
  /* height: 100%; */
  height: 26px;
  line-height: 26px;
}

.open-detail-button:hover {
  background: #fee7ce;
}

.open-detail-button svg {
  font-size: 14px;
  position: relative;
  top: -2px;
  margin-right: 4px;
}

.chat-room-header-container .chatroom-header-right-button-wrapper {
  display: inline-block;
  position: absolute;
  right: 12px;
  top: 0;
  /* transform: translate(0, -50%); */
  height: 100%;
  padding: 11px 0;
} 

.chat-room-header-container .mark-case-button {
  position: relative;
  display: inline-block;

  .mark-case-dropdown {
    background-color: #ffffff;
    display: none;
    position: absolute;
    width: 165px;
    right: 50%;
    transform: translateX(50.5px);
    border-radius: 8px;
    top: calc(100% + 25px);
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.16);
    ul {
      padding: 0;
      margin: 0;
    }
    li {
      padding: 8px;
      color: rgba(25, 25, 25, 0.6);
      list-style: none;
      cursor: pointer;

      svg {
        vertical-align: -2px;
        margin-right: 8px;
      }
    }
    li:hover {
      color: #FF7E00;
    }
  }
  .mark-case-dropdown:after {
    bottom: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .mark-case-dropdown:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 12px;
    left: 80%;
    margin-left: -12px;
  }
}

.chat-room-header-container .mark-as-resolved-wrapper {
  display: inline-block;
  /* height: 100%; */
  height: 26px;
  top: -2px !important;

  .button-text-wrapper {
    b {
      font-size: 14px;
      position: relative;
      top: -1px;
    }
  }
}

.chat-room-header-container .mark-as-resolved-wrapper button {
  cursor: pointer;
  transition: ease-in-out;
  position: relative;
  /* right: 178px;
  top: 50%;
  transform: translate(0, -50%); */
  font-size: 14px;
  letter-spacing: -0.5px;
  transition: ease-in-out .3s;
  border-radius: 6px;
  /* padding: 6px 0 7px; */
  /* line-height: 24px; */
  /* width: 191px; */
  height: 100%;
  margin-right: 8px;
  padding: 0 12px 0 10px !important;
  vertical-align: top;
  line-height: 24px;
  background: #ff7d00;
}

.chat-room-header-container .mark-as-resolved-wrapper button svg {
  position: relative;
  /* top: -2px; */
  font-size: 14px;
  /* margin-right: 4px; */
}

.chat-room-header-container .mark-as-resolved-wrapper .lds-ring {
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  right: unset;
  position: absolute;
}

.chat-room-header-container .mark-as-resolved-wrapper .lds-ring div {
  margin: 0;
}