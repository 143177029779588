.link-preview-container {
    position: relative;
  	max-width: 100%;
  	margin-top: 8px;
  	margin-bottom: 12px;
  	font-size: 12px;

    .link-preview-side-decoration-in {
        position: absolute;
        width: 2px;
        height: 100%;
        left: 0px;
        background-color: var(--main-dark-orange);
    }

    .link-preview-side-decoration-out {
        position: absolute;
        width: 2px;
        height: 100%;
        left: 0px;
        background-color: white;
    }

    .link-preview-title-in {
        width: calc(100% - 12px);
        position: relative;
        top: -2px;
        margin-left: 12px;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #191919;
    }

    .link-preview-title-out {
        width: calc(100% - 12px);
        position: relative;
        top: -2px;
        margin-left: 12px;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: white;
    }

    .link-preview-description-in {
        position: relative;
        width: calc(100% - 12px);
        max-height: 112px;
        margin-left: 12px;
        margin-bottom: 0px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.2px;
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        // display: -webkit-box;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // -webkit-line-clamp: 3;
        //         line-clamp: 3; 
        // -webkit-box-orient: vertical;
        color: #191919;
    }

    .link-preview-description-out {
        position: relative;
        width: calc(100% - 12px);
        max-height: 112px;
        margin-left: 12px;
        margin-bottom: 0px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.2px;
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        color: white;
    }

	.link-preview-image {
        position: relative;
		// max-width: 456px;
		max-width: calc(100% - 12px);
		max-height: 200px;
		margin-top: 12px;
		margin-left: 12px;
		border-radius: 8px;
		object-fit: contain;
	}
}
