.room-list-container {
    position: relative;
    /* width: 380px; */
    // width: 360px;
    // width: 320px;
    width: 382px;
    display: inline-block;
    background: #F2F2F2;
    box-shadow: 0px 0px 0px 1px rgba(25, 25, 25, 0.1);

    // box-shadow: -1px 0 #dcdcdc;
    // height: calc(100vh - 56px);
    height: 100%;
    max-height: 100%;
    vertical-align: top;
    z-index: 2;
    overflow: hidden;
    /* border-right: solid 1px #dcdcdc; */
}

.room-list-chat-container {
    /* box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.05); */
    /* max-height: calc(100vh - 120px);
    overflow-y: auto; */
    /* height: 100%; */
    background: #f3f3f3;
    transition: ease-in-out .2s;
  }
  .room-list-chat-container.solved-case-panel-active {
    max-height: calc(100vh - 104px);
  } 
  .room-list-chat-container .room-list-loading-wrapper {
    width: 100%;
    padding-top: 20px;
    text-align: center;
  }
  .room-list-chat-container .room-list-loading-wrapper .lds-ring {
    top: 0;
    left: 0;
  }
  .room-list-chat-container .room-list-loading-wrapper .lds-ring div {
    border-color: #ff7d00 transparent transparent transparent;
    margin: 0 !important;
  }
  .room-list-chat-container .room-list-loading-wrapper b {
    color: #ff7d00;
  }
  .room-list-chat-container .chat-list-wrapper {
    background: #ffffff;
    width: 100%;
    padding: 4px 0 0 12px;
    cursor: pointer;
    position: relative;
    // height: 100%;
  }
  .room-list-chat-container .chat-list-wrapper.chat-list-unassigned{
    position: relative;
  }
  .room-list-chat-container .chat-list-wrapper.chat-list-unassigned::before{
    position: absolute;
    background: #ffc0c0;
    top: 0;
    left: 0;
    /* border-radius: 8px; */
    // transform: translate(-50%, -50%); 
    content: "";
    height: 100%;
    width: 100%;
  }
  /* .room-list-chat-container .chat-list-wrapper:hover {
    background: rgb(25, 25, 25, 0.05);
  } */
  .room-list-chat-container .chat-list-wrapper .chat-avatar-wrapper {
    /* width: 80px; */
    width: 32px;
    height: 32px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    position: relative;
  }
  .room-list-chat-container .chat-list-wrapper .chat-avatar-wrapper img {
    width: 52px;
    height: 52px;
    display: inline-block;
    border-radius: 50%;
    object-fit: cover;
  }
  .room-list-chat-container .chat-list-wrapper .chat-avatar-wrapper img.group-badge {
    /* width: 22px;
    height: 22px;
    position: absolute;
    bottom: 1px;
    right: 11px; */
    width: 32px;
    height: 32px;
  }
  .room-list-chat-container .chat-list-wrapper .chat-avatar-wrapper .user-avatar-name {
    border-radius: 50%;
    width: 52px;
    height: 52px;
    display: inline-block;
    text-align: center;
    font-size: 20px;
    letter-spacing: -0.6px;
    color: white;
    line-height: 52px;
    background: #f9f9f9;
  }
  .room-list-chat-container .chat-list-wrapper .dialog-message-wrapper {
    width: calc(100% - 41px);
    margin-left: 8px;
    display: inline-block;
    position: relative;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1);
    // box-shadow: 0 1px rgba(25, 25, 25, .1);
    // padding: 0 0 4px 0;
    padding: 0 0 5px;
    height: 100%;
    /* top: -1px; */
  }
  .room-list-chat-container .chat-list-wrapper .dialog-message-wrapper.no-margin-bottom {
    border: none;
  }
  .room-list-chat-container .chat-list-wrapper:nth-last-child(1) .dialog-message-wrapper {
    /* border: none; */
  }
  .room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-top {
    position: relative;
    width: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    padding-right: 77px;
  }
  .room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-top .room-list-room-name {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    margin-bottom: 2px;
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 20px;
  }
  .room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-top .label-case-status {
    color: #ffffff;
    font-size: 10px;
    letter-spacing: 1.5px;
    height: 20px;
    border-radius: 8px;
    display: inline-block;
    text-align: center;
    padding: 2px 16px 0;
    vertical-align: top;
    max-width: calc(100% - 8px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-top .label-case-status.case-unassigned {
    background: #ff3f57;
  }
  .room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-top .label-case-status.case-taken {
    background: #0052ff;
  }
  .room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-top .label-case-status.case-my-case {
    background: #19c700;
  }
  .room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-top .label-case-status.case-solved {
    background: rgba(25, 25, 25, 0.2);
    color: rgba(25, 25, 25, 0.6);
  }
  .room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-top .dialog-date {
    color: rgba(25, 25, 25, 0.6);
    font-size: 12px;
    letter-spacing: -0.4px;
    /* line-height: 18px; */
    line-height: 1;
    padding-top: 6px;
    position: absolute;
    right: 12px;
    top: 0;
  }
  .room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-top b {
    /* display: block; */
  }
  .room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-bottom {
    color: rgba(25, 25, 25, 0.6);
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 21px;
    padding: 0 90px 0 0;
    position: relative;
    min-height: 32px;
  }
  .room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-bottom p {
    margin: 0;
    white-space: nowrap;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    line-height: 18px;
    color: #191919;
    line-height: 18px;
    max-width: 100%;
    white-space: nowrap;
    font-size: 14px;
    letter-spacing: -0.4px;
  }
  .room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-bottom .message-status {
    position: absolute;
    top: 4px;
    right: 12px;
  }
  .room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-bottom .message-status .unread-count-wrapper {
    background: #ff7d00;
    color: #ffffff;
    font-size: 12px;
    border-radius: 20px;
    height: 18px;
    min-width: 18px;
    text-align: center;
    line-height: 18px;
    padding: 0 5px;
    font-weight: bold;
    // letter-spacing: -0.4px;
    margin-top: 3px;

    &.white-unread-count-wrapper {
      background: #ffffff;
      color: #ff7d00;
    }
  }

  .room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-bottom .message-status .need-reply-badge {
    font-size: 12px;
    letter-spacing: -0.4px;
    text-align: center;
    color: #ffffff;
    background: #ff3f57;
    border-radius: 12px;
    width: 74px;
    display: block;
    line-height: 1;
    padding: 4px 0;

    svg {
      width: 12px;
      height: 12px;
      margin-right: 4px;
      vertical-align: -2px;
    }

    &.white-need-reply-badge {
      background: #ffffff !important;
      color: #ff3f57 !important;
    }
    &.white-junk-badge {
      background: #ffffff;
      color: #ff7e00;
     
    }
  }

  .chat-list-wrapper.active-typing .last-message-room-list {
    display: none;
  }
  .chat-list-wrapper .typing-room-list {
    display: none;
  }
  .chat-list-wrapper.active-typing .typing-room-list {
    display: block;
  }
  .room-list-chat-container .last-message-room-list {
      color: rgba(25, 25, 25, 0.6) !important;
  }
  .chat-list-wrapper.unassigned-room-list {
      background: rgb(255, 192, 192);
  }
  .room-list-chat-container .chat-list-wrapper.active-need-reply::before {
    background: #ffc0c0;
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .room-list-chat-container .chat-list-wrapper.active-chat-list::before {
    // background: rgba(255, 126, 0, 0.1);
    background: #FF7E00;
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .room-list-chat-container .chat-list-wrapper.active-chat-list {
    &:hover::before {
      background-color: #FF7E00 !important;
    }

    .dialog-message-wrapper {
      .dialog-top {
        .room-list-room-name {
          color: #ffffff;
        }

        .dialog-date {
          color: #ffffff;
        }
      }

      .dialog-bottom {
        .chat-room-room-name {
          color: #ffffff;
        }

        .last-message-room-list {
          color: #ffffff !important;
        }
      }

      .case-tab-label-wrapper {
        .more-label {
          color: #ffffff;
        }
      }
    }
  }
  .room-list-chat-container .chat-list-wrapper:hover::before {
    background: rgba(25, 25, 25, 0.1);
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .room-list-main-chat-container {
    border-bottom: solid 1px #dcdcdc;
  }

.case-room-search-filter-wrapper {
  position: relative;
  width: 100%;
  background: #ffffff;
  border-bottom: solid 1px #dcdcdc;
}

.case-room-search-wrapper {
  background: #ffffff;
  width: 100%;
  position: relative;
  height: 52px;
  padding: 8px 12px;
}

.submit-filter-button {
  background: #ffffff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border: solid 1px rgba(25, 25, 25, 0.1);
  position: absolute;
  top: 8px;
  right: 12px;
  height: 36px;
  width: 40px;
  transition: none;
}

.submit-filter-button svg {
  position: relative;
  top: -2px;
}

.case-room-search-wrapper input {
  border-radius: 8px;
  width: 100%;
  border: solid 1px rgba(25, 25, 25, 0.05);
  height: 36px;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: #191919;
  padding: 9px 45px 9px 12px;
  border: 0;
  background: rgba(25, 25, 25, 0.05);
  outline: none;
}

.case-room-search-wrapper input:focus {
  border: solid 1px #ff7d00;
  background: #ffffff;
}

.case-room-search-wrapper input:focus + .submit-filter-button {
  border-color: #ff7d00;
  background: #ff7d00;
}

.case-room-search-wrapper input:focus + .submit-filter-button svg {
  color: #ffffff;
}

.case-room-search-wrapper input::placeholder {
  color: rgba(25, 25, 25, 0.4) !important;
  font-size: 12px !important;
}

.case-room-search-wrapper .case-search-icon {
  position: absolute;
  top: 50%;
  left: 20px;
  font-size: 18px;
  transform: translate(0, -50%);
  color: rgba(25, 25, 25, 0.4);
}

.case-room-search-wrapper input:focus + .case-search-icon {
  color: #191919;
}

.case-room-search-wrapper .case-search-close-icon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
  font-size: 18px;
  color: #ff7d00;
  display: none;
}

.case-room-search-wrapper input:focus + .case-search-close-icon {
  display: block;
}

.case-room-filter-tag-wrapper {
  width: 100%;
  height: 34px;
  padding: 0 12px;
}

.case-room-filter-tag-badge {
  // margin-right: 8px;
  margin-right: 4px;
  border-radius: 4px;
  white-space: nowrap;
  display: inline-block;
  height: 22px;
  /* font-weight: bold; */
  border: solid 1px rgba(25, 25, 25, 0.1);
  cursor: pointer;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.4px;
  background: #ffffff;
  padding: 0 6px;
  vertical-align: top;
  white-space: nowrap;
  margin-top: 5px;

  &:hover {
    background: #f6f6f6;
  }
}

.case-room-filter-tag-badge-active {
  background: #ff7d00;
  color: #ffffff !important;
  border: solid 1px #ff7d00;

  &:hover {
    background: #e87200;
    border: solid 1px #e87200;
  }
}

.case-room-filter-tag-badge svg {
  margin-right: 3px;
  position: relative;
  top: -1px;
}

.case-room-filter-tag-badge.active-filter-badge {
  color: #ffffff;
  background: #ff7d00;
}

.agent-badge {
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: -0.4px;
  color: #ffffff;
  border-radius: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  padding: 1px 12px 2px;
  font-weight: bold;
  margin-top: 2px;
  max-width: calc(100% - 12px);
}

.agent-badge-wrapper {
  width: 100%;
  display: block;
  height: 24px;
}

.agent-badge img {
  position: relative;
  top: -1px;
  margin-right: 4px;
}

.agent-badge svg {
  position: relative;
  top: -1px;
  margin-right: 4px;
}

.other-agent-badge {
  background: #0052ff;
}

.my-agent-badge {
  background: #19c700;
}

.unassigned-agent-badge {
  // background: #ff3f57;
  background: #C3C8D0;
  color: #ffffff;
}

.resolved-agent-badge {
  color: rgba(25, 25, 25, 0.6);
  background: rgba(243, 243, 243, 1);
}

.other-case-room-wrapper .chat-list-wrapper .dialog-message-wrapper, .solved-case-room-wrapper .chat-list-wrapper .dialog-message-wrapper {
  // padding: 0 0 3px 0;
  padding: 0;
}

.all-case-list.need-reply-show > div > div:nth-last-child(1) {
  margin-bottom: 55px;
}

.need-reply-unread-panel-wrapper{
  width: 100%;
  position: relative;
  padding: 7px 52px;
  position: absolute;
  bottom: 0;
  text-align: center;

  .need-reply-unread-panel-content {
    border-radius: 12px;
    background: pink;
    height: 29px;
    font-size: 14px;
    letter-spacing: -0.4px;
    color: #ffffff;
    overflow:hidden;
    box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.3);
    display: inline-block;

    .need-reply-panel {
      // width: 50%;
      padding: 0 19px;
      display: inline-block;
      text-align: center;
      background: #FF3F57;
      height: 29px;
      line-height: 29px;
      display: inline-block;
      border-radius: 12px;
    }

    .unread-panel {
      width: 50%;
      display: inline-block;
      text-align: center;
      background: #ff7d00;
      height: 29px;
      line-height: 29px;

      .need-reply-unread-panel-counter {
        color: #ff7d00;
      }
    }

    .need-reply-unread-panel-counter {
      border-radius: 12px;
      background: #ffffff;
      letter-spacing: -0.4px;
      font-size: 12px;
      color: #FF3F57;
      min-width: 18px;
      height: 18px;
      text-align: center;
      display: inline-block;
      vertical-align: top;
      line-height: 18px;
      margin-left: 4px;
      position: relative;
      top: 5px;
      padding: 0 5px 0 4px;
    }
  }
}
