.assignment-settings {
    .button-save {
        margin-top: 28px;
    }

    .assignment-settings-header {
        font-size: 20px;
        letter-spacing: -0.6px;
        color: #191919;
        padding-bottom: 11px;
        border-bottom: solid 1px #dcdcdc;
    }

    .assignment-settings-form {
        padding-top: 24px;
        position: relative;

        span {
            font-size: 14px;
            letter-spacing: -0.2px;
            color: rgba(25, 25, 25, 0.6);
            display: block;  
        }

        .change-rule-gap {
            margin-bottom: 24px;
        }
    }
}