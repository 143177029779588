.chat-room-case-history-modal-main-chat-wrapper {
    background: #f3f3f3;
    width: 100%;
    height: 100%;
    transition: ease-in-out .2s;
    display: inline-block;
    /* border-right: solid 1px #dcdcdc; */

    .message-action-wrapper {
        display: none !important;
    }
}

.chat-room-case-history-modal-main-chat-wrapper.active-chat-room-case-history-modal-detail {
    // width: calc(100% - 300px);
    width: calc(100% - 340px);
    vertical-align: top;
}

.chat-room-case-history-modal-main-chat-container {
    /* height: calc(100vh - 190px);
    max-height: calc(100vh - 190px); */
    height: calc(100vh - 56px);
    max-height: calc(100vh - 56px);
    width: 100%;
    position: relative;
}

.chat-room-case-history-modal-main-chat-container .chat-room-case-history-modal-main-chat-content {
    position: absolute;
    max-height: calc(100vh - 56px);
    overflow: auto;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 0 15px 16px 15px;
}

.chat-room-case-history-modal-go-to-container {
    height: 134px;
    width: 100%;
    position: relative;
    background: #FFF4B1;
    color: #ff7d00;
    letter-spacing: -0.3px;
    font-size: 16px;
    text-align: center;
    padding: 10px 12px;
}

.chat-room-case-history-modal-go-to-container p {
    margin: 0;
    line-height: 1.5;
}

.chat-room-case-history-modal-go-to-container b {
    letter-spacing: -0.5px;
} 

.chat-room-case-history-modal-go-to-container button {
    width: 100%;
    letter-spacing: -0.5px;
    font-size: 16px;
    padding: 11px 0 12px;
    margin-top: 16px;
}

.chat-room-case-history-modal-go-to-container svg {
    font-size: 20px;
    position: relative;
    top: -1px;
    margin-right: 4px;
}

.chat-room-case-history-modal-main-chat-wrapper .loading-message-wrapper {
    text-align: center;
    padding-top: 16px;
}