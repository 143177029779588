.country-picker-wrapper {
    width: 300px;
    display: flex;
    border: 1px solid rgba(25, 25, 25, 0.1);
    border-radius: 8px;

    &.country-picker-wrapper-disabled {
        background: rgba(25, 25, 25, 0.1) !important;
    }

    .country-picker-label {
        // border: 1px solid rgba(25, 25, 25, 0.1);
        // border-left: none;
        // border-radius: 0 8px 8px 0;
        padding: 12px 16px 12px 0;
        font-size: 16px;
        width: 200px;

        p {
            margin: 0;
        }

        .placeholder {
            color: rgba(25, 25, 25, 0.4);
        }
    }

    .react-select-country-picker__placeholder {
        width: 35px;
        height: 24px;
        border: solid 1px rgba(25, 25, 25, 0.1);
        border-radius: 2px;
        text-align: center;

        svg {
            width: 18px;
            height: 18px;
            vertical-align: -2.5px;
        }
    }
    
    .react-select-country-picker__input {
        // padding: 0 16px;

        input {
            margin: 0;
            height: unset;
            // line-height: unset;
            padding: 0;
        }
    }

    // .react-select-country-picker__control--is-focused {
    //     ~ .country-picker-label {
    //         border: 1px solid #ff7e00;
    //     }
    // }

    .icon-image-select {
        width: 32px;
        height: 24px;
        margin-right: 4px;
        vertical-align: top;
    }

    input {
        position: relative;
        top: 4px;
    }
}

// .country-picker-wrapper .react-select-country-picker__control--is-focused {
//     border: 1px solid #ff7e00;
// }