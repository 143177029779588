.advanced-date-selection {
    margin-bottom: 24px;

    &.disabled-advanced-date-selection {
        button {
            background: rgba(25, 25, 25, 0.1) !important;
            color: rgba(25, 25, 25, 0.4) !important;
        }
    }

    button {
        background: #ffffff !important;
        border: solid 1px #dcdcdc !important;
        outline: none !important;
        box-shadow: none !important;
        color: #191919 !important;
        // padding: 11px 18px 10px 18px;
        padding: 12px 24px;
        border-radius: 8px;
        position: relative;
        font-size: 14px;
        letter-spacing: -0.2px;
        // width: 350px;
        text-align: left;

        .icon-calendar {
            font-size: 20px;
            position: relative;
            top: -2px;
            margin-right: 4px;
        }

        .icon-caret {
            font-size: 20px;
            margin-left: 8px;
        }
    }

    .dropdown-menu {
        padding: 0;
        border-radius: 8px;
        box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
        border: none !important;

        button {
            border: none !important;
            border-bottom: solid 1px #dcdcdc !important;
            border-radius: 0;
            padding: 7px 18px 7px 36px;
            position: relative;
            
            &:nth-child(1) {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }

            &:nth-last-child(1) {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                border-bottom: 0 !important;
            }

            &:hover {
                background: rgba(255, 242, 229, 1) !important;
            }

            svg {
                position: absolute;
                left: 16px;
                top: 50%;
                transform: translate(0, -50%);
                font-size: 16px;
                color: #ff7d00;
            }
        }
    }
}

.modal-max-range-filter {
    .modal-content {
        width: 350px;
    }

    .modal-body {
        p {
            text-align: center;
        }

        button {
            width: 100%;
        }
    }
}