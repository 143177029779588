.home-welcome-message {
  margin: 0;
  letter-spacing: -0.6px;
  font-size: 24px;
  color: #191919;
}

.home-info-wrapper {
  border-top: solid 1px rgba(25, 25, 25, 0.1);
  margin-top: 24px;
  padding-top: 28px;

  .home-info-content {
    // min-width: 300px;
    width: 25%;
    display: inline-block;
    // max-width: 50%;
    padding-right: 25px;
    vertical-align: top;

    .home-info-image {
      padding: 6px;
      border-radius: 8px;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
      display: inline-block;
      vertical-align: top;
      position: relative;
      top: 2px;
    }

    .home-info-detail {
      width: calc(100% - 48px);
      display: inline-block;
      padding-left: 8px;
      position: relative;

      .home-info-detail-title {
        font-size: 10px;
        color: rgba(25, 25, 25, 0.6);
        margin: 0;
        letter-spacing: 1.5px;
        line-height: 15px;
        white-space: nowrap;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .home-info-detail-value {
        letter-spacing: -0.5px;
        font-size: 16px;
        margin: 0;
        line-height: 18px;
        white-space: nowrap;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 24px;
        position: relative;
        top: -2px;
      }
    }

    button {
      height: 22px;
      font-size: 12px;
      letter-spacing: -0.4px;
      border-radius: 4px;
      padding: 0 10px 0 8px !important;
      line-height: 19px;
      margin-top: 4px;
    }
  }
}

.usage-info-wrapper {
  border-top: solid 1px rgba(25, 25, 25, 0.1);
  margin-top: 12px;
  padding-top: 24px;
  display: flex;

  .home-usage-wrapper {
    width: 25%;

    .usage-icon {
      width: 32px;
      height: 32px;
      margin-bottom: 8px;
    }

    .usage-title {
      font-size: 14px;
      margin: 0;

      span {
        font-family: PTRootUI;
        color: rgba(25, 25, 25, 0.6);
      }
    }

    .usage-value {
      font-size: 40px;
      margin: 0;
    }

    .usage-per {
      font-size: 16px;
      color: rgba(25, 25, 25, 0.4);
      margin: 0;
    }
  }
}

.usage-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .usage-title {
    margin: 0;
    letter-spacing: -0.6px;
    font-size: 24px;
    color: #191919;
  }
}
