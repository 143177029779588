.add-contact-main-wrapper {
  .integration-list-loading-wrapper {
    height: 80vh;

    .lds-ring {
      width: 50px;
      height: 50px;
      position: relative;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      right: unset;

      div {
        border: 5px solid #fff;
        border-color: #ff7d00 transparent transparent transparent;
        width: 50px;
        height: 50px;
        margin: 0;
      }
    }
  }

  .add-contact-header {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid rgba(25, 25, 25, 0.1);

    .grey-font {
      font-size: 16px;
      letter-spacing: -0.6px;
    }
  }

  .add-contact-search {
    width: 300px;
    border-radius: 8px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    margin-bottom: 24px;

    &.focus-search {
      border: solid 1px #ff7e00;
      
      svg {
        color: #ff7e00;
      }
    }

    span {
      position: relative;
      top: -2px;
    }

    input {
      border: none;
      background: transparent;
      padding: 8px 4px 8px 16px;
      width: 90%;
    }
  }
}

.table-user-contact-list {
  table-layout: fixed;
}

.table-user-contact-list thead {
  tr {
    background: transparent;

    td {
      padding: 8px;
      // width: calc((100% - 100px) / 5);
      width: 20%;
      font-size: 14px;
      word-wrap: break-word;
      white-space: normal;

      &:nth-child(2) {
        width: 15%;
      }

      &:nth-child(3) {
        width: 17%;
      }
  
      &:nth-child(5) {
        width: 15%;
      }

      &:last-child {
        width: 90px;

        .custom-select-option-value-wrapper {
          height: 30px;
          padding: 0 4px 0 15px !important;

          .custom-select-option-value {
            padding: 0;
          }

          svg {
            right: 10px;
          }
        }
      }

    }
  }
}

.table-user-contact-list {
  tbody {
    tr {
      cursor: pointer;

      &:hover {
        background-color: #FFF2E5;

        td {
          color: #FF7E00;
        }
      }

      td {
        background: transparent;
        position: relative;
        word-wrap: break-word;
        vertical-align: top;

        .unavailable {
          color: rgba(25, 25, 25, 0.4);
        }

        .link-user {
          color: inherit;
          text-decoration: inherit;
        }

        &:nth-child(1) {
          p {
            margin-bottom: 4px;
          }

          .col_name_alias {
            font-size: 12px;
          }
        }

        &:nth-child(4) {
          &.col-origin {
            // white-space: nowrap;
            
            p {
              margin: 0;
              display: inline-block;
              position: relative;
              padding: 0 0 0 24px;
            }

            img {
              position: absolute;
              left: 0;
              top: 0;
              margin-right: 8px;  
              width: 20px;
            }
          }
        }
      }
    }
  }
}

.table-user-contact-list tbody tr {
  &:nth-child(even) {
    background: rgba(25, 25, 25, 0.05);
  }
}

// .table-user-contact-list button svg {
  // position: relative !important;
  // right: unset !important;
  // top: -2px !important;
  // margin-right: 5px;
// }

.table-user-contact-list .col-action-button-wrapper {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translate(0, -50%);
  /* display: none; */
}

.table-user-contact-list td {
  max-width: unset !important;
}

.add-contact-modal {
  .modal-content {
      width: 740px;
  }

  .modal-body {
      padding: 32px;

      .add-contact-wrapper {
          position: relative;

          .add-contact-title {
              font-size: 20px;
              letter-spacing: -0.6px;
              color: #191919;
              line-height: 1;
              margin: 6px 0 0;
          }
  
          .add-contact-description {
              font-size: 16px;
              letter-spacing: -0.3px;
              color: rgba(25, 25, 25, 0.8);
              line-height: 1;
              margin: 6px 0 0;
          }

          svg {
              position: absolute;
              right: 0;
              top: 0;
              font-size: 24px;
              cursor: pointer;

              &:hover {
                  color: #ff7d00;
              }
          }
      }

      .add-contact-option-container {
          width: 100%;
          margin-top: 20px;
          text-align: center;
          display: flex;
          
          .add-contact-new-user {
            width: 50%;
            border-right: solid 1px rgba(25, 25, 25, 0.1);
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 24px 24px 32px;

            img {
              margin-bottom: 12px;
            }

            p {
              margin-bottom: 4px;
            }

            .add-description {
              margin-bottom: 12px;
              width: 80%;
              text-align: center;
              font-size: 14px;
            }

            button {
              font-family: 'PTRootUI';
              font-size: 12px;

              img {
                margin: 0;
                margin-right: 4px;
                position: relative;
                top: -1px;
              }

              &.add-new-contact-btn {
                width: 125px;
                height: 22px;
                border-radius: 4px;
                line-height: 13px;
              }
            }
          }

          .add-contact-import-contact {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 24px 12px 32px;

            img {
              margin-bottom: 12px;
            }

            p {
              margin-bottom: 4px;
            }

            .add-description {
              margin-bottom: 12px;
              text-align: center;
              font-size: 14px;
              width: 80%;
            }

            .button-import-container {
              display: flex;
            }

            button {
              font-family: 'PTRootUI';
              font-size: 12px;

              img {
                margin: 0;
                margin-right: 4px;
              }

              &.upload-csv-btn {
                width: 97px;
                height: 22px;
                border-radius: 4px;
                margin-right: 5px;
                line-height: 12px;

                img {
                  position: relative;
                  top: -1px;
                }
              }

              &.no-border {
                border: none !important;
                margin-right: 12px;
                line-height: 12px;
                position: relative;
                top: -3px;

                &:hover {
                  color: #e87200 !important;
                  background: transparent;
                  
                  svg {
                    color:#e87200;
                  }
                }

                svg {
                  position: relative;
                  font-size: 12px;
                  top: -1px;
                  margin-right: 4px;
                  transition: ease-in-out 0.3s;
                }
              }
            }
          }
      }
  }
}

.importing-contact-modal {
  .modal-content {
      width: 450px;
  }

  .modal-body {
      padding: 32px;

      .importing-contact-wrapper {
        position: relative;

        button {
          width: calc(50% - 6px);
          margin-right: 12px;

          &:nth-last-child(1) {
            margin: 0;
          }
        }

        .integration-list-loading-wrapper {
          position: relative;
          width: 100%;
          height: 50px;

          .lds-ring {
            width: 50px;
            height: 50px;
            position: relative;
            left: 50%;
            top: 20px;
            transform: translate(-50%, -50%);
            // right: unset;
      
            div {
              border: 5px solid #fff;
              border-color: #ff7d00 transparent transparent transparent;
              width: 50px;
              height: 50px;
              margin: 0;
            }
          }
        }

        .importing-text {
          margin: 12px 0;
          font-size: 14px;
          text-align: center;
        };

        .retry-button {
          width: 100%;
          height: 40px;

          svg {
            margin-right: 4px;
          }
        }

        .warning-svg {
          display: flex;
          justify-content: center;
          svg {
            color: #FF7E00;
            font-size: 50px;
          }
        }
      }
  }
}