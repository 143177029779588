.modal-select-account-google-profile {
    .modal-content {
        width: 650px;
        // height: 80vh;
        border-radius: 8px;

        .modal-body {
            padding: 0;

            .table-modal-select-location-wrapper {
                width: 100%;
                display: block;
                overflow: auto;
                max-height: calc(78vh - 120px);
            }

            .modal-header {
                padding: 18px 24px;
                position: relative;
                letter-spacing: -0.6px;
                color: rgba(25, 25, 25, 1);
                font-size: 20px;
                line-height: 1;
                margin: 0;
                border-bottom: 0;

                svg {
                    color: var(--main-dark-orange);
                    position: absolute;
                    top: 18px;
                    right: 24px;
                    cursor: pointer;
                    font-size: 22px;

                    &:hover {
                        color: var(--main-darker-orange);
                    }
                }
            }

            table {
                width: 100%;
                border-collapse: collapse;

                thead {
                    tr {
                        border-top: solid 1px rgba(25, 25, 25, 0.1);
                        border-bottom: solid 1px rgba(25, 25, 25, 0.1);
                        
                        td {
                            padding: 8px 24px;
                            font-size: 14px;
                            letter-spacing: -0.2px;
                        }
                    }
                }

                tbody {
                    tr {
                        &:nth-child(even) {
                            background: rgba(25, 25, 25, 0.05);
                        }
                        
                        td {
                            padding: 16px 24px;
                            font-size: 14px;
                            letter-spacing: -0.2px;

                            button {
                                width: 128px !important;
                                padding: 0 !important;
                            }

                            &:nth-child(3) {
                                width: 160px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal-select-location-google-profile {
    .modal-content {
        width: 90vw;
        max-height: 80vh;
        border-radius: 8px;

        .modal-body {
            padding: 0;
            width: 100%;

            .table-modal-select-location-wrapper {
                width: 100%;
                display: block;
                overflow: auto;
                max-height: calc(78vh - 120px);
            }

            .modal-header {
                padding: 18px 24px;
                position: relative;
                letter-spacing: -0.6px;
                color: rgba(25, 25, 25, 1);
                border-bottom: 0;
                margin: 0;
                height: 121px;
                padding-top: 56px;

                .back-to-select-account {
                    color: var(--main-dark-orange);
                    font-size: 16px;
                    letter-spacing: -0.5px;
                    position: absolute;
                    top: 18px;
                    left: 22px;
                    cursor: pointer;


                    &:hover {
                        color: var(--main-darker-orange);
                    }

                    svg {
                        position: relative;
                        top: -1px;
                        margin-right: 4px;
                        font-size: 18px;
                    }
                }

                .modal-header-title {
                    font-size: 24px;
                    line-height: 1;
                    margin: 0;
                    letter-spacing: -0.6px;
                    border-bottom: 0;
                    position: relative;
                    top: 12px;
                }

                .close-modal-select-location {
                    color: var(--main-dark-orange);
                    position: absolute;
                    top: 18px;
                    right: 24px;
                    cursor: pointer;
                    font-size: 22px;

                    &:hover {
                        color: var(--main-darker-orange);
                    }
                }
            }

            table {
                width: 100%;
                border-collapse: collapse;

                thead {
                    tr {
                        border-top: solid 1px rgba(25, 25, 25, 0.1);
                        border-bottom: solid 1px rgba(25, 25, 25, 0.1);
                        
                        td {
                            padding: 8px 24px;
                            font-size: 14px;
                            letter-spacing: -0.2px;
                        }
                    }
                }

                tbody {
                    tr {
                        &:nth-child(even) {
                            background: rgba(25, 25, 25, 0.05);
                        }
                        
                        td {
                            padding: 16px 24px;
                            font-size: 14px;
                            letter-spacing: -0.2px;
                            vertical-align: top;

                            button {
                                width: 156px !important;
                                padding: 0 !important;
                            }

                            &:nth-child(4) {
                                width: 160px;
                            }

                            a {
                                color: #191919;
                                cursor: pointer;

                                &:hover {
                                    text-decoration: underline !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.integration-google-profile-add-account {
  padding: 32px 30px;
  text-align: left;

  .google-button {
      img {
          width: 20px !important;
      }
  }

  .no-fill-black-button {
    min-width: 224px;
  }

  .comp-back-button {
      margin: 0 0 16px 0;
  }

  .integration-google-profile-add-account-box {
      background: #ffffff;
      border-radius: 8px;
      padding: 32px;
      border: solid 1px rgba(25, 25, 25, 0.1);

      .top-component {
          padding-top: 24px;

          .top-desc {
            font-size: 14px;
            letter-spacing: -0.2px;
            color: rgba(25, 25, 25, 0.6);
            width: 600px;
            max-width: 100%;

            a {
                color: var(--main-dark-orange);

                &:hover {
                    text-decoration: underline !important;
                }
            }
          }

          .alert-component {
              border: 1px solid rgba(4, 75, 132, 1);
              background: #DCEBFF;
              color: rgba(4, 75, 132, 1);

              a {
                  color: rgba(4, 75, 132, 1);   
                  
                  &:hover {
                      text-decoration: underline !important;
                  }
              }
          }
      }

      .facebook-component {
          padding-top: 6px;

          p {
              margin: 0;
          }

          ul {
              padding-left: 18px;
          }

          a {
              color: #ff7d00;

              &:hover {
                  text-decoration: underline !important;
              }
          }
      }

      .integration-google-profile-add-account-box-top {
          padding-bottom: 15px;
          border-bottom: solid 1px #dcdcdc;
          position: relative;
          font-size: 20px;
          letter-spacing: -0.6px;

          button {
              position: absolute;
              top: -6px;
              right: 0;
              // padding: 7px 24px;
              font-size: 16px;
              letter-spacing: -0.6px;
              // width: 195px;
              // height: 40px;

              // .lds-ring {
              //     top: unset;
              //     right: unset;
              //     position: relative;
              //     top: 3px;

              //     div {
              //         margin: 0;
              //         border-color: #ffffff transparent transparent transparent;
              //     }
              // }
          }
      }

      .integration-google-profile-add-account-box-form {
        //   width: 620px;
          max-width: 100%;
          border-top: solid 1px #dcdcdc;
          margin-top: 24px;

          .selected-label {
            font-size: 16px;
            letter-spacing: -0.5px;
            margin: 12px 0 0;
            cursor: pointer;
            display: inline-block;

            svg {
                position: relative;
                margin-right: 4px;
                top: -1px;
                color: #474747!important;
            }

            .selected-label-edit {
                color: var(--main-dark-orange) !important;
                cursor: pointer;
                position: relative;
                top: -1px;
                margin-left: 4px;
                font-size: 14px;
                text-decoration: none !important;
            }

            &:hover {
                color: var(--main-dark-orange);

                b {
                    text-decoration: underline;
                }
            }
          }

          .selected-title {
            font-size: 16px;
            letter-spacing: -0.3px;
            padding: 0 0 0 20px;
            margin: 0;
            max-width: 620px;
          }

          .selected-address {
            font-size: 14px;
            letter-spacing: -0.2px;
            color: rgba(25, 25, 25, 0.6);
            padding: 0 0 0 20px;
            margin: 4px 0 0 0;
          }

          .select-business-profile {
            color: var(--main-dark-orange);
            cursor: pointer;
            font-size: 14px;
            margin: 0;
            letter-spacing: -0.4px;

            &:hover {
                text-decoration: underline;
            }

            svg {
                position: relative;
                top: -2px;
                margin-right: 2px;
            }
          }

          .input-wrap {
            position: relative;
            width: 340px;

            span {
                position: absolute;
                top: -29px;
                right: 0;
                font-size: 14px;
                letter-spacing: -0.4px;
            }
          }

          .input-channel-name {
            width: 340px !important;
          }

          .custom-select-option-value-wrapper {
            width: 340px !important;
          }
          
          label {
              padding: 24px 0 0;
              font-size: 14px;
              letter-spacing: -0.4px;
              display: block;
          }

          input[type="text"] {
              padding: 12px 16px;
              border-radius: 6px;
              border: solid 1px #dcdcdc;
              width: 100%;
              font-size: 16px;
              letter-spacing: -0.3px;
              display: block;
              margin-bottom: 8px;
          }

          .integration-info-form {
              font-size: 14px;
              color: rgba(25, 25, 25, 0.8);
              letter-spacing: -0.2px;
          }

          .custom-select-google-profile-topic {
              .custom-select-option-value-wrapper {
                  padding: 12px 16px !important;
                  margin-bottom: 8px;
              }

              .dropdown {
                button {
                    margin: 0 !important;
                }
              }

              .dropdown-menu {
                width: 340px;


              }
          }
      }
  }
}

.modal-continue-with-google {
    .modal-content {
        width: 550px;
    }

    button {
        width: calc(50% - 2px) !important;
        margin-right: 4px !important;

        &:nth-last-child(1) {
            margin-right: 0 !important;
        }
    }
}