@charset "UTF-8";
.modal-new-conversation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  background: rgba(25, 25, 25, 0.8);
  visibility: hidden;
  opacity: 0;
  right: 0;
  transition: visibility .3s, opacity 0.5s ease-in-out;
  z-index: 9999; }
  .modal-new-conversation .this-top-22 {
    position: relative;
    top: -22px; }
  .modal-new-conversation .maximum-upload-size {
    font-size: 12px;
    letter-spacing: -0.4px;
    color: #191919;
    margin: 0; }
    .modal-new-conversation .maximum-upload-size svg {
      margin-right: 4px;
      position: relative;
      top: -1px; }
  .modal-new-conversation.active-modal-new-conversation {
    /* right: 0; */
    opacity: 1;
    visibility: visible;
    transition: visibility 0s, opacity 0.5s ease-in-out; }
  .modal-new-conversation .modal-body {
    position: absolute;
    top: 8px;
    right: -100%;
    left: unset;
    transform: unset;
    height: calc(100% - 8px);
    width: 600px;
    display: inline-block;
    border-radius: 8px 0 0 0;
    background: #ffffff;
    padding: 0;
    max-height: calc(100% - 8px);
    transition: ease-in-out .4s; }
    .modal-new-conversation .modal-body.action-modal-body-new-conversation {
      right: 0; }
    .modal-new-conversation .modal-body .custom-select-topic button {
      margin-top: 0; }
    .modal-new-conversation .modal-body .close-modal-start-conversation {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      background: #ffffff;
      color: #757575;
      position: absolute;
      left: -65px;
      top: 0;
      text-align: center;
      cursor: pointer; }
      .modal-new-conversation .modal-body .close-modal-start-conversation:hover {
        background: #f9f9f9; }
      .modal-new-conversation .modal-body .close-modal-start-conversation svg {
        position: absolute;
        font-size: 25px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    .modal-new-conversation .modal-body .new-conversation-wrapper {
      height: calc(100% - 72px);
      max-height: calc(100% - 72px);
      overflow: hidden; }
      .modal-new-conversation .modal-body .new-conversation-wrapper > div > div {
        overflow-x: hidden !important; }
      .modal-new-conversation .modal-body .new-conversation-wrapper .comp-back-button {
        margin: 0 0 24px; }
      .modal-new-conversation .modal-body .new-conversation-wrapper label {
        display: inline-block !important; }
      .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container {
        padding: 32px; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container .new-conversation-title {
          color: #191919; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container .new-conversation-title b {
            font-size: 20px;
            letter-spacing: -0.6px; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container .new-conversation-title svg {
            font-size: 24px;
            margin: 0 4px 0 0;
            position: relative;
            top: -2px; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container .new-conversation-title p {
            margin: 2px 0 0;
            color: rgba(25, 25, 25, 0.6);
            font-size: 14px;
            letter-spacing: -0.2px; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container .channel-type {
          margin: 0;
          line-height: 1;
          font-size: 16px;
          letter-spacing: -0.3px; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container .channel-type img {
            margin-right: 4px;
            position: relative;
            top: -2px; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container .form-label {
          width: 100%;
          position: relative;
          font-size: 14px;
          letter-spacing: -0.6px;
          color: #191919; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container .form-label.form-label-phone {
            margin: 24px 0 0; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container .form-label p {
            margin: 0 8px 0 0;
            width: 142px;
            display: inline-block; }
            .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container .form-label p.pos-relative {
              width: calc(100% - 150px);
              margin: 0 !important; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container label span {
          letter-spacing: -0.2px;
          font-size: 14px;
          color: rgba(25, 25, 25, 0.6); }
        .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container label.button-upload-image {
          margin: 0 4px;
          color: #ff7d00;
          letter-spacing: -0.4px;
          font-size: 12px;
          border-radius: 4px;
          cursor: pointer;
          display: inline-block !important;
          padding: 2px 8px;
          background: rgba(255, 126, 0, 0.1);
          -webkit-transition: ease-in-out .1s;
          transition: ease-in-out .1s; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container label.button-upload-image:hover {
            color: #ffffff;
            background: #ff7d00; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container label.button-upload-image svg {
            position: relative;
            top: -1px;
            margin-right: 4px; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container label.button-upload-image-dis {
          margin: 0 4px;
          color: rgba(25, 25, 25, 0.4);
          letter-spacing: -0.4px;
          font-size: 12px;
          border-radius: 4px;
          cursor: default;
          display: inline-block !important;
          padding: 2px 8px;
          background: rgba(25, 25, 25, 0.1);
          -webkit-transition: ease-in-out .1s;
          transition: ease-in-out .1s; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container label.button-upload-image-dis svg {
            position: relative;
            top: -1px;
            margin-right: 4px; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container textarea {
          width: 100%;
          height: 96px; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container .image-and-caption-wrapper {
          width: 100%; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container input[type="file"] {
          display: none; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form {
          color: #191919;
          font-size: 16px;
          letter-spacing: -0.3px;
          margin-top: 8px;
          overflow: hidden; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form .dropdown-menu {
            border: 1px solid rgba(0, 0, 0, 0.15);
            transform: translate3d(0px, 50px, 0px) !important; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form input[type="text"] {
            width: 100%;
            padding: 12px 16px;
            border: solid 1px rgba(25, 25, 25, 0.1);
            border-radius: 8px;
            box-shadow: none;
            outline: none; }
            .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form input[type="text"]:focus {
              border-color: #ff7d00; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form .phone-wrapper {
            width: calc(100% - 150px);
            display: inline-block;
            vertical-align: top; }
            .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form .phone-wrapper input {
              height: 48px; }
            .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form .phone-wrapper p {
              color: #ff3f57;
              font-size: 12px;
              letter-spacing: -0.4px;
              margin: 4px 0 0;
              width: 100%;
              position: relative;
              padding: 0 0 0 20px; }
              .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form .phone-wrapper p svg {
                margin-right: 4px;
                position: absolute;
                left: 0;
                top: 1px;
                font-size: 16px; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form.phone-number-disabled input[type="text"] {
            background: rgba(25, 25, 25, 0.1);
            border: none;
            cursor: not-allowed; }
      .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper label {
        margin: 0 0 8px; }
      .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .add-by-phone-number {
        display: inline-block;
        font-size: 12px;
        letter-spacing: .2px;
        cursor: pointer;
        padding: 0 10px;
        margin: 0 0 0 4px;
        border-radius: 4px; }
      .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .conversation-data-contact-wrapper {
        border-radius: 8px;
        border: solid 1px rgba(25, 25, 25, 0.1);
        padding: 11px 16px;
        font-size: 16px;
        color: #191919; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .conversation-data-contact-wrapper p {
          white-space: nowrap;
          overflow: hidden;
          letter-spacing: -0.3px;
          text-overflow: ellipsis;
          margin: 0;
          max-width: 100%; }
      .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .search-contact-wrapper {
        position: relative; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .search-contact-wrapper input {
          width: 100%;
          border: solid 1px #dcdcdc;
          padding: 12px 50px 12px 16px;
          border-radius: 8px;
          margin: 0;
          font-size: 16px;
          letter-spacing: -0.3px; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .search-contact-wrapper input:focus {
            border: solid 1px #ff7d00; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .search-contact-wrapper svg {
          position: absolute;
          top: 50%;
          right: 16px;
          transform: translate(0, -50%);
          font-size: 20px; }
      .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .selected-contact {
        position: relative;
        font-size: 14px;
        letter-spacing: -.4px;
        color: rgba(25, 25, 25, 0.6);
        border-radius: 4px;
        padding: 4px 24px 3px 8px;
        background: rgba(25, 25, 25, 0.05);
        display: inline-block; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .selected-contact svg {
          position: relative;
          top: -2px;
          margin-right: 4px;
          font-size: 16px; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .selected-contact .remove-contact {
          position: absolute;
          border-radius: 50%;
          background: #707070;
          right: 7px;
          top: 50%;
          transform: translate(0, -50%);
          width: 13px;
          height: 13px;
          cursor: pointer;
          text-align: center; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .selected-contact .remove-contact svg {
            font-size: 9px;
            position: absolute;
            top: 2px;
            left: 2px;
            color: #ffffff;
            margin: 0; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .selected-contact .remove-contact:hover {
            background: rgba(112, 112, 112, 0.8); }
      .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .add-phone-number-info {
        font-size: 12px;
        letter-spacing: -0.4px;
        color: #191919;
        margin: 8px 0 0; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .add-phone-number-info svg {
          position: relative;
          margin-right: 4px;
          top: -1px;
          font-size: 16px; }
      .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list {
        position: absolute;
        left: 0;
        top: 52px;
        width: 100%;
        border-radius: 8px;
        max-height: 193px;
        overflow-y: auto;
        background: #ffffff;
        border: solid 1px #dcdcdc;
        z-index: 2;
        overflow-x: hidden !important; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact {
          font-size: 14px;
          letter-spacing: -.2px;
          border-bottom: solid 1px #dcdcdc;
          border-radius: 0;
          padding: 8px 20px;
          cursor: pointer;
          position: relative; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact.selected-number-active {
            padding: 8px 20px 8px 36px;
            background: #FFF2E5; }
            .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact.selected-number-active::before {
              color: #ff7e00;
              content: '✔';
              position: absolute;
              left: 15px;
              top: 50%;
              transform: translate(0, -50%); }
          .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact:nth-child(1) {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact span {
            font-size: 12px;
            display: block;
            color: rgba(25, 25, 25, 0.6); }
          .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact:hover {
            background: #FFF2E5; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact:nth-last-child(1) {
            border: none;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact.no-contact-found {
            text-align: center;
            color: rgba(25, 25, 25, 0.6);
            background: none !important; }
    .modal-new-conversation .modal-body .send-message-button {
      width: 100%;
      padding: 12px 24px;
      background: #ffffff;
      font-size: 16px;
      letter-spacing: -0.5px;
      position: absolute;
      bottom: 0;
      left: 0;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18); }
      .modal-new-conversation .modal-body .send-message-button button {
        width: 100%;
        height: 48px; }
