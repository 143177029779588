.chat-room-message-image-in-wrapper {
    width: 100%;
    padding: 0 24px;
    text-align: left;
    position: relative;
    margin-top: 16px;
    font-size: 0;
  }
  .chat-room-message-image-in-wrapper .group-sender-avatar-wrapper {
    width: 34px;
    height: 34px;
    display: inline-block;
    border-radius: 50%;
    vertical-align: top;
    margin-right: 5px;
    font-size: 16px;
    line-height: 34px;
    text-align: center;
    color: #ffffff;
    position: relative;
  }
  .chat-room-message-image-in-wrapper .group-sender-avatar-wrapper img {
    width: 34px;
    height: 34px;
    display: inline-block;
    border-radius: 50%;
  }
  .chat-room-message-image-in-wrapper .group-sender-avatar-wrapper .avatar-icon-badge {
    width: 20px;
    bottom: -4px;
    right: -4px;
    height: 20px;
    position: absolute;
  }
  .chat-room-message-image-in-wrapper .message-in-bubble-image {
    border-radius: 2px 16px 16px;
    max-width: 300px;
    display: inline-block;
    position: relative;
    font-size: 0;
    // overflow: hidden;
    background: #ffffff;
    border: 1px solid rgba(25, 25, 25, 0.1);
  }
  .chat-room-message-image-in-wrapper .message-in-bubble-image .bubble-image-wrapper {
    // position: relative;
    min-width: 150px;
    // min-height: 150px;
    padding: 10px;

    &.bubble-wrapper-with-caption {
      padding: 10px 10px 0;
    }

    img {
      border-radius: 0px 16px 16px;
    }

    // &.bubble-wrapper-without-caption {
    //   img {
    //     border-radius: 0px 16px 16px;
    //   }
    // }

    // &.with-reply-caption {
    //   img {
    //     border-radius: 0;
    //   }
    // }

    // &.with-reply-no-caption {
    //   img {
    //     border-radius: 0 0 16px 16px;
    //   }
    // }
  }
  .chat-room-message-image-in-wrapper .message-in-bubble-image .bubble-image-wrapper img {
    vertical-align: top;
    width: 100%;
    max-width: 100%;
  }
  .chat-room-message-image-in-wrapper .message-in-bubble-image .bubble-image-wrapper .icon-status-wrapper {
    border-radius: 50%;
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-block;
    background: rgba(25, 25, 25, 0.4);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  .chat-room-message-image-in-wrapper .message-in-bubble-image .bubble-image-wrapper .icon-status-wrapper svg {
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
  }
  .chat-room-message-image-in-wrapper .message-in-bubble-image .bubble-image-wrapper .icon-status-wrapper .onprocess-wrapper {
    height: 40px;
    width: 40px;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .chat-room-message-image-in-wrapper .message-in-bubble-image .main-image-message {
    width: 100%;
    max-width: 100%;
  }
  .chat-room-message-image-in-wrapper .message-in-bubble-image .thumbnail-image-message {
    width: 300px;
    min-width: 300px;
  }
  .modal-image.modal-dialog {
    width: 100vw !important;
  }  
  .modal-image .modal-content {
    width: 100%;
    height: 100%;
    background: none;
  }
  .modal-image .modal-content .modal-body {
    width: 100%;
    height: 100%;
    background: rgba(25, 25, 25, 0.8);
  }
  .modal-image .modal-content .modal-body img.image-preview-val {
    max-width: calc(100vw - 130px);
    max-height: calc(100vh - 130px);
    background: #ffffff;
  }

  .modal-image .modal-content .modal-body .image-wrapper {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    figure {
      margin: 0;
      
      &.zoom {
        background-size: 180%;
        background-position: -100% -100%;
        background-repeat: no-repeat;
        position: relative;
        width: 100%;
        overflow: hidden;
        cursor: zoom-in;

        img{
          transition: opacity 0.5s;
          display: inline-block;
          // width: 100%;

          &:hover {
            opacity: 0;
          }
        }
      }   
    }
  }

  .modal-image .modal-content .modal-body .close-modal-image {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #ffffff;
    font-size: 40px;
    cursor: pointer;
    transition: ease-in-out 0.1s;
  }
  .modal-image .modal-content .modal-body .close-modal-image:hover {
    color: #c9c9c9;
  }
  .chat-room-message-image-in-wrapper .message-in-bubble-image .caption-text {
    font-size: 16px;
    letter-spacing: -.3px;
    // padding: 10px 55px 10px 10px;
    padding: 4px 10px 0 10px;
    margin: 0;
    word-break: break-word;
  }
  .chat-room-message-image-in-wrapper .message-in-bubble-image .reply-message {
    width: calc(100% - 20px);
    border-radius: 4px;
    border-left: solid 5px #ff7d00;
    background: #f3f3f3;
    color: #191919;
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 5px 7px 5px 7px;
    line-height: 1;
    z-index: 1;
  }
  .chat-room-message-image-in-wrapper .message-in-bubble-image .reply-message p {
    margin: 0;
    font-size: 12px;
    letter-spacing: -0.4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
  }
  .chat-room-message-image-in-wrapper .message-in-bubble-image .reply-message p:nth-child(2) {
    letter-spacing: -0.2px;
  }
  .chat-room-message-image-in-wrapper .message-in-bubble-image .reply-message.reply-file .reply-file-thumbnail {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    position: relative;
  }
  .chat-room-message-image-in-wrapper .message-in-bubble-image .reply-message.reply-file .reply-file-thumbnail img {
    width: 40px;
    height: 40px;
  }
  .chat-room-message-image-in-wrapper .message-in-bubble-image .reply-message.reply-file .reply-file-thumbnail svg {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 35px;
    transform: translate(-50%, -50%);
  }
  .chat-room-message-image-in-wrapper .message-in-bubble-image .reply-message.reply-file .reply-text-wrapper {
    width: calc(100% - 50px);
    display: inline-block;
    vertical-align: top;
  }
  .chat-room-message-image-in-wrapper .message-in-bubble-image .message-info {
    // position: absolute;
    position: relative;
    margin: 0;
    white-space: nowrap;
    border-radius: 10px;
    font-size: 10px;
    letter-spacing: -0.4px;
    color: rgba(25, 25, 25, 0.4);
    // bottom: 10px;
    // right: 10px;
    // z-index: 1;
    width: 100%;
    text-align: right;
    padding: 2px 10px 10px;

    .centered-dot {
      width: 2px;
      height: 2px;
      display: inline-block;
      vertical-align: top;
      background: rgba(25, 25, 25, 0.4);
      border-radius: 50%;
      position: relative;
      top: 9px;
    }
  }
  .chat-room-message-image-in-wrapper .message-in-bubble-image .group-sender-name-wrapper {
    color: #ff7d00;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    font-size: 14px;
  }
  .chat-room-message-image-in-wrapper .message-in-bubble-image .group-sender-name-wrapper .agent-span {
    color: #A3A3A3;
  }
  .chat-room-message-image-in-wrapper .message-in-bubble-image .message-info-dark {
    background: rgba(25, 25, 25, 0.6);
    padding: 2px 5px;
    color: #ffffff;
    bottom: 16px;
    right: 16px;
    position: absolute;
    width: unset;

    .centered-dot {
      background: #ffffff;
    }
  }
  .chat-room-message-image-in-wrapper .message-in-bubble-image.not-sent-message-bubble {
    // overflow: hidden;
  }
  .chat-room-message-image-in-wrapper .message-in-bubble-image.not-sent-message-bubble .dark-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: -5%;
    border-radius: 2px 16px 16px;
  }
  .chat-room-message-image-in-wrapper:hover .message-action-wrapper {
    display: inline-block;
  }
  .chat-room-message-image-in-wrapper .message-action-wrapper {
    right: -35px !important;
    left: unset !important;

    &.with-forward {
      right: -73px !important;
      
      .forward-button {
       display: inline-block !important;
      } 
    }
  }
  .chat-room-message-image-in-wrapper .message-action-wrapper .message-action-button-wrapper {
    cursor: pointer;
  }
  .chat-room-message-image-in-wrapper .CircularProgressbar .CircularProgressbar-trail {
    stroke: transparent;
  }
  .chat-room-message-image-in-wrapper .CircularProgressbar .CircularProgressbar-path {
    stroke: #ffffff;
  }
  .chat-room-message-image-in-wrapper .image-from-url {
    /* max-width: 234px; */
    /* max-height: 300px; */
  }

  //reply button
  .hide-reply-button .message-in-bubble-image .message-action-wrapper {
    right: -40px !important;

    &.with-forward {
      right: -40px !important;
    }
  }
  //reply button