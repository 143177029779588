.entry-google {
  position: relative;
  width: 100%;
}

.non-local-block {
  margin: 24px 0 0;
  
  label {
    position: relative;

    svg {
      position: relative;
      top: -2px;
      margin-right: 4px;
    }
  }

  .value-non-local {
    font-size: 20px;
    letter-spacing: -0.6px;
    line-height: 1;
    margin: 0 0 24px;
  }

  .region-block {
    .custom-regions {
      font-size: 14px;
      letter-spacing: -0.4px;
      color: rgba(25, 25, 25, .6);
      display: block;
      margin-bottom: 10px;
    }


    &.contact-block {
      margin-top: 14px;
    }

    .region-badge {
      font-size: 12px;
      letter-spacing: -0.4px;
      color: rgba(25, 25, 25, 0.6);
      margin: 0 8px 8px 0;
      background: rgba(25, 25, 25, 0.05);
      border-radius: 4px;
      padding: 2px 8px;
      display: inline-block;
    }
  }
}

.entry-google {
  .entry-google-top {
    padding: 0 292px 0px 0;
    border-bottom: solid 1px #dcdcdc;
    font-size: 20px;
    letter-spacing: -0.6px;
    font-weight: 700;
    margin: 0 0 24px 0;
    position: relative;

    &.local-disabled {
      padding: 0 130px 0 0;
    }

    &.entry-google-top-non-local {
      padding: 0 130px 0 0;
    }

    &.entry-google-bottom {
      padding: 0 120px 0px 0;

      .no-fill-button {
        right: 0 !important;
      }
    }

    p {
      font-size: 14px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.6);
    }

    .entry-google-top-button {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 16px;
        letter-spacing: -0.5px;
        padding: 7px 32px;
        margin-left: 8px;

        img {
            margin-right: 4px;
            position: relative;
            top: -2px;
        }
    }

    .no-fill-button {
      right: 174px;
    }

    .edit-non-local {
      right: 0;
    }
  }

  .entry-google-middle {
    width: 100%;
    display: block;
    position: relative;
    margin-bottom: 24px;

    .contact-us-source {
      margin-top: 12px;

      .contact-us-source-list {
        margin-right: 8px;
        border-radius: 4px;
        color: rgba(25, 25, 25, 0.6);
        background: rgba(25, 25, 25, 0.05);
        font-size: 12px;
        letter-spacing: -0.4px;
        display: inline-block;
        padding: 3px 8px;
      }
    }
    
    .text-value {
      font-size: 20px;
      letter-spacing: -0.6px;
      line-height: 1;

      .custom-region {
        margin: 0 0 8px 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        color: rgba(25, 25, 25, .6);
      }
    }

    .search-delete-wrapper {
      position: relative;
      width: 100%;
      min-height: 48px;

      .delete-location {
        position: absolute;
        right: 0;
        // top: 27px;
      }
    }

    label {
      display: block;
      font-size: 14px;
      letter-spacing: -0.4px;
      margin-bottom: 6px;

      &.location-list-label {
        margin-top: 24px;
      }

      svg {
        position: relative;
        top: -2px;
        margin-right: 4px;
      }
    }

    .entry-google-top-button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.modal-view-message {
  .modal-content {
    padding: 32px;
    width: 477px;
  }

  .modal-body {
    padding: 0;

    .header-modal {
      margin-bottom: 24px;

      b {
        font-size: 20px;
      }

      svg {
        margin-right: 4px;
        position: relative;
        top: -3px;
      }
    }

    .message-container {
      label {
        font-size: 14px;
      }

      input {
        display: flex;
        align-items: center;
        background: white;
        border-radius: 8px;
        border: 1px solid rgba(25, 25, 25, 0.1);
        height: 48px;
        width: 100%;
        color: #191919;
        font-size: 16px;
        letter-spacing: -0.3px;
        max-width: 100%;
        padding: 0 16px;
        margin-bottom: 24px;

        &:disabled {
          background: rgba(25, 25, 25, 0.05);
          cursor: not-allowed;
        }
      }

      textarea {
        background: white;
        border-radius: 8px;
        border: 1px solid rgba(25, 25, 25, 0.1);
        min-height: 48px;
        width: 100%;
        color: #191919;
        font-size: 16px;
        letter-spacing: -0.3px;
        max-width: 100%;
        padding: 12px 16px;
        line-height: 24px;
        resize: none;
        margin-bottom: 20px;

        &:disabled {
          background: rgba(25, 25, 25, 0.05);
          cursor: not-allowed;
        }
      }
    }
  }
}

.table-entry-list {
  table-layout: fixed;
  min-height: 115px;

  .td-item-per-page {
    width: 70px;
  }

  .custom-checkbox {
    display: inline-block;
  }

  thead {
    tr {
      background: transparent;

      td {
        padding: 8px;
        width: 20%;
        font-size: 14px;
        word-wrap: break-word;
        white-space: normal;
        position: relative;

        .question-circle-wrapper {
          margin-left: 5px;
          position: absolute;
          top: 25%;
        }

        &:first-child {
          width: 30px;
        }

        // &:nth-child(2) {
        //   width: 30%;
        // }

        // &:nth-child(3) {
        //   width: 30%;
        //   img {
        //     margin-left: 5px;
        //     vertical-align: -1.5px;
        //   }
        // }

        &:last-child {
          // width: 100%;
          // text-align: right;

          .custom-select-option-value-wrapper {
            width: 55px !important;
            height: 23px;
            padding: 2px 8px 2px 12px !important;
            border-radius: 4px !important;
            // visibility: hidden;

            p {
              font-size: 12px;
              font-weight: bold;
            }

            .custom-select-option-value {
              padding: 0;
            }

            svg {
              right: 3px;
              height: 15px;
              top: 11px;
            }
          }
        }
      }
    }
  }

  tbody {
    tr {
      // cursor: pointer;
      border-bottom: none;

      &:hover {
        background-color: unset;

        td {
          color: unset;
        }
      }

      td:not(.no-data-column) {
        padding: 8px;

        p {
          margin-bottom: 0;
        }
      }

      td {
        background: transparent;
        position: relative;
        word-wrap: break-word;
        vertical-align: top;
        white-space: nowrap;

        &.alias-wrap {
          position: relative;
          padding: 8px 30px 8px 8px;
          white-space: unset !important;

          svg {
            position: absolute;
            top: 10px;
            right: 8px;
            font-size: 16px;
            cursor: pointer;

            &:hover {
              color: var(--main-darker-orange);
            }
          }
        }

        &.status-wrap {
          .badge-status {
            font-size: 14px;
            letter-spacing: -0.4px;

            svg, img {
              position: relative;
              top: -2px;
              margin-right: 4px;
            }

            &.badge {

            }
          }

          button {
            font-size: 14px;
            letter-spacing: -0.5px;
            padding: 4px 17px;
            border-radius: 6px;
            color: #fff;
            background: #ff7d00;
            cursor: pointer;
            border: 0;
            box-shadow: 0;

            &:hover {
              background: var(--main-darker-orange);
            }
          }
        }

        &.test-url-wrap {
          color: #ff7d00;

          b {
            margin-right: 8px;
            cursor: pointer;
            
            img {
              position: relative;
              top: -2px;
              margin-right: 4px;
            }

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .unavailable {
          color: rgba(25, 25, 25, 0.4);
        }

        .link-user {
          color: inherit;
          text-decoration: inherit;
        }

        &:nth-child(2) {
          p {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }

        &.col-view {
          color: #ff7e00;
          cursor: pointer;
          font-weight: 700;
          text-align: right;

          a {
            color: #ff7e00;
          }

          svg {
            position: relative;
            color: #ff7e00;
            font-size: 16px;
            margin-right: 4px;
            right: 0;
            top: -2px;
          }
        }
      }
    }

    .no-data-row {
      .no-result-found {
        color: rgba(25, 25, 25, 0.8);
        margin-top: 18px;

      }
      
      button {
        svg {
          right: unset !important;
        }
      }
    }
  }

  .td_place_id {
    white-space: unset !important;
    width: 255px !important;
  }

  .td_place_alias { 
    width: 200px;
  }

  .td_verification_status {
    width: 168px;
  }

  .td_test_url {
    width: 152px;
  }

  #pagination-wrapper {
    visibility: hidden;
    ul {
      li {
        font-size: 12px !important;
        position: relative !important;

        &:not(.pagination-button) {
          top: 6px !important;
        }

        span {
          position: absolute;
          top: -42px;
          display: inline-block;
          left: 50%;
          transform: translate(-50%, 0);
        }

        &.active {
          width: 30px !important;
          height: 30px !important;

          span {
            top: -40px !important;
          }
        }

        &.pagination-label {
          top: 5px !important;

          svg {
            height: 12px;
          }
        }
      }
    }
  }
}

.modal-edit-alias-entry {
  .modal-content {
    width: 476px;

    .modal-body {
      padding: 32px;

      .edit-alias-title {
        font-size: 20px;
        letter-spacing: -0.6px;
        color: #191919;
        margin: 0 0 20px 0;
      }

      p {
        font-size: 14px;
        letter-spacing: -0.2px;
        position: relative;
        margin: 20px 0 8px 0;

        span {
          color: rgba(25, 25, 25, 0.6);
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      input {
        width: 100%;
        padding: 11px 18px;
        border-radius: 8px;
        border: solid 1px #dcdcdc;
        font-size: 16px;
        letter-spacing: -0.3px;
        margin: 0;
      }

      button {
        margin-right: 4px;
        width: calc(50% - 2px);
        display: inline-block;
        vertical-align: top;
        margin-top: 20px;

        &:nth-last-child(1) {
          margin-right: 0;
        }
      }
    }
  }
}

.modal-add-location {
  .modal-content {
    width: 600px;
  }
  
  .modal-body {
    border-radius: 8px;
    padding: 32px;
    position: relative;

    input[type="file"] {
      display: none;
    }

    .modal-add-location-title {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: rgba(25, 25, 25, 1);
      line-height: 1;
    }

    .modal-add-location-desc {
      font-size: 16px;
      letter-spacing: -0.3px;
      color: rgba(25, 25, 25, .8);
      margin: 4px 0 28px;
      line-height: 1;
    }

    .close-icon {
      font-size: 24px;
      position: absolute;
      top: 32px;
      right: 32px;
      color: #ff7d00;
      cursor: pointer;

      &:hover {
        color: var(--main-darker-orange);
      }
    }

    .selection-box {
      width: 50%;
      display: inline-block;
      vertical-align: top;
      text-align: center;
      border-right: solid 1px rgba(25, 25, 25, .1);

      &:nth-last-child(1) {
        border: none !important;
      }

      img {
        margin: 0 0 12px 0;
      }

      b {
        font-size: 16px;
        letter-spacing: -0.5px;
      }

      p {
        font-size: 16px;
        letter-spacing: -0.5px;
        margin: 4px 0 12px;
        color: rgba(25, 25, 25, 0.6);
      }

      button {
        height: 22px;
        padding: 4px 10px;
        border-radius: 4px;

        .lds-ring {
          width: 13px;
          height: 13px;
          position: relative;
          margin-right: 8px;
          top: -6px;

          div {
            border: solid 1px #fff;
            border-color: #fff transparent transparent transparent;
            margin: 0;
            width: 13px;
            height: 13px;
          }

        }

        &.disabled {
          background: rgba(25, 25, 25, .6) !important;
          border: solid 1px transparent !important;
        }

        b {
          font-size: 12px;
          letter-spacing: -0.4px;
          position: relative;
          top: -9px;
        }

        svg {
          margin-right: 6px;
          position: relative;
          top: -9px;
          font-size: 12px;
        }

        &.download-template {
          background: #fff !important;
          color: #ff7d00 !important;
          box-shadow: none !important;
          border: 0 !important;


          svg {
            color: #ff7d00 !important;
          }

          &:hover {
            color: var(--main-darker-orange) !important;

            svg {
              color: var(--main-darker-orange) !important;
            }
          }
        }
      }
    }
  }
}

.modal-verification-entry {
  .modal-content {
    width: 538px;

    button {
      width: calc(50% - 2px) !important;
      margin-right: 4px;

      &:nth-last-child(1) {
        margin-right: 0;
      }
    }
  }
}
