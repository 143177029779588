.search-box-wrapper {
    position: relative;
    width: 450px;
    position: relative;
    display: inline-block;
}

.search-box-wrapper input {
    padding: 11px 42px 11px 16px;
    font-size: 16px;
    border:solid 1px #dcdcdc;
    border-radius: 8px;
    width: 100% !important;
    letter-spacing: -0.3px;
}

#search-icon {
    width: 48px;
    height: 48px;
    line-height: 1;
    position: absolute;
    right: 0;
    top: 0;
    display: inline-block;
    text-align: center;
    background: rgba(25, 25, 25, 0.1);
    border-radius: 0 8px 8px 0;
    /* border-left: solid 1px #dcdcdc; */
}

#search-icon svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: rgba(25, 25, 25, 0.6);
}

.search-box-wrapper input:focus {
    border: solid 1px var(--main-dark-orange);
}

.search-box-wrapper input:focus + #search-icon {
    /* border-left: solid 1px var(--main-dark-orange); */
}

.search-box-wrapper input:focus + #search-icon svg {
    /* color: var(--main-dark-orange) !important; */
}

.search-box-wrapper input::placeholder {
    color: #9c9c9c;
}