.sort-table-header {
    cursor: pointer;
    position: relative;
    padding: 0 20px 0 8px !important;

    .ascending-descending-table-wrapper {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translate(0, -50%);
    }
}