.broadcast-message-detail-wrapper {
  min-height: 100%;
  position: relative;

  .LoadingRoll_1234fed {
    height: unset;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .lds-ring {
      position: relative;
      height: 50px;
      width: 50px;
      transform: unset;
      left: unset;
      top: unset;

      div {
        width: 50px;
        height: 50px;
        border: solid 5px #ff7d00;
        border-color: #ff7d00 transparent transparent transparent;
      }
    }
  }

  .error-component-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.channel-icon-detail {
    width: 24px;
}

.broadcast-dot {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    position: relative;
    top: 1px;
    background: #191919;
    display: inline-block;
    top: -3px;
}

.broadcast-content-box {
    min-height: 40px;
    height: unset;
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
    padding-top: 8px;
    padding-bottom: 8px;
    word-break: break-word;
    line-height: 21px;

    &.broadcast-content-box-image {
        padding-right: 15px;
    }

    .icon-info {
        vertical-align: top;
        top: 4px;
    }

    .broadcast-content {
        width: calc(100% - 23px);
        display: inline-block;
        font-size: 14px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.6);

        .broadcast-text {
          margin: 0;
        }

        .file-content-wrapper {
          width: 100%;
          display: block;
          position: relative;
          padding: 0;

          .file-content-left {
            margin: 0 8px 0 0;
            width: 44px;
            height: 44px;
            display: inline-block;
            border-radius: 50%;
            background: #fff;
            position: relative;

            svg {
              color: #ff7d00;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              position: absolute;
              font-size: 22px;
            }
          }

          .file-content-right {
            width: calc(100% - 44px);
            display: inline-block;
            position: absolute;
            top: 3px;
            left: 52px;

            .file-name {
              font-size: 14px;
              letter-spacing: -0.2px;
              margin: 0;
              position: relative;
              top: 12px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: #191919;
              line-height: 1;
              padding: 0 8px 0 0; 
            }

            .file-size {
              font-size: 14px;
              letter-spacing: -0.2px;
              margin: 0;
              color: rgba(25, 25, 25, 0.6);
              line-height: 1;
            }

            &:hover {
              .file-name {
                color: #ff7d00;
              }
            }
          }
        }

        .caption-broadcast {
          margin: 6px 0 0;
        }

        .broadcast-image {
          border-radius: 4px;
          max-width: 100%;
          margin: 0;
          cursor: pointer;
        }

        .broadcast-video {
          border-radius: 4px;
          max-width: 100%;
          margin: 0;
          cursor: pointer;
        }

        p {
            margin: 4px 0 8px;
            font-size: 14px;
            letter-spacing: -0.2px;
            color: rgba(25, 25, 25, .6);
            text-align: justify;
        }
    }
}

.modal-media-broadcast.modal-dialog {
    width: 100vw !important;
  }  
  .modal-media-broadcast .modal-content {
    width: 100%;
    height: 100%;
    background: none;
  }
  .modal-media-broadcast .modal-content .modal-body {
    width: 100%;
    height: 100%;
    background: rgba(25, 25, 25, 0.8);
  }
  .modal-media-broadcast .modal-content .modal-body img.image-preview-val {
    max-width: calc(100vw - 130px);
    max-height: calc(100vh - 130px);
  }

  .modal-media-broadcast .modal-content .modal-body .image-wrapper {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    figure {
      margin: 0;
      
      &.zoom {
        background-size: 180%;
        background-position: -100% -100%;
        background-repeat: no-repeat;
        position: relative;
        width: 100%;
        overflow: hidden;
        cursor: zoom-in;

        img{
          transition: opacity 0.5s;
          display: inline-block;
          // width: 100%;

          &:hover {
            opacity: 0;
          }
        }
      }   
    }
  }

  .modal-media-broadcast .modal-content .modal-body .video-wrapper {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    video {
      max-width: calc(100% - 80px);
      max-height: calc(100% - 80px);
    }
  }

  .modal-media-broadcast .modal-content .modal-body .close-modal-media-broadcast {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #ffffff;
    font-size: 40px;
    cursor: pointer;
    transition: ease-in-out 0.1s;
  }
  .modal-media-broadcast .modal-content .modal-body .close-modal-media-broadcast:hover {
    color: #c9c9c9;
  }