.user-segment-main-wrapper {
  .integration-list-loading-wrapper {
    height: 80vh;

    .lds-ring {
      width: 50px;
      height: 50px;
      position: relative;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      right: unset;

      div {
        border: 5px solid #fff;
        border-color: #ff7d00 transparent transparent transparent;
        width: 50px;
        height: 50px;
        margin: 0;
      }
    }
  }

  .user-segment-header {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid rgba(25, 25, 25, 0.1);

    .remove-segment-wrapper {
      button {
        &.cancel-delete-segment-btn {
          margin-right: 8px;
        }
      }

      &:focus {
        border: none;
        outline: none;
      }
    }
  }

  .search-box-wrapper {
    margin-bottom: 24px;
  }

  // .user-segment-search {
  //   width: 300px;
  //   border-radius: 8px;
  //   border: solid 1px rgba(25, 25, 25, 0.1);
  //   margin-bottom: 24px;

  //   span {
  //     position: relative;
  //     top: -2px;
  //   }

  //   &.focus-search {
  //     border: solid 1px #ff7e00;

  //     svg {
  //       color: #ff7e00;
  //     }
  //   }

  //   input {
  //     border: none;
  //     background: transparent;
  //     padding: 8px 4px 8px 16px;
  //     width: 90%;
  //   }
  // }
}

.table-user-segment-list {
  table-layout: fixed;

  thead {
    tr {
      background: transparent;
  
      td {
        padding: 10px;
        // width: calc((100% - 100px) / 5);
        width: 20%;
        font-size: 14px;
        word-wrap: break-word;
        white-space: normal;
  
        &:nth-child(1) {
          width: 40%;
        }
    
        &:nth-child(2) {
          width: 30%;
        }
  
        &:last-child {
          width: 30%;
          padding-right: 0;
          

          .main-custom-select-option-wrapper {
            display: flex;
            justify-content: flex-end;

            .dropdown {
              width: 80px;
            }
          }
  
          .custom-select-option-value-wrapper {
            height: 30px;
            padding: 0 4px 0 15px !important;
  
            .custom-select-option-value {
              padding: 0;
            }
  
            svg {
              right: 10px;
            }
          }
        }
  
      }
    }
  }

  tbody {
    tr {
      // cursor: pointer;

      &:nth-child(even) {
        background: rgba(25, 25, 25, 0.05);
      }

      &:hover {
        background-color: transparent !important;
      }

      td {
        background: transparent;
        position: relative;
        word-wrap: break-word;
        // vertical-align: top;

        &.col_segment_name {
          p {
            margin-bottom: 0;
            display: inline-block;
            width: 85%;
            vertical-align: top;
          }
        }

        &.col-action {
          padding-right: 0;
          // height: 77px;
          text-align: right;
          white-space: nowrap;

          button {
            border: none !important;

            &.detail-btn {
              width: 100px;

              &:hover {
                background: transparent;
                color: #e87200;
              }

              svg {
                position: relative;
                top: -3px;
              }
            }

            &.delete-btn {
              width: 100px;
              color: #FF3F57 !important;

              &:hover {
                background: transparent;
                color: #e83b51;
              }

              svg {
                position: relative;
                top: -3px;
                color: #FF3F57;
              }
            }
          }
        }
      }
    }
  }
}

.custom-table-content {
  tbody {
    tr {
      &:hover {
        td {
          background: none;
        }
      }

      &:nth-child(even) {
        &:hover {
          td {
            background: rgba(25, 25, 25, 0.05);
          }
        }
      }
    }
  }
}

.new-segment-modal {
  .modal-content {
      width: 520px;

      .modal-body {
        padding: 32px;
  
        .new-segment-wrapper {
          .segment-title-wrapper {
            .new-segment-title {
              font-size: 20px;
              line-height: 24px;
            }

            svg {
              margin-right: 10px;
              font-size: 21px;
              position: relative;
              top: -3px;
            }
          }

          label {
            width: 100%;
            margin-top: 20px;
          }
  
          .segment-name {
            width: 100%;
            height: 48px;
            border: 1px solid rgba(25, 25, 25, 0.1);
            border-radius: 8px;
            padding: 12px 16px;
            margin: 0;

            &:focus {
              border: 1px solid #FF7E00;
            }
          }
  
          .new-segment-button-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin: 20px 0 0;

            button {
              width: 50%;

              &.orange-button {
                margin-left: 8px;
              }
            }
            
          }
        }
    }
  }
}