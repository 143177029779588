.business-profile-whatsapp {
    position: relative;
    width: 100%; 

    .business-profile-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(25, 25, 25, 0.1);
        margin-bottom: 24px;
        padding-bottom: 12px;

        .title-wrapper {
            b {
                font-size: 20px;
            }

            p {
                font-size: 16px;
                margin: 0;
            }
        }
    }

    .business-profile-content {
        .phone-number-wrapper {
            border-bottom: solid 1px #dcdcdc;
            margin-bottom: 24px;
            padding-bottom: 12px;

            .custom-select-phonenumber {
                max-width: 100%;
                width: 340px;
            }
        }

        .business-profile-content-wrapper {
            margin-bottom: 24px;
            max-width: 400px;

            &:last-child {
                margin-bottom: 0;
            }

            .label-wrapper {
                display: flex;
                align-items: center;
                margin-bottom: 8px;

                svg {
                    margin-right: 4px;
                    position: relative;
                    top: 0px;
                }

                img {
                    margin-right: 4px;
                }

                label {
                    margin: 0;
                    font-size: 14px;
                }
            }

            .photo-wrapper {
                margin-bottom: 8px;
            }

            .info-wrapper {
                display: flex;
                align-items: center;

                b {
                    font-size: 12px;
                }

                svg {
                    font-size: 16px;
                    margin-right: 4px;
                }
            }

            .text-wrapper {
                p {
                    margin: 0;
                }

                &.web-link {
                    margin-bottom: 8px;
                }
            }
        }
    }
}