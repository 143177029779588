.member-list-title {
    color: #191919;
    font-size: 20px;
    letter-spacing: -0.6px;
    line-height: 24px;
    padding-bottom: 16px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1);
}

.member-list-tool-wrapper {
    width: 100%;
    position: relative;
    margin-top: 24px;
    margin-bottom: 32px;
}

.member-list-tool-wrapper button.button-invite-member {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    /* padding: 11px 0; */
    /* width: 200px; */
}

/* .member-list-tool-wrapper button.button-invite-member svg {
    position: relative;
    top: -1px;
    margin-right: 5px;
} */

.member-list-table-wrapper {
    width: 100%;
    position: relative;
}

.table-member-list thead tr {
    background: #ffffff;
    border-top: solid 1px #dcdcdc;
}

.table-member-list tbody td {
    background: #ffffff;
    position: relative;
}

.table-member-list button svg {
    position: relative !important;
    right: unset !important;
    top: -2px !important;
    margin-right: 5px;
}

.table-member-list .col-action-button-wrapper {
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translate(0, -50%);
    /* display: none; */
}

.table-member-list .single-remove-button {
    margin-left: 16px;
    color: #ff3f57;
}

.table-member-list .single-remove-button:hover {
    color: #e83b51;
}

.table-member-list .col-action-button-wrapper .view-agent {
    color: #ff7d00;
    letter-spacing: -0.5px;
}

.table-member-list .col-action-button-wrapper .view-agent:hover {
    color: var(--main-darker-orange);
}

.table-member-list .col-action-button-wrapper img.view-agent {
    margin-right: 3px;
    position: relative;
    top: -1px;
}

.table-member-list tbody tr:hover .col-action-button-wrapper {
    /* display: block; */
}

.table-member-list tbody tr:hover {
    background: #f8f8f8 !important;
}

.table-member-list .resent-invitation {
    background: none;
    border: 0;
    color: #ff7d00;
    letter-spacing: -0.5px;
    padding: 0;
}

.table-member-list .resent-invitation img {
    position: relative;
    top: -2px;
}


.table-member-list .resent-invitation:hover {
    color: var(--main-darker-orange);
}


.table-member-list .resend-invitation-loading {
    width: 150px;
}

.table-member-list .resend-invitation-loading .lds-ring {
    top: 3px;
    right: unset;
}

.table-member-list .resend-invitation-loading .lds-ring div {
    margin: 0;
}

.member-tool-wrapper {
    width: 460px;
}

.col_full_name {
    width: 35%;
    position: relative !important;
    padding: 8px 8px 8px 40px !important;

    .custom-checkbox {
        position: absolute !important;
        top: 1px !important;
        left: 12px !important;
    }
}

.col_name {
    width: 25%;
}

.col_role {
    width: 30%;
}