.comp-back-button {
  /* display: flex;
  justify-content: space-between; */
  border: none;
  background: none;
  color: var(--main-dark-orange) !important;
  font-weight: 600;
  letter-spacing: -0.5px;
  line-height: 1.5;
  font-size: 16px;
  margin: 32px 0 0px;
  padding: 0;
}

.comp-back-button svg {
  position: relative;
  top: -1px;
}

.comp-back-button:hover svg {
  transition: 0.25s ease-in-out;
  transform: translateX(-25%);
}
