.single-date-modal-calendar {
    .modal-content {
        width: 380px;
        position: relative;

        .cancel-single-picker {
            position: absolute;
            border: none !important;
            left: 0;
            background: transparent !important;

            &:hover {
                color: var(--main-darker-orange);
            }
        }
    }

    .modal-body {
        padding: 32px 32px 0 32px; 
        position: relative;

        .close-picker {
            position: absolute;
            cursor: pointer;
            font-size: 24px;
            top: 24px;
            right: 24px;

            &:hover {
                color: #ff7d00;
            }
        }
    }

    .modal-date-filter-single-title {
        margin: 0 0 8px 0;
        font-size: 24px;
        letter-spacing: -0.6px;

        svg {
            position: relative;
            margin-right: 4px;
            top: -3px;
        }
    }

    .modal-footer {
        position: relative;
        border-top: 0;
        padding: 0 32px 32px 32px;

        button {
            margin: 0 0 0 4px;
        }

        .button-clear {
            color: #ff7d00;
            border: none;
            background: none;
            font-size: 16px;
            letter-spacing: -0.5px;
            position: absolute;
            left: 0;
            top: 0;

            svg {
                font-size: 19px;
                position: relative;
                top: -1px;
                margin-right: 4px;
            }

            &:hover {
                color: #e87200;
            }
        }
    }
}