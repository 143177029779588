.overview-report-wrapper {
    min-height: 500px;

    .overview-report-title {
        font-size: 20px;
        letter-spacing: -0.6px;
        margin: 0 0 16px 0;
        border-bottom: solid 1px #dcdcdc;
        color: #191919;
        padding: 8px 0 24px 12px;
        position: relative;

        .advanced-date-selection {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .overview-report-description {
        margin: 8px 0 16px;
        font-size: 16px;
        letter-spacing: -0.3px;
        width: calc(100% - 230px);
        color: #191919;
        line-height: 24px;
    }

    .add-overview-report-button {
        // height: 40px;
        // line-height: 23px;
        // padding: 0;
        // width: 195px;
        text-align: center;
        position: absolute;
        top: 32px;
        right: 32px;

        // svg {
        //     vertical-align: top;
        //     font-size: 24px;
        //     position: relative;
        //     top: -1px;
        //     margin-right: 4px;
        // }
    }

    .overview-report-data-outer-wrapper {
        // border-top: solid 1px #dcdcdc;
        padding: 32px 0 0;
    } 

    .overview-report-data-wrapper {
        width: 100%;
        color: #191919;

        .no-overview-report {
            width: 100%;
            text-align: center;
            font-size: 20px;
            letter-spacing: -0.6px;
            color: rgba(25, 25, 25, 0.6);
        }

        .overview-box-wrapper {
            .overview-box-title {
                font-size: 20px;
                letter-spacing: -.6px;
                color: #191919;
                padding: 0 0 12px;
                border-bottom: solid 1px #dcdcdc;
                margin: 0 0 24px;
            }

            .case-report-box {
                border-radius: 8px;
                box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
                margin: 0 24px 24px 0;
                width: calc((100% / 3) - 16px);
                display: inline-block;
                padding: 18px 8px;
                vertical-align: top;

                &:nth-child(3n) {
                    margin: 0 0 24px 0;
                }

                img {

                }

                .case-report-value {
                    font-size: 24px;
                    letter-spacing: -0.6px;
                    margin: 8px 0;
                    line-height: 1;
                }

                .case-report-title {
                    letter-spacing: -0.4px;
                    font-size: 14px;
                    color: rgba(25, 25, 25, 0.6);
                    margin: 0;
                    line-height: 1;
                }
            }

            .overview-case-rating-left {
                vertical-align: top;
                width: 50%;
                display: inline-block;
                position: relative;
                padding: 20px 60px 20px 60px;

                .total-rating-is-text {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                .total-rating-is {
                    background: #ffffff;
                    border-radius: 50%;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 170px;
                    height: 170px;
                    text-align: center;
                }
            }

            .overview-case-rating-right {
                vertical-align: top;
                width: 50%;
                display: inline-block;

                .table-overview {
                    width: 100%;

                    thead {
                        tr {
                            td {
                                font-size: 16px;
                                letter-spacing: -0.5px;
                                color: #191919;
                                padding: 0 0 16px 0;

                                &:nth-child(2) {
                                    text-align: right;
                                }
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                font-size: 14px;
                                letter-spacing: -0.4px;
                                border-bottom: solid 1px #dcdcdc;
                                padding: 8px 0 8px 14px;
                                position: relative;

                                div {
                                    width: 6px;
                                    height: 6px;
                                    border-radius: 50%;
                                    position: absolute;
                                    left: 0;
                                    top: 50%;
                                    transform: translate(0, -50%);
                                }

                                &.stars-8 {
                                    div {
                                        background: #DCDCDC;
                                    }
                                }

                                &.stars-7 {
                                    div {
                                        background: #FF3F57;
                                    }
                                }

                                &.stars-6 {
                                    div {
                                        background: #007A8A;
                                    }
                                }

                                &.stars-5 {
                                    div {
                                        background: #51E683;
                                    }
                                }

                                &.stars-4 {
                                    div {
                                        background: #3BAEff;
                                    }
                                }

                                &.stars-3 {
                                    div {
                                        background: #9C2EEF;
                                    }
                                }
                                
                                &.stars-2 {
                                    div {
                                        background: #F4C12A;
                                    }
                                }

                                &.stars-1 {
                                    div {
                                        background: #f99080;
                                    }
                                }

                                &:nth-child(2) {
                                    text-align: right;
                                }
                            }
                        }
                    }
                }

                .overview-case-rating-average {
                    padding: 16px 8px;
                    border-radius: 8px;
                    width: 100%;
                    position: relative;
                    display: block;
                    box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
                    margin-top: 24px;

                    .rating-value {
                        font-size: 24px;
                        letter-spacing: -0.6px;
                    }

                    svg {
                        color: #F2BF2A;
                        font-size: 22px;
                        margin-right: 4px;
                        top: -5px;
                        position: relative;
                    }

                    p {
                        margin: 8px 0 0;
                        color: rgba(25, 25, 25, 0.6);
                        font-size: 14px;
                        letter-spacing: -0.4px;
                    }
                }
            }

            .overview-agent-report-wrapper {
                width: 100%;
                position: relative;
                display: block;

                .overview-agent-report-box {
                    text-align: center;
                    width: 25%;
                    padding: 0 16px;
                    display: inline-block;
                    vertical-align: top;
                }

                .agent-report-icon-wrapper {
                    width: 48px;
                    height: 48px;
                    position: relative;
                    display: inline-block;
                    border-radius: 50%;
                    background: #F99080;
                    box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);

                    svg {
                        color: #ffffff;
                        font-size: 20px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                p {
                    &.agent-report-text-1 {
                        font-size: 24px;
                        letter-spacing: -0.6px;
                        margin: 8px 0;

                        .rating-text {
                            svg {
                                position: relative;
                                top: -3px;
                                font-size: 20px;
                                color: #F4C12A;
                                margin-right: 4px;
                            }
                        }
                    }

                    &.agent-report-text-2 {
                        font-size: 12px;
                        letter-spacing: -0.4px;
                        margin: 0 0 2px;
                    }   

                    &.agent-report-text-3 {
                        font-size: 12px;
                        letter-spacing: -0.2px;
                        color: rgba(25, 25, 25, 0.8);
                        margin: 0;
                    }
                }
            }
        }
    }
}