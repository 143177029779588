.chat-room-message-out-template-image-wrapper {
    width: 100%;
    text-align: right;
    position: relative;
    margin-top: 16px;
    padding: 0 24px;
    font-size: 0;

    .message-action-wrapper {
      // right: calc(30% + 10px) !important;
      left: -45px;
    }
  }
  .chat-room-message-out-template-image-wrapper .message-out-bubble {
    border-radius: 16px 2px 16px 16px;
    max-width: 300px;
    display: inline-block;
    position: relative;
    font-size: 0;
    background: #FFF2E5;
    border: 1px solid rgba(25, 25, 25, 0.1);
    text-align: left;
    color: #191919;
    min-height: 80px;
  }
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble.deleted-bubble {
    color: #ffffff;
    padding: 10px 10px 10px 33px;
  }
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble.deleted-bubble
    .deleted-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);
  }
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble
    .forwarded-message {
    color: #ffffff;
    font-size: 12px;
    letter-spacing: -0.4px;
    line-height: 18px;
    display: block;
    margin-bottom: 5px;
  }
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble
    .reply-message {
    width: calc(100% - 20px);
    border-radius: 4px;
    border-left: solid 5px #ff8f22;
    background: #e88422;
    color: #ffffff;
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 5px 7px 5px 7px;
    line-height: 1;
  }
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble
    .reply-message
    p {
    margin: 0;
    font-size: 12px;
    letter-spacing: -0.4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
  }
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble
    .reply-message
    p:nth-child(2) {
    letter-spacing: -0.2px;
  }
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble
    .reply-message.reply-file
    .reply-file-thumbnail {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    position: relative;
  }
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble
    .reply-message.reply-file
    .reply-file-thumbnail
    img {
    width: 40px;
    height: 40px;
  }
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble
    .reply-message.reply-file
    .reply-file-thumbnail
    svg {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 35px;
    transform: translate(-50%, -50%);
  }
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble
    .reply-message.reply-file
    .reply-text-wrapper {
    width: calc(100% - 50px);
    display: inline-block;
    vertical-align: top;
  }
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble
    .message-info {
    /* position: absolute; */
    /* bottom: 11px; */
    /* right: 27px; */
    margin: 0;
    color: rgba(25, 25, 25, 0.4);
    font-size: 10px;
    letter-spacing: -0.4px;
    line-height: 18px;
    /* width: 24px; */
    width: 100%;
    white-space: nowrap;
    text-align: right;
  
    .centered-dot {
      width: 2px;
      height: 2px;
      display: inline-block;
      vertical-align: top;
      background: #ffffff;
      border-radius: 50%;
      position: relative;
      top: 9px;
    }
  }
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble
    .message-info
    img {
    margin-left: 4px;
    width: 16px;
    position: relative;
    /* top: 4px; */
  }
  
  .message-action-wrapper {
    position: relative;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    /* left: -170px; */
    /* left: -90px; */
    left: -45px;
    display: none;
  }
  .message-action-wrapper.message-action-right {
    right: -170px;
    left: unset;
  }
  .message-action-wrapper .forward-message svg {
    transform: scaleX(-1);
  }
  .message-action-wrapper .message-action-button-wrapper {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    position: relative;
    display: inline-block;
    background-attachment: fixed;
    margin-left: 10px;
  }
  .message-action-wrapper .message-action-button-wrapper svg {
    color: #9b9b9b;
    position: absolute;
    font-size: 18px;
    left: 6px;
    top: 6px;
  }
  
  .chat-room-message-out-broadcast-wrapper:hover .message-action-wrapper {
    display: inline-block;
  }
  
  .message-action-popup-wrapper {
    width: 172px;
    display: inline-block;
    box-shadow: 0px 0px 9px 0px rgba(25, 25, 25, 0.1);
    line-height: 1;
    position: absolute;
    right: 63px;
    top: 0;
    border-radius: 8px;
    z-index: 1;
  }
  .message-action-popup-wrapper .message-action-content {
    width: 100%;
    position: relative;
    padding: 12px 12px 12px 35px;
    font-size: 15px;
    letter-spacing: -0.3px;
    color: #191919;
    border-bottom: solid 1px #dcdcdc;
    background: #ffffff;
    cursor: pointer;
  }
  .message-action-popup-wrapper .message-action-content.delete-message {
    color: #ff3f57;
  }
  .message-action-popup-wrapper .message-action-content.forward-message svg {
    transform: rotate(180deg) translate(0, 50%);
  }
  .message-action-popup-wrapper .message-action-content:hover {
    background: #ff7d00;
    color: #ffffff;
  }
  .message-action-popup-wrapper .message-action-content:hover svg {
    color: #ffffff;
  }
  .message-action-popup-wrapper .message-action-content:nth-child(1) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .message-action-popup-wrapper .message-action-content:nth-last-child(1) {
    border: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .message-action-popup-wrapper svg {
    left: 12px;
    font-size: 16px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
  
  // IMAGE
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble
    .bubble-image-wrapper {
    position: relative;
    min-width: 150px;
    // min-height: 150px;
    padding: 10px;
  
    img {
      width: 100%;
      object-fit: cover;
      border-radius: 16px 2px 16px 16px;
    }

    &.bubble-wrapper-with-caption {
      padding: 10px 10px 0;
    }
  
  //   &.bubble-wrapper-without-caption {
  //     img {
  //       border-radius: 16px 2px 16px 16px;
  //     }
  //   }
  
    &.bubble-wrapper-clickable {
        img {
          cursor: pointer;
        }
    }
  }
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble
    .bubble-image-wrapper
    img {
    vertical-align: top;
    width: 100%;
    max-width: 100%;
  }
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble
    .bubble-image-wrapper
    .icon-status-wrapper {
    border-radius: 50%;
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-block;
    background: rgba(25, 25, 25, 0.4);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble
    .bubble-image-wrapper
    .icon-status-wrapper
    svg {
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
  }
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble
    .bubble-image-wrapper
    .icon-status-wrapper
    .onprocess-wrapper {
    height: 40px;
    width: 40px;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .chat-room-message-out-template-image-wrapper .message-out-bubble .main-image-message {
    width: 100%;
    max-width: 100%;
  }
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble
    .thumbnail-image-message {
    width: 300px;
  //   min-width: 278px;
  }
  .modal-image.modal-dialog {
    width: 100vw !important;
  }
  .modal-image .modal-content {
    width: 100%;
    height: 100%;
    background: none;
  }
  .modal-image .modal-content .modal-body {
    width: 100%;
    height: 100%;
    background: rgba(25, 25, 25, 0.8);
  }
  .modal-image .modal-content .modal-body img.image-preview-val {
    max-width: calc(100vw - 130px);
    max-height: calc(100vh - 130px);
  }
  .modal-image .modal-content .modal-body .image-wrapper {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  
    figure {
      margin: 0;
  
      &.zoom {
        background-size: 180%;
        background-position: -100% -100%;
        background-repeat: no-repeat;
        position: relative;
        width: 100%;
        overflow: hidden;
        cursor: zoom-in;
  
        img {
          transition: opacity 0.5s;
          display: inline-block;
          // width: 100%;
  
          &:hover {
            opacity: 0;
          }
        }
      }
    }
  }
  .modal-image .modal-content .modal-body .close-modal-image {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #ffffff;
    font-size: 40px;
    cursor: pointer;
    transition: ease-in-out 0.1s;
  }
  .modal-image .modal-content .modal-body .close-modal-image:hover {
    color: #c9c9c9;
  }
  .chat-room-message-out-template-image-wrapper .message-out-bubble .caption-text {
    font-size: 16px;
    // padding: 10px 73px 10px 10px;
    padding: 4px 10px 0 10px;
    margin: 0;
    word-break: break-word;
  }
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble
    .reply-message {
    width: calc(100% - 20px);
    border-radius: 4px;
    border-left: solid 5px #ff8f22;
    background: #e88422;
    color: #191919;
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 5px 7px 5px 7px;
    line-height: 1;
    z-index: 1;
    color: #ffffff;
  }
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble
    .reply-message
    p {
    margin: 0;
    font-size: 12px;
    letter-spacing: -0.4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
  }
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble
    .reply-message
    p:nth-child(2) {
    letter-spacing: -0.2px;
  }
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble
    .reply-message.reply-file
    .reply-file-thumbnail {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    position: relative;
  }
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble
    .reply-message.reply-file
    .reply-file-thumbnail
    img {
    width: 40px;
    height: 40px;
  }
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble
    .reply-message.reply-file
    .reply-file-thumbnail
    svg {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 35px;
    transform: translate(-50%, -50%);
  }
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble
    .reply-message.reply-file
    .reply-text-wrapper {
    width: calc(100% - 50px);
    display: inline-block;
    vertical-align: top;
  }
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble
    .message-info {
    // position: absolute;
    position: relative;
    margin: 0;
    white-space: nowrap;
    border-radius: 10px;
    font-size: 10px;
    letter-spacing: -0.4px;
    color: #ffffff;
    // bottom: 10px;
    // right: 10px;
    // z-index: 1;
    width: 100%;
    text-align: right;
    padding: 2px 10px 10px;
  
    .centered-dot {
      width: 2px;
      height: 2px;
      display: inline-block;
      vertical-align: top;
      background: #ffffff;
      border-radius: 50%;
      position: relative;
      top: 9px;
    }
  }
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble
    .message-info-dark {
    background: rgba(25, 25, 25, 0.6);
    padding: 2px 5px;
    color: #ffffff;
    bottom: 60px;
    right: 16px;
    position: absolute;
    width: unset;
    border-radius: 16px;
  }
  
  .chat-room-message-out-template-image-wrapper
    .message-out-bubble
    .message-info
    img {
    /* vertical-align: top; */
    margin-left: 3px;
  }
  .chat-room-message-out-template-image-wrapper .failed-sending-file-warning {
    font-size: 12px;
    letter-spacing: -0.4px;
    color: rgba(25, 25, 25, 0.4);
  }
  .chat-room-message-out-template-image-wrapper:hover .message-action-wrapper {
    display: inline-block;
  }
  .chat-room-message-out-template-image-wrapper
    .message-action-wrapper
    .message-action-button-wrapper {
    cursor: pointer;
  }
  .chat-room-message-out-template-image-wrapper
    .CircularProgressbar
    .CircularProgressbar-trail {
    stroke: transparent;
  }
  .chat-room-message-out-template-image-wrapper
    .CircularProgressbar
    .CircularProgressbar-path {
    stroke: #ffffff;
  }
  .chat-room-message-out-template-image-wrapper .message-out-template-banner {
    background: rgba(25, 25, 25, 0.05);
    border-radius: 6px;
    margin: 0 10px 10px;
    cursor: pointer;
    color: var(--main-dark-orange);
    text-align: center;
    padding: 5px 16px;
    display: flex;
    justify-content: center;
    font-weight: 700;
  
    svg {
      margin-right: 5px;
      align-self: center;
      font-size: 16px;
    }
  
    p {
      font-size: 14px;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      width: fit-content;
      /* margin: 0; */
      display: inline-block;
      white-space: nowrap;
      vertical-align: -7px;
    }
  }
  
  .modal-templated-message {
    border-radius: 8px;
    padding: 32px;
  
    .modal-content {
      width: 476px !important;
    }
  
    svg {
      vertical-align: -1px;
      margin-right: 5px;
    }
    b {
      font-weight: bold;
      font-size: 20px;
    }
    p {
      font-size: 14px;
      margin-bottom: 20px;
    }
    button {
      width: 100%;
    }
  }