.filter-report-title {
    border-bottom: solid 1px #dcdcdc;
    margin-bottom: 34px !important;
}

.form-filter-report-button-wrapper {
    width: 100%;
    position: relative;
    display: block;
    text-align: right;
    
    button {
        margin: 24px 6px 0;
        text-align: center;
        // float: right;
        padding: 0 75px !important;
    }
}

.date-report-select-wrapper {
    display: inline-block;
}
