.pay-methods-main-wrapper {
  width: 100%;
  min-height: calc(100vh - 87px);
  margin-top: 87px;
  background: #f3f3f3;

  .pay-methods-content {
    .pay-methods-header {
      padding: 40px 72px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      .comp-back-button {
        margin: 0;
      }
      
      .button {
        height: 40px;
        width: 110px;
        // display: flex;
      }

      .cont-button {
        padding: 8px 24px;
        display: flex;
        justify-content: space-between;
        width: 145px;

        &:disabled {
          background: rgba(25, 25, 25, 0.1) !important;
        }

        .grey-lds-ring {
          div {
            border-color: rgba(25, 25, 25, 0.4) transparent transparent transparent;
            
          }
        }

        div {
          color: rgba(25, 25, 25, 0.4);
        }
      }
    }



    .pay-methods-container {
      // position: relative;
      // left: 50%;
      // transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;

      .alert-component {
        width: 100%;
        color: #044B84;
        font-size: 14px;
        letter-spacing: -0.4px;
        border: solid 1px #044B84;
        background: #DCEBFF;
        line-height: 18px;
        padding: 8px 24px 8px 40px;
        margin: 0 0 10px 0;

         svg {
           font-size: 16px;
          //  top: 12px;
          //  transform: unset;
         }
      }

      .pay-title {
        margin: 40px 0 32px;
        font-size: 40px;
        letter-spacing: -0.6px;
        line-height: 48px;
      }

      .pay-methods-wrapper {
        display: flex;
        justify-content: space-between;
        width: 652px;

        button {
          &.select-button {
            // background-color: #FFF2E5;
            width: 100%;
            height: 40px;
            // color: #FF7E00;
          }

          &:focus {
            box-shadow: none;
          }

          svg {
            margin-right: 6px;
            position: relative;
            top: -1px;
          }
        }


        .pay-methods-auto {
          background-color: #ffffff;
          width: 320px;
          display: flex;
          flex-direction: column;
          align-items: center;
          border: 1px solid rgba(25, 25, 25, 0.1);
          border-radius: 8px;
          padding: 24px;

          img {
            margin-bottom: 12px;
          }

          p {
            text-align: center;
          }
        }

        .pay-methods-manual {
          background-color: #ffffff;
          width: 320px;
          display: flex;
          flex-direction: column;
          align-items: center;
          border: 1px solid rgba(25, 25, 25, 0.1);
          border-radius: 8px;
          padding: 24px;

          img {
            margin-bottom: 12px;
          }

          p {
            text-align: center;
          }
        }
      }
    }
  }
}