.checkout-success-main-wrapper {
  width: 100%;
  min-height: calc(100vh - 87px);
  margin-top: 87px;
  background: #f3f3f3;

  .checkout-success-content {

    .checkout-success-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: calc(100vh - 90px);

      h1 {
        font-size: 40px;
        margin: 32px 0 12px;
      }

      p {
        font-size: 16px;
        color: rgba(25, 25, 25, 0.8);

        a {
          color: #ff7e00;
        }
      }
    }
  }
}