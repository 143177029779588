.broadcast-message-main-wrapper {
    .btn {
        border: 0;
    }

    .view-broadcast-message-detail-middle {
        padding: 16px;
        display: flex;
        border: solid 1px #dcdcdc;
        margin-bottom: 24px;
        max-width: 100%;
        overflow: auto;

        > div {
          svg {
            margin-right: 5px;
            vertical-align: -2.4px;
          }
          img {
            margin-right: 5px;
            margin-bottom: 4px;
            position: relative;
            top: 1px;
          }
        }
        p {
          margin-top: 8px;
        }
        .view-broadcast-message-detail-middle-category {
          p {
            margin-bottom: 0;
          }
        }
        .view-broadcast-message-detail-middle-channel {
          div > img {
            margin-bottom: 9px;
          }
          > div {
            margin-top: 8px;
          }
        }
        .view-broadcast-message-detail-middle-status {
          .colored-info-box {
            margin-top: 10px;
            margin-bottom: 1rem;
            p {
              color: #ffffff;
              margin-top: 0 !important;
            }
          }
        }
        .view-broadcast-message-detail-middle-content-body {
          max-width: 568px;
          > img {
            margin-bottom: 5px !important;
          }
          .message-content {
            margin-top: 20px;
            img {
              width: 200px;
              height: 200px;
              object-fit: cover;
              border-radius: 8px;
              cursor: pointer;
              margin: 0 0 8px 0;
            }
            .content-caption {
              padding-top: 10px;
              border-top: solid 1px #dcdcdc;
              margin-top: 0 !important;
            }
          }
    
          .file-url {
            cursor: pointer;
            img {
              width: 24px !important;
              height: 24px !important;
              margin-right: 7px;
            }
            div > p {
              margin-top: 2px;
              font-weight: bold;
              font-size: 14px;
              color: #ff7e00;
              line-height: 21px;
              letter-spacing: -0.4px;
              border-bottom: solid 1px #ff7e00;
            }
          }
        }
    
        &.view-broadcast-message-detail-middle-total-status {
          font-weight: bold;
          > div {
            margin-right: 9%;

            &:nth-last-child(1) {
                margin-right: 0;
            }
          }
          .flexing {
            font-weight: bold;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: -0.4px;
          }
          .total {
            font-size: 24px;
            line-height: 30px;
            letter-spacing: -0.6px;
          }
        }
      }
}

.broadcast-message-list-title {
    color: #191919;
    font-size: 20px;
    letter-spacing: -0.6px;
    line-height: 24px;
    padding-bottom: 16px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1);
}

.broadcast-message-list-tool-wrapper {
    width: 100%;
    position: relative;
    margin-top: 24px;
    margin-bottom: 32px;
}

.broadcast-message-list-info {
    font-size: 16px;
    letter-spacing: -0.3px;
    color: rgba(25, 25, 25, 0.8);
    line-height: 1.5;
    padding: 0 208px 12px 0;
    margin-bottom: 24px;
    border-bottom: solid 1px #dcdcdc;
}

.broadcast-message-list-tool-wrapper button.button-new-message {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    padding: 11px 0;
    width: 200px;
}

.broadcast-message-list-tool-wrapper button.button-new-message svg {
    position: relative;
    top: -1px;
    margin-right: 5px;
}

.broadcast-message-list-table-wrapper {
    width: 100%;
    position: relative;

    .pagination-wrapper {
        ul {
          li {
            font-size: 12px !important;
            position: relative !important;
      
            &:not(.pagination-button) {
              top: 6px !important;
            }
      
            span {
              position: absolute;
              top: -42px;
              display: inline-block;
              left: 50%;
              transform: translate(-50%, 0);
            }
      
            &.active {
              width: 30px !important;
              height: 30px !important;
      
              span {
                top: -40px !important;
              }
            }
      
            &.pagination-label {
              top: 5px !important;
      
              svg {
                height: 12px;
              }
            }
          }
        }
      }
}

.table-broadcast-message-list thead tr {
    background: #ffffff;
    // border-top: solid 1px #dcdcdc;

    td {
        // &:nth-child(1) {
        //     width: 100%;
        // }

        // &:nth-child(2), &:nth-child(3) {
        //     width: 100px;
        // }

        // &:nth-child(4), &:nth-child(5) {
        //     width: 120px;
        // }

        &:last-child {
            width: 90px;
    
            .custom-select-option-value-wrapper {
              width: 55px !important;
              height: 23px;
              padding: 2px 8px 2px 12px !important;
              border-radius: 4px !important;
  
              p {
                font-size: 12px;
                font-weight: bold;
              }
    
              .custom-select-option-value {
                padding: 0;
              }
    
              svg {
                right: 3px !important;
                height: 15px;
                top: 11px !important;
                position: absolute !important;
                margin-right: unset !important;
              }
            }
          }
    }
}

.table-broadcast-message-list tbody td {
    position: relative;

    &.col_view {
      svg {
        position: relative;
        top: -1px !important;
      }
    }

    &:nth-child(1) {
        div {
            display: flex;
            img {
                margin-right: 4px;
                margin-top: 2px;
            }
        }
    }

    // &:nth-child(2), &:nth-child(3) {
    //     width: 100px;
    // }

    // &:nth-child(4), &:nth-child(5) {
    //     width: 120px;
    // }

    &:last-child {
        color: #ff7e00;
        // cursor: pointer;
        font-weight: 700;

        a {
          color: #ff7e00;
        }

        svg {
          position: relative;
          color: #ff7e00;
          font-size: 16px;
          margin-right: 4px;
          right: 0;
          top: -2px;
        }
      }

    .medium-logo {
        width: 16px;
        height: 16px;
    }
}

.table-broadcast-message-list button svg {
    position: relative !important;
    right: unset !important;
    top: -2px !important;
    margin-right: 5px;
}

.table-broadcast-message-list .col-action-button-wrapper {
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translate(0, -50%);
    /* display: none; */
}

.table-broadcast-message-list td {
    max-width: unset !important;
}

.new-message-modal {
    .modal-content {
        width: 712px;
    }

    &.new-message-select-channel {
        .modal-content {
            width: 476px;
        }
    }

    .modal-body {
        padding: 32px;

        .new-message-wrapper {
            position: relative;

            .comp-back-button {
                margin: 0 0 20px;
            }

            .new-message-title {
                font-size: 20px;
                letter-spacing: -0.6px;
                color: #191919;
                line-height: 1;
                margin: 6px 0 0;
            }
    
            .new-message-description {
                font-size: 16px;
                letter-spacing: -0.3px;
                color: rgba(25, 25, 25, 0.8);
                line-height: 1;
                margin: 6px 0 0;
            }

            .close-modal-new-message {
                position: absolute;
                right: 0;
                top: 0;
                font-size: 24px;
                cursor: pointer;

                &:hover {
                    color: #ff7d00;
                }
            }
        }

        .new-message-channel-option-container {
            label {
                font-size: 14px;
                letter-spacing: -0.4px;
                margin: 20px 0 0;
            }

            .new-message-channel-option-wrapper {
                width: 100%;
                position: relative;

                .new-message-channel-option {
                    cursor: pointer;
                    border-radius: 8px;
                    border: solid 1px #dcdcdc;
                    color: rgba(25, 25, 25, 0.6);
                    font-size: 16px;
                    letter-spacing: -0.4px;
                    padding: 0 24px;
                    height: 40px;
                    line-height: 36px;
                    vertical-align: top;
                    margin: 4px 4px 0 0;
                    display: inline-block;

                    &.active-channel {
                        background: #FFF2E5;
                        color: #ff7d00;
                        border: solid 1px #FFF2E5;
                    }

                    &:hover {
                        background: #FFF2E5;
                        color: #ff7d00;
                        border: solid 1px #FFF2E5;
                    }

                    img {
                        font-size: 16px;
                        letter-spacing: -0.5px;
                        width: 16px;
                        height: 16px;
                        margin-right: 4px;
                        position: relative;
                        top: -1px;
                    }
                }
            }
        }

        .new-message-option-container {
            width: 100%;
            margin-top: 20px;
            text-align: center;

            .new-message-option-list {
                cursor: pointer;
                text-align: center;
                width: calc((100% / 3) - 8px);
                margin-right: 12px;
                display: inline-block;
                vertical-align: top;
                border-radius: 8px;
                border: solid 1px rgba(25, 25, 25, 0.1);
                padding: 24px;
                height: 178px;

                &:nth-last-child(1) {
                    margin-right: 0;
                }

                img {
                    display: inline-block;
                    margin: 0 0 14px;
                }

                b {
                    line-height: 1;
                    font-size: 16px;
                    letter-spacing: -0.5px;
                    color: rgba(25, 25, 25, 0.6);
                }

                p {
                    letter-spacing: -0.2px;
                    color: rgba(25, 25, 25, 0.8);
                    font-size: 14px;
                    line-height: 21px;
                }

                &:hover {
                    border: solid 1px rgba(25, 25, 25, 0.05);
                    background: rgba(25, 25, 25, 0.05);

                    b {
                        color: #191919;
                    }

                    p {
                        color: #191919;
                    }
                }

                &.active-message-type {
                    border: solid 1px rgba(25, 25, 25, 0.05);
                    background: rgba(25, 25, 25, 0.05);

                    b {
                        color: #191919;
                    }

                    p {
                        color: #191919;
                    }
                }
            }
        }
    }

    .cancel-broadcast {
        margin: 20px 8px 0 0;
        width: calc(50% - 4px);
        height: 40px;
    }

    .continue-broadcast {
        margin: 20px 0 0 0;
        width: calc(50% - 4px);
        height: 40px;
    }
}

.bc-tootlip-toggle {
  margin-left: 4px;
}

