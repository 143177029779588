.chat-history-outer-wrapper {
    overflow-y: auto;
    max-height: 100%;
}

.chat-history-tab-list {
    padding: 8px;
    cursor: pointer;
    border-bottom: solid 1px #dcdcdc;
}

.chat-history-tab-list:hover {
    background: rgba(25, 25, 25, 0.1);
}

.chat-history-omnichannel-icon {
    position: relative;
    top: -2px;
    width: 24px;
}

.chat-history-topic-name {
    width: calc(100% - 24px);
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 4px;
    font-size: 16px;
    letter-spacing: -0.5px;
    vertical-align: top;
    margin: 0;
}

.chat-history-last-message {
  margin: 4px 0 0;
  position: relative;
  top: -1px;
  font-size: 14px;
  letter-spacing: -0.2px;
  color: rgba(25, 25, 25, 0.6);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-history-time-and-status-wrapper {
    width: 100%;
    position: relative;
}

.chat-history-time-wrapper {
    font-size: 12px;
    letter-spacing: -0.4px;
    color: rgba(25, 25, 25, 0.4);
    margin-top: 7px;
}

.chat-history-time-wrapper svg {
    position: relative;
    top: -1px;
    font-size: 12px;
    margin-right: 3px;
}

.chat-history-case-status {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 12px;
    letter-spacing: -0.4px;
    border-radius: 4px;
    padding: 2px 12px 2px 8px;
    line-height: 18px;
    border-radius: 4px;
    background: #19c700;
    color: #ffffff;
    max-width: 125px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat-history-case-status svg {
    position: relative;
    top: -1px;
    margin-right: 4px;
}

.chat-history-case-status.chat-history-other-agent {
    background: #0052ff;
}

.chat-history-case-status.chat-history-solved {
    color: rgba(25, 25, 25, 0.6);
    background: rgba(25, 25, 25, 0.05);
}