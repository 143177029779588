.case-modal-calendar {
    .modal-footer {
        position: relative;

        .button-clear {
            color: #ff7d00;
            border: none;
            background: none;
            font-size: 16px;
            letter-spacing: -0.5px;
            position: absolute;
            left: 0;
            top: 0;

            svg {
                font-size: 19px;
                position: relative;
                top: -1px;
                margin-right: 4px;
            }

            &:hover {
                color: #e87200;
            }
        }
    }
}

.DayPicker-Day--disabled {
    // pointer-events: none;
    cursor: not-allowed !important;
}