.modal-new-conversation {
  .select--is-disabled {
    .select__input {
      display: none;
    } 
  }
  .channel-type {
    margin: 0;
    line-height: 1;
    font-size: 16px;
    letter-spacing: -0.3px;

    img {
        margin-right: 4px;
        position: relative;
        top: -2px;
    }
  }

  .case-id {
    p {
      margin-bottom: 0;
      color: rgba(25, 25, 25, 0.8);
    }
  }
  input:disabled {
    background-color: rgba(25, 25, 25, 0.1) !important;
  }

  input::placeholder {
      color: rgba(25, 25, 25, 0.4);
  }

  .disabled {
    label, b, input {
      color: rgba(25, 25, 25, 0.4);
    }
  }

  .new-conversation-field-info {
    font-size: 12px;
    letter-spacing: -0.4px;
    color: #191919;
    margin: 8px 0 0;

    svg {
      position: relative;
      margin-right: 4px;
      top: -1px;
      font-size: 16px;
    }
  }

  .search-contact-wrapper {
    .conversation-data-contact-wrapper {
      border-radius: 8px;
      border: solid 1px rgba(25, 25, 25, .1);
      padding: 11px 16px;
      font-size: 16px;
      color: #191919;
      
      p {
          white-space: nowrap;
          overflow: hidden;
          letter-spacing: -0.3px;
          text-overflow: ellipsis;
          margin: 0;
          max-width: 100%;
      }
    }
    
    .LoadingRoll_1234fed {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translate(0, -50%);
      font-size: 20px;
    }
  }

  .search-message-template-wrapper, .search-language-template-wrapper {
    position: relative;
    div {
      div {
        margin-bottom: 0;
      }
    }

    .LoadingRoll_1234fed {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translate(0, -50%);
      font-size: 20px;
    }
  }

  .search-message-template-wrapper.disabled, .search-language-template-wrapper.disabled {
    input {
      visibility: hidden;
    }
  }

  .add-by-phone-number {
    &:hover {
      svg {
        color: #ffffff;
      }
    }
  }
}

.new-conversation-container {
  padding: 32px;

  .custom-select-phonenumber {
    margin-bottom: 24px;
  }

  .new-templated-message-title {
    color: #191919;

    b {
      font-size: 20px;
      letter-spacing: -0.6px;
    }

    svg {
      font-size: 24px;
      margin: 0 4px 0 0;
      position: relative;
      top: -2px;
    }

    p {
      margin: 2px 0 0;
      color: rgba(25, 25, 25, 0.6);
      font-size: 14px;
      letter-spacing: -0.2px;
    }
  }
}
