.integration-auto-message-setup {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    margin-bottom: 12px;
    margin-top: 24px;

    .label-auto-message {
      position: relative;
      width: 400px;

      span {
        position: absolute;
        right: 0;
      }
    }

    .title-wrapper {
        display: block;
        width: 100%;
        position: relative;
        padding-right: 150px;
        border-bottom: solid 1px #dcdcdc;
        padding-bottom: 24px;
        margin-bottom: 24px;

        .title-description {
            display: block;

            p {
                margin: 0;
            }

            .title-customize {
                font-size: 20px;
                // text-transform: capitalize;
            }

            .description-customize {
                font-size: 14px;
            }
        }

        button {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .top-setup-wrapper {
        display: block;
        width: 100%;
        position: relative;
        padding-right: 150px;
        margin-bottom: 24px;

        .top-setup-description {
            display: block;

            p {
                margin: 0;
            }

            .title-customize {
                font-size: 16px;
                letter-spacing: -0.5px;
                // text-transform: capitalize;
            }

            .description-customize {
                font-size: 14px;
                letter-spacing: -0.2px;
                color: rgba(25, 25, 25, 0.8);
            }
        }

        button {
            position: absolute;
            right: 0;
            top: 0;
        }

        .custom-switch-wrapper {
            position: absolute;
            right: 0;
            top: 0;
            width: 48px;
            height: 28px;

            .custom-switch {
                &::before {
                    width: 18px;
                    height: 18px;
                    bottom: 6px;
                }
            }

            input:checked + .custom-switch:before {
                transform: translateX(16px);
            }
        }
    }

    .auto-message-setup-textarea-wrapper {
        position: relative;
        display: inline-block;

        .auto-message-setup-textarea {
            padding: 12px 16px;
            border-radius: 8px;
            border: solid 1px #dcdcdc;
            font-size: 16px;
            letter-spacing: -0.3px;
            height: 200px;
            width: 400px;
            // margin-bottom: 15px;
        }

        span {
            position: absolute;
            bottom: -10px;
            font-size: 14px;
            letter-spacing: -0.2px;
            right: 0;
        }
    }

    .user-rating-wrapper {
        position: relative;
        width: 100%;

        .color-select-wrapper {
            width: 100%;
            position: relative;
            padding-top: 24px;

            .color-select-title {
                font-size: 14px;
                letter-spacing: -0.4px;
            }

            .color-select-desc {
                font-size: 14px;
                letter-spacing: -0.2px;
            }

            .button-go-preview {
                position: absolute;
                top: 24px;
                right: 0;
            }

            .color-input-wrapper {
                position: relative;
                width: 166px;
                height: 48px;
                border-radius: 8px;
                border: solid 1px #dcdcdc;
                cursor: pointer;
                line-height: 47px;
                font-size: 16px;
                letter-spacing: -0.3px;
                color: #191919;
                padding: 0 0 0 16px;
                margin-top: 12px;
    
                .color-placeholder {
                  color: rgba(25, 25, 25, 0.6);
                }
    
                img {
                  position: absolute;
                  position: absolute;
                  right: 16px;
                  top: 50%;
                  transform: translate(0, -50%);
                }
    
                .color-tag {
                  display: inline-block !important;
                  margin-right: 1px;
                  margin: 0;
                }
    
                .color-value {
                  width: 16px;
                  height: 16px;
                  display: inline-block;
                  background: #dcdcdc;
                  border-radius: 50%;
                  position: absolute;
                  right: 16px;
                  top: 50%;
                  transform: translate(0, -50%);
                  // margi: 0;
                }
    
                .color-input {
                  border: none;
                  padding: 0;
                  background-color: transparent;
                  height: 20px;
                  margin-top: 0 !important;
                  height: 40px !important;
                  display: inline-block;
                  width: 125px !important;
                }
    
                .color-picker-container {
                  background: #ffffff;
                  position: absolute;
                  bottom: 0;
                  right: -265px;
                  border-radius: 4px;
                  box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18) !important;
                  padding: 14px;
                  z-index: 9999;
    
                  .close-color-picker {
                    position: absolute;
                    top: 6px;
                    font-size: 14px;
                    right: 6px;
                    color: rgba(25, 25, 25, 0.6);
    
                    &:hover {
                      color: rgba(25, 25, 25, 0.8);
                    }
                  }
    
                  &::before {
                    position: absolute;
                    background: #ffffff;
                    width: 16px;
                    height: 16px;
                    content: '';
                    left: -8px;
                    transform: rotate(45deg);
                    bottom: 17px;
                  }
    
                  .sketch-picker {
                    border-radius: 4px;
                    box-shadow: none !important;
    
                    .flexbox-fix:nth-child(2) {
                      height: 28px;
    
                      div:nth-child(1) {
                        div:nth-child(1) {
                          height: 23px !important  ;
                          div {
                            div.hue-horizontal {
                              div {
                                div {
                                  display: block !important;
                                  margin-top: 0 !important;
                                }
                              }
                            }
                          }
                        }
    
                        div:nth-child(2) {
                          div {
                            display: none;
                          }
                        }
                      }
                    }
    
                    .flexbox-fix {
                      &:nth-child(3) {
                        display: none !important;
                      }
    
                      &:nth-child(4) {
                        div {
                          div {
                            border-radius: 50% !important;
                          }
                        }
                      }
                    }
                  }
                }
              }

            .color-picker-container {
                background: #ffffff;
                position: absolute;
                bottom: 0;
                right: -265px;
                border-radius: 4px;
                box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18) !important;
                padding: 14px;
                z-index: 9999;
  
                .close-color-picker {
                  position: absolute;
                  top: 6px;
                  font-size: 14px;
                  right: 6px;
                  color: rgba(25, 25, 25, 0.6);
  
                  &:hover {
                    color: rgba(25, 25, 25, 0.8);
                  }
                }
  
                &::before {
                  position: absolute;
                  background: #ffffff;
                  width: 16px;
                  height: 16px;
                  content: '';
                  left: -8px;
                  transform: rotate(45deg);
                  bottom: 17px;
                }
  
                .sketch-picker {
                  border-radius: 4px;
                  box-shadow: none !important;
  
                  .flexbox-fix:nth-child(2) {
                    height: 28px;
  
                    div:nth-child(1) {
                      div:nth-child(1) {
                        height: 23px !important  ;
                        div {
                          div.hue-horizontal {
                            div {
                              div {
                                display: block !important;
                                margin-top: 0 !important;
                              }
                            }
                          }
                        }
                      }
  
                      div:nth-child(2) {
                        div {
                          display: none;
                        }
                      }
                    }
                  }
  
                  .flexbox-fix {
                    &:nth-child(3) {
                      display: none !important;
                    }
  
                    &:nth-child(4) {
                      div {
                        div {
                          border-radius: 50% !important;
                        }
                      }
                    }
                  }
                }
              }
        }
    }
}