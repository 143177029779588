.side-account-panel-wrapper {
    position: relative;
    width: 62px;
    height: 100%;
    background: #ffffff;
    display: inline-block;
    box-shadow: 1px 0 #dcdcdc;
    z-index: 4;
    vertical-align: top;

    .side-account-button-wrapper {
        position: relative;
        width: 100%;
        border-bottom: solid 1px #dcdcdc;
        padding: 12px 5px;
        font-size: 10px;
        letter-spacing: -0.3px;
        color: rgba(25, 25, 25, 0.6);
        cursor: pointer;
        text-align: center;

        p {
            margin: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        svg {
            color: #ff7d00;
            font-size: 24px;
            margin-bottom: 4px;
        }

        img {

        }

        &.button-dashboard {
            border-bottom: 0;
            border-top: solid 1px #dcdcdc;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        &:hover {
            color:rgba(25, 25, 25, .8);

            // svg {
            //     color: var(--main-darker-orange);
            // }
        }

        &.side-panel-account-button {
            &:hover {
                background: #ededed;
            }

            &:active {
                background: #FBF2EB;
                color: #ff7d00;
            }
        }
    }

    .account-avatar {
        border-radius: 50%;
        width: 32px;
        height: 32px;
        background: pink;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        color: #ffffff;
        display: inline-block;
        margin-bottom: 4px;
        position: relative;

    }

    .account-avatar-status-dot {
        width: 12px;
        height: 12px;
        background: #ffffff;
        border-radius: 50%;
        position: absolute;
        right: 0;
        bottom: 0;
        display: inline-block;

        .account-avatar-status-dot-inside {
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .dot-green {
        background: #19C700;
    }

    .dot-yellow {
        background: #FFB438;
    }

    .dot-red {
        background: #FF3F57;
    }
      
    .account-avatar-image {
        width: 32px;
        height: 32px;
        display: inline-block;
    }
    
    .account-avatar-name {
        font-size: 14px;
        letter-spacing: -0.4px;
    }

    .popup-account-panel {
        width: 300px;
        padding: 8px 12px;
        border-radius: 8px;
        background: #ffffff;
        box-shadow: 0 0 20px 2px #eaeaea;
        position: absolute;
        left: 78px;
        top: 10px;
        cursor: default;
        text-align: left;
        visibility: visible;
        opacity: 1;

        &.deactive-popup-account-panel {
            visibility: hidden;
            opacity: 0;
        }

        .account-panel-name-company {
            position: relative;
            width: 100%;
            padding-bottom: 15px;
            
            .account-avatar {
                width: 40px;
                height: 40px;
                line-height: 40px;

                .account-avatar-status-dot {
                    width: 14px;
                    height: 14px;

                    .account-avatar-status-dot-inside {
                        width: 10px;
                        height: 10px;
                    }
                }
            }

            .account-avatar-name-company-content {
                width: calc(100% - 40px);
                display: inline-block;
                vertical-align: top;
                padding-left: 16px;

                p {
                    margin: 0;
                    font-size: 12px;
                    letter-spacing: -0.2px;
                    color: rgba(25, 25, 25, .6);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    b {
                        color: rgba(25, 25, 25, 1);
                        font-size: 16px;
                        letter-spacing: -0.5px;
                    }
                }
            }
        }

        &::before {
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            transform: rotate(45deg);
            top: 18px;
            left: -8px;
            box-shadow: -4px 5px 5px 0px rgba(234, 232, 232, .2);
            background: #ffffff;
        }

        .account-panel-status-bar {
            margin-bottom: 9px;
            width: 100%;
            border-radius: 8px;
            font-size: 14px;
            letter-spacing: -0.4px;
            color: rgba(25, 25, 25, .8);
            padding: 12px 12px 12px 30px;
            position: relative;
            text-align: left;
            line-height: 1;
            cursor: pointer;

            svg {
                color: #ff7d00;
                font-size: 16px;
                position: absolute;
                right: 12px;
                top: 50%;
                transform: translate(0, -50%);
            }

            &:nth-last-child(1) {
                margin-bottom: 0;
            }

            .account-avatar-status-dot {
                // position: relative;
                left: 12px;
                top: 50%;
                transform: translate(0, -50%);
                margin-right: 8px;
            }

            &:hover {
                background: #f3f3f3;
                color: #191919
            }

            &.active-status-bar {
                background: #FFF2E5;
                color: #ff7d00;
            }
        }
    }
}

.modal-confirmation-clock {
    .modal-content {
      border-radius: 8px;
      padding: 32px;
      width: 476px;
    }
  
    .lds-ring {
      top: 4px;
      right: 0;
  
      div {
        border-color: #ffffff transparent transparent transparent;
        margin: 0;
      }
    }

    button {
      &[disabled] {
        .lds-ring {
          div {
            border-color: #959595 transparent transparent transparent;
          }
        }
      }
    }
  
    .modal-body {
      padding: 0;
  
      b {
        font-size: 20px;
        line-height: 1.2;
        letter-spacing: -0.6px;
        color: #191919;
      }
  
      p {
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.6);
        margin: 6px 0 0;
      }
    }
  
    .modal-footer {
      padding: 20px 0 0;
      border: none;
  
      button {
        width: calc(50% - 4px);
        display: inline-block;
        border-radius: 8px;
        border: none;
        height: 48px;
        line-height: 45px;
        margin: 0 8px 0 0;
  
        &:nth-last-child(1) {
          margin: 0;
        }
  
        svg {
          position: relative;
          font-size: 24px;
          top: -1px;
          margin-right: 3px;
        }
      }
    }
  }