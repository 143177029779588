.detail-segment-main-wrapper {
  .detail-segment-header {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid rgba(25, 25, 25, 0.1);

    .btn {
      padding: 0;
    }
  }

  .segment-name-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    button {
      margin: 24px 0 0;
    }

    b {
      margin-top: 12px;
      position: relative;
      font-size: 14px;
      width: 303px;
    }

    span {
      font-weight: normal;
    }
    
    .segment-name-input {
      width: 300px;
      border: 1px solid rgba(25, 25, 25, 0.1);
      border-radius: 8px;
      height: 48px;
      padding: 12px 16px;
      margin: 8px 0 0;

      &:focus {
        border: 1px solid #FF7E00;
      }
    }
  }
}

.contact-detail-segment-main-wrapper {
  .contact-detail-segment-header {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid rgba(25, 25, 25, 0.1);

    .remove-segment-wrapper {
      button {
        &.cancel-delete-segment-btn {
          margin-right: 8px;
        }
      }
    }
  }

  .search-box-wrapper {
    margin-bottom: 24px;
  }
}

.table-contact-detail-segment-list {
  table-layout: fixed;

  thead {
    tr {
      background: transparent;
  
      td {
        padding: 10px;
        // width: calc((100% - 100px) / 5);
        width: 20%;
        font-size: 14px;
        word-wrap: break-word;
        white-space: normal;
  
        &:nth-child(1) {
          width: 25%;
        }
    
        &:nth-child(2) {
          width: 25%;
        }

        &:nth-child(3) {
          width: 30%;
        }
  
        &:last-child {
          width: 20%;
          padding-right: 0;
          

          .main-custom-select-option-wrapper {
            display: flex;
            justify-content: flex-end;

            .dropdown {
              width: 80px;
            }
          }
  
          .custom-select-option-value-wrapper {
            height: 30px;
            padding: 0 4px 0 15px !important;
  
            .custom-select-option-value {
              padding: 0;
            }
  
            svg {
              right: 10px;
            }
          }
        }
  
      }
    }
  }

  tbody {
    tr {
      // cursor: pointer;

      &:nth-child(even) {
        background: rgba(25, 25, 25, 0.05);
      }

      &:hover {
        background-color: transparent !important;
      }

      td {
        background: transparent;
        position: relative;
        word-wrap: break-word;
        // vertical-align: top;

        &.col_full_name {
          p {
            margin-bottom: 0;
            display: inline-block;
            width: 75%;
            vertical-align: top;
          }
        }

        &.col-action {
          padding-right: 0;
          // height: 77px;
          text-align: right;
          white-space: nowrap;

          button {
            border: none !important;

            &.delete-btn {
              width: 100px;
              color: #FF3F57 !important;

              &:hover {
                background: transparent;
                color: #e83b51;
              }

              svg {
                position: relative;
                top: -2px;
                right: 5px;
                color: #FF3F57;
              }
            }
          }
        }
      }
    }
  }
}