.verify-card-main-wrapper {
  width: 100%;
  min-height: calc(100vh - 87px);
  margin-top: 87px;
  background: #f3f3f3;

  .verify-card-content {
    .verify-card-header {
      padding: 40px 72px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      .comp-back-button {
        margin: 0;
      }
      
      .button {
        padding: 8px 24px;
        // display: flex;
      } 
    }

    .verify-card-container {
      position: relative;
      display: block;
      flex-direction: column;
      align-items: center;
      padding: 0 32px 32px;
      // margin-top: 32px;
      top: 32px;
      background-color: #ffffff;
      border: 1px solid rgba(25, 25, 25, 0.1);
      border-radius: 8px;
      width: 90%;
      margin: 0 auto;
      text-align: center;

      h1 {
        margin: 32px 0;
        font-size: 40px;
      }

      .verify-card-iframe {
        width: 100%;
        height: 360px;
        // background-color: rgba(25, 25, 25, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 1px #dcdcdc;
        border-radius: 8px;

        .iframe-card-wrapper {
          width: 100%;
          height: 100%;

          iframe {
            width: 100%;
            height: 100%;
          }
        }
        
        .loading-add-card-wrapper {
          position: absolute;
          width: 100%;
          text-align: center;
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
          color: #ff7d00;
          font-size: 16px;
          letter-spacing: -0.6px;

          .LoadingRoll_1234fed {
              height: unset;
              margin-bottom: 24px;
              
              .lds-ring {
                  width: 64px;
                  height: 64px;
                  position: relative;
                  top: unset;
                  left: unset;
                  transform: unset;
                  
                  div {
                      border: solid 4px #ff7d00;
                      border-color: #ff7d00 transparent transparent transparent;
                      width: 64px;
                      height: 64px;
                  }
              }
          }

          b {
            font-size: 18px;
            color: #ff7d00 !important;
          }
      }

      b {
          font-size: 40px;
          color: rgba(25, 25, 25, 0.4);
        }
      }
    }
  }
}

// .confirm-modal {
//   .modal-content {
//       width: 476px;
//   }

//   .modal-body {
//       padding: 32px;

//       .confirm-modal-wrapper {
//           position: relative;

//           .confirm-title-wrapper {
//             display: flex;
//             align-items: center;

//             .confirm-title {
//                 font-size: 20px;
//                 letter-spacing: -0.6px;
//                 color: #191919;
//                 line-height: 1;
//                 margin: 0;
//             }

//             svg {
//               width: 18px;
//               height: 18px;
//               margin-right: 7px;
//             }
//           }

  
//           .confirm-description {
//               font-size: 14px;
//               letter-spacing: -0.2px;
//               color: rgba(25, 25, 25, 0.6);
//               line-height: 21px;
//               margin: 6px 0 20px;
//           }

//           .button-container {
//             width: 100%;
//             display: flex;
//             justify-content: space-between;

//             button {
//               &.cancel {
//                 width: 23%;
//                 height: 48px
//               }

//               &.discard {
//                 width: 75%;
//                 height: 48px;

//                 svg {
//                   margin-right: 9px;
//                 }
//               }
//             }
//           }
//       }
//   }
// }