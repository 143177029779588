.view-broadcast-message-detail {
  .flexing {
    display: flex;
    margin: 4px 0 0 !important;
  }
  .view-broadcast-message-detail-top {
    padding: 10px 0 20px 0;
    border-bottom: solid 1px #dcdcdc;
    margin-bottom: 24px;
    position: relative;

    b {
      font-size: 20px !important;
      letter-spacing: -0.6px;
      font-weight: 700;
    }

    .button-export {
      position: absolute;
      right: 0;
      bottom: 10px;

      b {
        color: unset !important;
        font-size: 16px !important;
      }
    }

    span {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.5px;
      color: rgba(25, 25, 25, 0.4);
      position: relative;
      top: 2px;
    }

    button {
      b {
        font-size: 16px !important;
        color: #ffffff !important;
        letter-spacing: -.5px !important;
      }

      &.button-edit {
        position: absolute;
        right: 134px;
        top: 6px;
      }

      &.button-delete {
        position: absolute;
        right: 0;
        top: 6px;
      }
    }
  }

  .colored-info-box {
    p {
      color: #ffffff;
      margin-top: 0 !important;
    }
  }

  div {
    p,
    b {
      font-size: 14px;
      letter-spacing: -0.4px  ;
      color: rgba(0, 0, 0, 0.8);
      font-family: PTRootUI !important;

      &.yes-text {
        color: #19C700;
      }

      &.no-text {
        color: #E02E2E;
      }
    }

    p {
      letter-spacing: -0.2px;

    }

    b {
      font-weight: 700 !important;
      vertical-align: top;
    }

    .disabled-form {
      div {
        background-color: rgba(25, 25, 25, 0.05);
        border: 1px solid rgba(25, 25, 25, 0.1);
        border-radius: 8px;
        padding: 12px 16px;
        width: 400px;
        margin: 8.5px 0 24px 0;
        word-break: break-word;

        p {
          font-size: 16px;
        }
      }
    }
  }

  .view-broadcast-message-detail-middle {
    padding: 0 0 16px;

    &.message-number-wrapper {
      border-bottom: solid 1px #dcdcdc;
      margin-bottom: 10px;

      &:nth-last-child(1) {
        margin-bottom: 0;
        border-bottom: 0;
      }
    }


    > div {
      svg {
        margin-right: 5px;
        vertical-align: top;
        position: relative;
        top: 1px;
      }
      img {
        margin-right: 5px;
        margin-bottom: 4px;

        &.label-icon {
          margin-bottom: 0;
          position: relative;
          vertical-align: top;
          top: 1px;
        }

        &.channel-icon {
          margin: 0 5px 0 0 !important;
          position: relative;
          top: 2px;
        }

        position: relative;
        top: -1px;
      }
    }
    p {
      margin: 8px 0 0;
    }
    .view-broadcast-message-detail-middle-category {
      p {
        margin-bottom: 0;
      }
    }
    .view-broadcast-message-detail-middle-channel {
      div > img {
        // margin-bottom: 9px;
      }
      > div {
        margin: 8px 0 0;
      }
    }
    .view-broadcast-message-detail-middle-status {
      .colored-info-box {
        margin-top: 10px;
        margin-bottom: 1rem;
        p {
          color: #ffffff;
          margin-top: 0 !important;
        }
      }
    }
    .view-broadcast-message-detail-middle-content-body {
      max-width: 568px;
      > img {
        margin-bottom: 5px !important;
      }
      .message-content {
        margin-top: 12px;
        img {
          width: 200px;
          height: 200px;
          object-fit: cover;
          border-radius: 8px;
          cursor: pointer;
          margin: 0 0 8px 0;
        }
        .content-caption {
          padding-top: 10px;
          // border-top: solid 1px #dcdcdc;
          margin-top: 0 !important;
        }
      }

      .file-url {
        cursor: pointer;
        img {
          width: 24px !important;
          height: 24px !important;
          margin-right: 7px;
        }
        div > p {
          margin-top: 2px;
          font-weight: bold;
          font-size: 14px;
          color: #ff7e00;
          line-height: 21px;
          letter-spacing: -0.4px;
          border-bottom: solid 1px #ff7e00;
        }
      }
    }

    .view-broadcast-message-detail-middle-total-status {
      font-weight: bold;
      max-width: 100%;
      overflow: auto;
      
      > div {
        margin-right: 9%;
      }
      .flexing {
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.4px;
      }
      .total {
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.6px;
      }
    }

    &:nth-last-child(1) {
      padding: 0;
    }
  }

  .view-broadcast-message-detail-bottom {
    padding: 24px 0 0;

    .view-message-box {
      position: relative;
      width: 100%;
    }

    .view-message-box-left {
      display: inline-block;
      vertical-align: top;
      width: 400px;
    }

    .view-message-box-right {
      width: calc(100% - 400px);
      vertical-align: top;
      display: inline-block;
      padding-left: 24px;
    }
  }

  div:last-child {
    .view-broadcast-message-detail-bottom-footer {
      p {
        margin-bottom: 0;
      }
    }
  }

  .broadcast-recipients-table-wrapper {
    width: 100%;
    position: relative;
    margin-top: 24px;
  }

  .table-broadcast-recipients thead tr {
    background: #ffffff;
    // border-top: solid 1px #dcdcdc;
    
    .col_name {
      width: unset;
    }

    td {
      // &:nth-child(1) {
      //     width: 100%;
      // }

      // &:nth-child(2), &:nth-child(3) {
      //     width: 100px;
      // }

      // &:nth-child(4), &:nth-child(5) {
      //     width: 120px;
      // }

      &:last-child {
        width: 90px;

        .custom-select-option-value-wrapper {
          width: 55px !important;
          height: 23px;
          padding: 2px 8px 2px 12px !important;
          border-radius: 4px !important;

          p {
            font-size: 12px;
            font-weight: bold;
          }

          .custom-select-option-value {
            padding: 0;
          }

          svg {
            right: 3px !important;
            height: 15px;
            top: 11px !important;
            position: absolute !important;
            margin-right: unset !important;
          }
        }
      }
    }
  }

  .table-broadcast-recipients tbody td {
    position: relative;

    &.col_name {
      width: unset;
    }

    &.col_status {
      P {
        font-size: 12px !important;
      }
    }

    &.col_responded {
      b {
        font-size: 12px !important;
        position: relative;
        top: 2px;
      }
    }
    
    &:nth-child(1) {
      div {
        display: flex;

        p {
          font-size: 12px !important;
          letter-spacing: -0.4px;
        }

        img {
          margin-right: 4px;
          margin-top: 2px;
        }
      }
    }

    // &:nth-child(2), &:nth-child(3) {
    //     width: 100px;
    // }

    // &:nth-child(4), &:nth-child(5) {
    //     width: 120px;
    // }

    &:last-child {
      color: #ff7e00;
      cursor: pointer;
      font-weight: 700;

      a {
        color: #ff7e00;
      }

      svg {
        position: relative;
        color: #ff7e00;
        font-size: 16px;
        margin-right: 4px;
        right: 0;
        top: -2px;
      }
    }

    .medium-logo {
      width: 16px;
      height: 16px;
    }
  }
  .pagination-wrapper {
    ul {
      li {
        font-size: 12px !important;
        position: relative !important;

        &:not(.pagination-button) {
          top: 6px !important;
        }

        span {
          position: absolute;
          top: -42px;
          display: inline-block;
          left: 50%;
          transform: translate(-50%, 0);
        }

        &.active {
          width: 30px !important;
          height: 30px !important;

          span {
            top: -40px !important;
          }
        }

        &.pagination-label {
          top: 5px !important;

          svg {
            height: 12px;
          }
        }
      }
    }
  }
  .custom-select-option-value {
    margin-top: 0px !important;
  }
}

.modal-image-broadcast.modal-dialog {
  width: 100vw !important;
}
.modal-image-broadcast .modal-content {
  width: 100%;
  height: 100%;
  background: none;
}
.modal-image-broadcast .modal-content .modal-body {
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 25, 0.8);
}
.modal-image-broadcast .modal-content .modal-body img.image-preview-val {
  max-width: calc(100vw - 130px);
  max-height: calc(100vh - 130px);
  background: #ffffff;
}
.modal-image-broadcast .modal-content .modal-body .image-wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  figure {
    margin: 0;

    &.zoom {
      background-size: 180%;
      background-position: -100% -100%;
      background-repeat: no-repeat;
      position: relative;
      width: 100%;
      overflow: hidden;
      cursor: zoom-in;

      img {
        transition: opacity 0.5s;
        display: inline-block;
        // width: 100%;

        &:hover {
          opacity: 0;
        }
      }
    }
  }
}
.modal-image-broadcast .modal-content .modal-body .close-modal-image-broadcast {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  font-size: 40px;
  cursor: pointer;
  transition: ease-in-out 0.1s;
}
.modal-image-broadcast
  .modal-content
  .modal-body
  .close-modal-image-broadcast:hover {
  color: #c9c9c9;
}

.modal-confirmation-delete-broadcast-scheduled {
  button {
    width: calc(50% - 6px) !important;
    display: inline-block;
    margin-right: 12px;
  
    &:nth-last-child(1) {
      margin-right: 0 !important;
    }
  }
}
