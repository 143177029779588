.option-select-topic-wrapper {
    width: 100%;
    position: relative;

    .option-select-topic-wrapper-50 {
        width: calc(50% - 8px);
        position: relative;
        vertical-align: top;
        display: inline-block;
        margin: 0 16px 0 0;

        label {
            position: relative;
            width: 100%;

            .clear-topic {
                position: absolute;
                bottom: 0;
                right: 0;
                cursor: pointer;
                color: var(--main-dark-orange);

                svg {
                    position: relative;
                    font-size: 16px;
                    top: -2px;
                    margin-right: 4px;
                }

                &:hover {
                    color: var(--main-darker-orange);
                }
            }
        }

        &:nth-child(2) {
            margin: 0;
        }

        span {
            color: rgba(25, 25, 25, 0.6);
            position: relative !important;
        }

         .selection-topic-wrapper {
             border-radius: 8px;
             font-size: 14px;
             letter-spacing: -0.2px;
             box-shadow: 0 0 0 1px #dcdcdc;

             .select-content {
                 width: 100%;
                 padding: 13px 17px;
                 white-space: nowrap;

                 label {
                     margin: 0;
                     max-width: 100%;
                     overflow: hidden;
                     text-overflow: ellipsis;
                     white-space: nowrap;
                     display: inline-block !important;
                     padding: 0 0 0 22px !important; 
                 }

                 input {
                     display: none;
                 }

                 &:hover {
                     background: #FFF2E5;
                 }

                 &:nth-child(1) {
                     border-top-left-radius: 8px;
                     border-top-right-radius: 8px;
                 }
             }

             .search-box-wrapper {
                width: 100%;

                input[type="text"] {
                    border: none !important;
                    border-bottom: solid 1px #dcdcdc !important;
                    border-radius: 0;
                    background: transparent;
                    width: 100%;
                    position: relative;
                    padding: 0 16px 0 42px;
                    height: 40px;
                    margin: 0 !important;
                }

                #search-icon {
                    width: 40px;
                    right: unset !important;
                    left: 0 !important;
                    height: 40px;
                    background: transparent !important;
                }
             }

             .overflow-select-topic {
                 max-height: 160px;
                 overflow-y: auto;

                 .select-content {
                     &:nth-child(1) {
                         border-top-left-radius: 0px;
                         border-top-right-radius: 0px;
                     }

                     &:nth-last-child(1) {
                         border-bottom-left-radius: 8px;
                         border-bottom-right-radius: 8px;
                     }

                     &.checkbox-all {
                         border-bottom: solid 1px #dcdcdc;
                     }

                     &.not-found {
                         text-align: center;
                         color: var(--main-dark-grey);
                     }
                 }
             }
         }

         .select-topic-wrapper {
             width: 100%;
             height: 200px;
            
             .selected-topic-container {
                 width: 100%;
                 min-height: 100%;
                 overflow-y: auto;
                 max-height: 100%;
                 border: solid 1px #dcdcdc;
                 border-radius: 8px;
                 padding: 16px 16px 8px 16px;

                 .no-selected-topic {
                     display: flex;
                     flex-direction: column;
                     align-items: center;
                     margin-top: 48px;

                     img {
                         margin-bottom: 20px;
                     }

                     p {
                         color: rgba(25, 25, 25, 0.8);
                         font-size: 14px;
                         line-height: 21px;
                         letter-spacing: -0.2px;
                     }
                 }
             }
         }
    }
}