.setup-room-list-modal {
    background: #4e4e4e;
  }
  .setup-room-list-modal .modal-content {
    width: unset;
    min-width: 268px;
    display: inline-block;
    border-radius: 8px;
    padding: 32px 16px;
    text-align: center;
    position: relative;
  }
  .setup-room-list-modal .modal-content .setup-main-content .setup-image-content {
    height: 64px;
    width: 64px;
    display: inline-block;
    position: relative;
    border-radius: 50%;
    margin-bottom: 12px;
  }
  .setup-room-list-modal .modal-content .setup-main-content .setup-image-content .lds-ring {
    position: absolute;
    left: 0;
    top: 0;
    height: 64px;
    width: 64px;
  }
  .setup-room-list-modal .modal-content .setup-main-content .setup-image-content .lds-ring div {
    height: 64px;
    width: 64px;
    border: 8px solid;
    border-color: #ff7d00 transparent transparent transparent;
  }
  .setup-room-list-modal .modal-content .setup-main-content .setup-image-content.setup-success {
    border: 12px solid #19C700;
  }
  .setup-room-list-modal .modal-content .setup-main-content .setup-fail {
    color: #ff3f57;
  }
  .setup-room-list-modal .modal-content .setup-main-content .setup-image-content img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .setup-room-list-modal .modal-content .setup-main-content b {
    font-size: 20px;
    letter-spacing: -0.6px;
    color: #ff7d00;
  }
  .setup-room-list-modal .modal-content .setup-main-content p {
    font-size: 16px;
    letter-spacing: -0.3px;
    margin: 8px 0 0;
  }
  .setup-room-list-modal .modal-content .setup-main-content p.retry-setup b {
    color: #ff7d00;
    transform: ease-in-out 0.1s;
    cursor: pointer;
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 16px;
  }
  .setup-room-list-modal .modal-content .setup-main-content p.retry-setup:hover {
    color: var(--main-darker-orange);
  }
  .setup-room-list-modal .modal-content .setup-main-content p svg {
    font-size: 20px;
    margin-right: 7px;
    font-size: 25px;
    position: relative;
    top: -1px;
  }
  .setup-room-list-modal .lds-ring div {
      margin: 0;
  }
  .modal-dialog.setup-room-list-modal {
    width: 100vw !important;
  }