.case-action-history-wrapper {
    width: 100vw;
    height: 100vh;
    background: rgba(25, 25, 25, 0.8);
    position: fixed;
    top: 0;
    /* right: -100vw; */
    visibility: hidden;
    opacity: 0;
    right: 0;
    /* transition: visibility 0s, opacity 0.3s ease-in-out; */
    transition: visibility .3s, opacity 0.5s ease-in-out;
    z-index: 4;

    &.active-case-action-history {
        opacity: 1;
        visibility: visible;
        -webkit-transition: visibility 0s, opacity 0.5s ease-in-out;
        transition: visibility 0s, opacity 0.5s ease-in-out;
    }

    .case-action-history-main-wrapper {
        width: 340px;
        transition: ease-in-out 0.5s;
        background: #ffffff;
        height: calc(100vh - 8px);
        border-top-left-radius: 18px;
        bottom: 0;
        right: -405px;
        position: fixed;
        
        &.active-case-action-history-main-wrapper {
            right: 0;
        }

        .case-action-history-close {
            background: #ffffff;
            border-radius: 50%;
            cursor: pointer;
            position: absolute;
            width: 48px;
            height: 48px;
            display: inline-block;
            text-align: center;
            line-height: 45px;
            top: 0;
            left: -64px;

            svg {
                font-size: 24px;
                color: #7D7C7D;
            }
        }

        .title-case-action-history {
            font-size: 24px;
            letter-spacing: -0.6px;
            padding: 24px;
            border-bottom: solid 1px #dcdcdc;
            margin: 0;
        }

        .case-action-history-list-wrapper {
            // padding: 20px;
            width: 100%;
            height: calc(100% - 85px);
            max-height: calc(100% - 85px);

            .loading-case-action {
                text-align: center;
                color: #ff7d00;
                padding-top: 12px;

                .lds-ring {
                    vertical-align: top;
                    top: 3px;
                    right: unset;
                    margin-right: 4px;

                    div {
                        margin: 0;
                    }
                }
            }

            .on-error {
                text-align: center;
                color: #ff7d00;
                
                svg {
                    position: relative;
                    margin-right: 4px;
                    top: -1px;
                }

                p {
                    cursor: pointer;
                    display: inline-block;

                    &:hover {
                        color: #e87200;
                    }
                }

            }

            .case-action-history-card {
                padding: 8px 20px 8px 52px;
                position: relative;
                margin-bottom: 12px;
                
                &::before {
                    position: absolute;
                    border-radius: 50%;
                    width: 16px;
                    height: 16px;
                    left: 20px;
                    top: 10px;
                    // transform: translate(0, -50%);
                    content: "";
                    border: solid 2px rgba(25, 25, 25, 0.2);
                    background: #ffffff;
                    z-index: 2;
                }

                &::after {
                    position: absolute;
                    width: 1px;
                    height: 100%;
                    left: 27px;
                    top: 24px;
                    content: "";
                    border-left: dashed 2px rgba(25, 25, 25, 0.2);
                    z-index: 1;
                }

                &:nth-child(1) {
                    &::before {
                        border: solid 2px #ff7d00;
                    }
                }

                p {
                    max-width: 100%;
                    // text-overflow: ellipsis;
                    // overflow: hidden;
                    // white-space: nowrap;

                    &.action-time {
                        font-size: 12px;
                        letter-spacing: -0.2px;
                        color: rgba(25, 25, 25, 0.4);
                        margin: 0;

                        svg {
                            position: relative;
                            margin-right: 4px;
                            top: -1px;
                        }
                    }

                    &.action-text {
                        margin: 0;
                        font-size: 14px;
                        letter-spacing: -0.4px;
                        color: rgba(25, 25, 25, 0.4);
                    }
                }

                &:nth-last-child(1) {
                    &::after {
                        display: none;
                    }
                }

                &:nth-child(1) {
                    margin-top: 12px;
                }
            }
        }
    }
}