.case-nation-flag {
    width: 22px;
    position: relative;
    top: 1px !important;
}

.user-info-list-wrapper.remarks-for-contact {
    border-top: solid 1px #dcdcdc;
    padding-top: 8px;
}

.contact-segment-text {
    font-size: 12px;
    letter-spacing: -0.2px;
    margin: 0;
}

.modal-select-segment {
    .modal-content {
        width: 840px;
        max-width: 100vw;
        height: 85vh;
        max-height: 85vh;
        overflow: auto;

        .modal-body {
            padding: 32px;
        }
    }
}