.modal-confirmation-delete .modal-content {
    box-sizing: border-box;
    width: 546px;
    padding: 0 32px;
    border-radius: 8px;
}

.modal-confirmation-delete .modal-body {
    font-size: 14px;
    letter-spacing: -0.2px;
    text-align: left;
    color: #9b9b9b;
    /* padding-top: 30px; */
    padding: 30px 0 16px;
}

.modal-confirmation-delete .modal-body p {
    margin: 0;
    word-break: break-word;
}

.modal-confirmation-delete .modal-body b {
    font-size: 24px;
    letter-spacing: -0.4px;
    color: #191919;
    word-break: break-word;
}

.modal-confirmation-delete .modal-footer {
    padding: 0 0 32px;
    border: 0;
}

.modal-confirmation-delete .modal-footer button {
    width: calc(50% - 8px);
    display: inline-block;
    margin: 0 16px 0 0;
    position: relative;
}

.modal-confirmation-delete .modal-footer button:nth-child(2) {
    margin-right: 0;
}

.modal-confirmation-delete .modal-footer-without-border {
    border-top: solid 1px #ffffff;
    padding: 4px 0 40px;
}