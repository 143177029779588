#pagination-wrapper {
    width: 100%;
    text-align: right;
    height: 110px;
    line-height: 110px;

    ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            display: inline;
            // color: var(--main-dark-orange);
            color: rgba(25, 25, 25, 0.6);
            font-weight: 600;
            font-size: 12px;
            letter-spacing: -0.4px;
            margin-right: 25px;
            cursor: pointer;

            &.active {
                /* padding: 12px 20px; */
                border-radius: 4px;
                background: var(--main-dark-orange);
                color: white;
                margin: 0 20px 0 0;
                text-align: center;
                position: relative;
                width: 30px;
                height: 30px;
                display: inline-block;
                top: 11px;
                position: relative;

                span {
                    position: absolute;
                    color: #ffffff;
                    top: 50% !important;
                    display: inline-block;
                    left: 50% !important;
                    transform: translate(-50%, -50%) !important;
                }
            }

            &.pagination-label {
                position: relative;
                top: -1px;
            }

            svg {
                vertical-align: middle !important;
                position: relative !important;
                top: -1px !important;
            }
        }
    }
}

// #pagination-wrapper ul {
//     padding: 0;
//     margin: 0;
//     list-style: none;
// }

// #pagination-wrapper ul li {
//     display: inline;
//     color: var(--main-dark-orange);
//     font-weight: 600;
//     font-size: 17px;
//     margin-right: 40px;
//     cursor: pointer;
// }

// #pagination-wrapper ul li.active {
//     /* padding: 12px 20px; */
//     border-radius: 50%;
//     background: var(--main-dark-orange);
//     color: white;
//     margin: 0 20px 0 -20px;
//     text-align: center;
//     position: relative;
//     width: 46px;
//     height: 46px;
//     display: inline-block;
//     top: 16px;
//     position: relative;
// }

// #pagination-wrapper ul li.active span {
//     position: absolute;
//     color: #ffffff;
//     top: -32px;
//     display: inline-block;
//     left: 50%;
//     transform: translate(-50%, 0);
// }

// #pagination-wrapper ul li.pagination-label {
//     position: relative;
//     top: -1px;
// }