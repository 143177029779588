.select-tier-main-wrapper {
    width: 100%;
    min-height: calc(100vh - 87px);
    margin-top: 87px;
    background: #f3f3f3;
}

#new-organization-header-content {
    color: red;

    .step-container {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 64px;
    }
}

.select-tier-form-1 {
    position: relative;

    .comp-back-button {
        margin-left: 72px;
    }

    .continue-tier {
        top: 25px;
        right: 72px;
        height: 40px;
        width: 110px;
        position: absolute;
    }

    .select-tier-top {
        width: 100%;
        font-size: 20px;
        letter-spacing: -0.6px;
        color: rgba(25, 25, 25, 0.8);
        padding: 32px 70px 0;
        white-space: nowrap;
        text-align: center;

        .select-your-tier-title {
            font-size: 40px;
            letter-spacing: -0.6px;
            line-height: 1;
            margin: 0 0 33px;
        }

        .toggle-select-tier {
            position: relative;
            top: 10px;  
            display: inline-block;

            .pay-switch-wrapper {
                vertical-align: top;
                display: inline-block;
                margin: 0 16px;
                
                .custom-switch-wrapper {
                    margin: 0;
                    height: 28px;
                    width: 48px;

                    .custom-switch:before {
                        width: 15px;
                        height: 15px;
                        bottom: 7px;
                    }
                }
                
                input:checked + .custom-switch:before {
                    transform: translateX(17px);
                }
            }
        }

        .alert-component {
            font-size: 16px;
            letter-spacing: -0.5px;
            padding: 11px 16px 11px 44px; 
            margin-left: 16px;
        }
    }

    .pricing-outer-container {
        padding: 32px 70px;

        .alert-component {
            background: #fff4b1;
            border: 1px solid #ff7e00;
            box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
            width: 100%;
            color: #ff7e00;
            position: relative;
            margin-bottom: 28px;

            .orange-button {
                position: absolute;
                right: 16px;
                top: 50%;
                transform: translate(0, -50%);
                padding: 0 24px;
                height: 32px;
                font-size: 14px;
            }
        }

        .main-pricing-wrapper {
            .main-pricing-title {
                font-size: 40px;
                line-height: 1.2;
                letter-spacing: -0.6px;
                text-align: center;
                color: #191919;
                margin: 0 0 48px;
            }

            .pricing-div-wrapper {
                margin-bottom: 12px;
                margin-right: 12px;
                width: calc(25% - 9px);
                display: inline-block;
                vertical-align: top;
                background: #ffffff;
                padding: 39px 16px 24px;
                border-radius: 8px;
                text-align: center;
                border: solid 1px rgba(25, 25, 25, 0.1);
                // box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);

                &.active-selected-tier {
                    border: solid 1px #dcdcdc;
                    box-shadow: 0px 0px 8px rgba(255, 126, 0, 0.24)
                }

                P.note-text {
                    text-align: left;
                    margin: 0;
                    font-size: 16px;
                    letter-spacing: -0.5px;
                }

                &:nth-child(4n) {
                    margin-right: 0 !important;
                }

                .pricing-badge {
                    color: #ff7e00;
                    border-radius: 6px;
                    background: #ffe7d0;
                    letter-spacing: -0.4px;
                    font-size: 12px;
                    padding: 2px 12px;
                }

                .pricing-title {
                    color: rgba(25, 25, 25, 0.6);
                    font-size: 14px;
                    letter-spacing: -0.2px;
                    margin: 8px 0;
                }

                .pricing-price1 {
                    margin: 0;
                    font-size: 24px;
                    letter-spacing: -0.6px;
                    color: rgba(25, 25, 25, 0.8);
                }

                .save-pricing {
                    color: #ff7e00;
                    font-size: 14px;
                    letter-spacing: -0.4px;
                    margin: 4px 0 0;
                }

                .submit-price {
                    height: 40px;
                    font-size: 16px;
                    letter-spacing: -0.5px;
                    width: 100%;
                    text-align: center;
                    margin: 24px 0 0;

                    p {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        margin: 0;
                    }

                    .lds-ring {
                        position: relative;
                        top: 3px;
                        right: unset;
                        
                        div {
                            margin: 0;
                            border-color: #ffffff transparent transparent transparent;
                        }
                    }

                    &.active-tier-button {
                        background: #ff7e00 !important;
                        color: #ffffff !important;
                    }
                }

                .pricing-price2 {
                    margin: 0;
                    color: rgba(25, 25, 25, 0.6);
                    font-size: 14px;
                    letter-spacing: -0.2px;
                }

                ul {
                    list-style: none;
                    padding: 16px 0 0;
                    margin: 8px 0 0;

                    &.no-margin-padding-top {
                        padding: 0 0 0;
                        margin: 0 0 0;
                    }

                    li {
                        text-align: left;
                        padding: 0 0 12px 30px;
                        position: relative;
                        color: #191919;
                        letter-spacing: -0.5px;
                        font-size: 16px;

                        img {
                            position: absolute;
                            left: 0;
                            width: 20px;
                            top: 2px;
                        }

                        svg {
                            color: #24c90c;
                            position: absolute;
                            left: 0;
                            top: 2px;
                            font-size: 20px;
                        }

                        &.pricing-whatsapp {
                            padding: 0 0 12px 24px;

                            p {
                                font-size: 14px;
                                letter-spacing: -0.2px;
                                margin: 2px 0 0;
                            }
                        }
                    }
                }

                .accordion-toggle-wrapper {
                    text-align: center;

                    .accordion-toggle-content {
                        color: rgba(25, 25, 25, 0.6);
                        font-size: 14px;
                        letter-spacing: -0.4px;
                        border-bottom: 1px solid #dcdcdc;
                        width: 80%;
                        display: inline-block;
                        padding-bottom: 12px;
                        margin-bottom: 12px;
                        cursor: pointer;
   
                        &:hover {
                            color: #191919;
                        }

                        svg {
                            transition: ease-in-out .2s;
                            position: relative;
                            margin-right: 4px;
                            top: -2px;

                            &.active-accordion-feature {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }

                &.pricing-professional {
                    background: #ffffff;
                    color: #191919;
                    // box-shadow: 0 2px 20px 0 rgba(255, 126, 0, 0.6);
                    border: solid 1px rgba(25, 25, 25, 0.1);

                    .pricing-badge {
                        background: #ffe7d0;
                        color: #ff7e00;
                        font-size: 12px;
                        letter-spacing: -0.4px;
                    }

                    .pricing-title {
                        color: rgba(25, 25, 25, 0.6);
                    }

                    .pricing-price1 {
                        color: #191919;
                    }

                    .pricing-price2 {
                        color: #191919;
                    }

                    ul {
                        border-color: rgba(25, 25, 25, 0.1);
                        
                        li {
                            color: #191919;
    
                            svg {
                                color: #5FD84E;
                            }
                        }
                    }
                        
                }
            }
        }
    
        .additional-pricing {
            margin-top: 32px;
            border: solid 1px #044b84;
            background: #dcebff;
            padding: 15px 20px 12px;
            text-align: center;
            color: #044b84;
            border-radius: 8px;
            margin-bottom: 120px;
    
            p {
                margin: 0;
    
                &:nth-child(1) {
                    position: relative;
                    display: inline-block;
                    // padding-left: 30px;
                    font-size: 14px;
                    letter-spacing: -0.3px;
                    line-height: 24px;
    
                    img {
                        top: 50%;
                        transform: translate(0, -50%);
                        position: absolute;
                        visibility: top;
                        left: 0;
                    }
                }
    
                &:nth-child(2) {
                    margin-top: 4px;
                    font-size: 20px;
                    line-height: 24px;
                    letter-spacing: -0.3px;
                }
            }
        }
    }
}

.modal-has-pending-subscription {
    .modal-content {
        border-radius: 8px;
    }

    .modal-body {
        padding: 32px;

        p {
            &.modal-has-pending-subscription-title {
                font-size: 20px;
                letter-spacing: -0.6px;
                margin: 0 0 4px 0;

                svg {
                    font-size: 24px;
                    margin-right: 4px;
                    position: relative;
                    top: -2px;
                }
            }

            &.modal-has-pending-subscription-description {
                color: rgba(25, 25, 25, 0.6);
                font-size: 14px;
                letter-spacing: -0.2px;
                line-height: 1.5;
            }
        }

        button {
            height: 48px;
            width: calc(50% - 4px);
            margin: 0 8px 0 0;

            &:nth-last-child(1) {
                margin: 0;
                vertical-align: top;
            }
        }
    }
}